import * as Yup from "yup";

const setting = Yup.object({
  auto_complated_after_receive: Yup.number()
    .test(
      "Is positive?",
      "Tidak boleh diawali dengan minus",
      (value) => value >= 0,
    )
    .required("Silahkan isi inputan"),
  reminder_notif_before_overdue: Yup.number()
    .test(
      "Is positive?",
      "Tidak boleh diawali dengan minus",
      (value) => value >= 0,
    )
    .required("Silahkan isi inputan"),
  reminder_notif_after_overdue: Yup.number()
    .test(
      "Is positive?",
      "Tidak boleh diawali dengan minus",
      (value) => value >= 0,
    )
    .required("Silahkan isi inputan"),
  wa_number: Yup.number()
    .positive("Tidak boleh diawali dengan minus")
    .test("length min phone", "Min nomer 11", (val) => {
      return val?.toString().length > 10;
    })
    .test("length max phone", "Max nomer 15", (val) => {
      return val?.toString().length < 14;
    }),
});

export { setting };
