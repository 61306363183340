import React from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import * as ROUTEPATH from './routePath';
import { ProductCategory } from './App/Screens/MasterData/ProductCategory';
import { AddProductCategory } from './App/Screens/MasterData/ProductCategory/AddProductCategory';
import ManagementPlafond from './App/Screens/ManagementPlafond';
import DetailsManagementPlafond from './App/Screens/ManagementPlafond/details';
import ManagementMitra from './App/Screens/ManagementMitra';
import AddManagemenMitra from './App/Screens/ManagementMitra/addManagemenMitra';
import DetailManagementMitra from './App/Screens/ManagementMitra/details';
import { ShippingMethod } from './App/Screens/MasterData/ShippingMethod';
import { AddShippingMethod } from './App/Screens/MasterData/ShippingMethod/AddShippingMethod';
import { ManagementRole } from './App/Screens/ManagementAdmin/Role';
import { AddRole } from './App/Screens/ManagementAdmin/Role/addRole';
import ManagementContentFAQ from './App/Screens/ManagementContent/FAQ';
import AddFAQ from './App/Screens/ManagementContent/FAQ/addFAQ';
import ManagementContentPromo from './App/Screens/ManagementContent/Promo';
import AddPromo from './App/Screens/ManagementContent/Promo/addPromo';
import Setting from './App/Screens/Setting';
import MasterDataSatuan from './App/Screens/MasterData/Satuan';
import AddSatuan from './App/Screens/MasterData/Satuan/addSatuan';
import PageMedia from './App/Screens/Media';
import { GroupMitra } from './App/Screens/MasterData/GroupMitra';
import { AddGroupMitra } from './App/Screens/MasterData/GroupMitra/AddGroupMitra';
import Product from './App/Screens/ManagementProduct/Product';
import Details from './App/Screens/ManagementProduct/Product/details';
import AddProduct from './App/Screens/ManagementProduct/Product/addProduct';
import ProductUnggulan from './App/Screens/ManagementProduct/ProductUnggulan';
import ManagementStock from './App/Screens/ManagementProduct/ManagementStock';
import ProductPaket from './App/Screens/ManagementProduct/ProductPaket';
import AddProductPaket from './App/Screens/ManagementProduct/ProductPaket/addProduct';
import DetailsProductPaket from './App/Screens/ManagementProduct/ProductPaket/details';
import { PageAdmin } from './App/Screens/ManagementAdmin/Admin';
import { AddManagementAdmin } from './App/Screens/ManagementAdmin/Admin/addManegementAdmin';
import { ManagementTransaction } from './App/Screens/ManagementTransaction';
import DetailsTransaction from './App/Screens/ManagementTransaction/details';
import Dashboard from './App/Screens/Dashboard';
import ManagementContentBanner from './App/Screens/ManagementContent/Banner';
import AddBanner from './App/Screens/ManagementContent/Banner/addBanner';
import ManagementContentPage from './App/Screens/ManagementContent/Page';
import AddPage from './App/Screens/ManagementContent/Page/addPage';
import ReportStock from './App/Screens/report/stok';
import ReportSale from './App/Screens/report/sale';
import { Profile } from './App/Screens/Profile';


window.jQuery = $;
window.$ = $;
global.jQuery = $;

// const DashboardDefault = React.lazy(() => import('./Demo/Dashboard/Default'));

const UIBasicButton = React.lazy(() => import('./Demo/UIElements/Basic/Button'));
const UIBasicBadges = React.lazy(() => import('./Demo/UIElements/Basic/Badges'));
const UIBasicBreadcrumbPagination = React.lazy(() => import('./Demo/UIElements/Basic/BreadcrumbPagination'));

const UIBasicCollapse = React.lazy(() => import('./Demo/UIElements/Basic/Collapse'));
const UIBasicTabsPills = React.lazy(() => import('./Demo/UIElements/Basic/TabsPills'));
const UIBasicBasicTypography = React.lazy(() => import('./Demo/UIElements/Basic/Typography'));

const FormsElements = React.lazy(() => import('./Demo/Forms/FormsElements'));

const BootstrapTable = React.lazy(() => import('./Demo/Tables/BootstrapTable'));

const Nvd3Chart = React.lazy(() => import('./Demo/Charts/Nvd3Chart/index'));

const GoogleMap = React.lazy(() => import('./Demo/Maps/GoogleMap/index'));

const OtherSamplePage = React.lazy(() => import('./Demo/Other/SamplePage'));
const OtherDocs = React.lazy(() => import('./Demo/Other/Docs'));

const routes = [
    { path: '/', exact: true, name: 'Dashboard', component: () => <Redirect to={ROUTEPATH.DASHBOARD} /> },
    { path: ROUTEPATH.DASHBOARD, exact: true, name: 'Dashboard', component: Dashboard },
    { path: ROUTEPATH.PROFILE, exact: true, name: 'Profile', component: Profile },
    { path: '/basic/button', exact: true, name: 'Basic Button', component: UIBasicButton },
    { path: '/basic/badges', exact: true, name: 'Basic Badges', component: UIBasicBadges },
    { path: '/basic/breadcrumb-paging', exact: true, name: 'Basic Breadcrumb Pagination', component: UIBasicBreadcrumbPagination },
    { path: '/basic/collapse', exact: true, name: 'Basic Collapse', component: UIBasicCollapse },
    { path: '/basic/tabs-pills', exact: true, name: 'Basic Tabs & Pills', component: UIBasicTabsPills },
    { path: '/basic/typography', exact: true, name: 'Basic Typography', component: UIBasicBasicTypography },
    { path: '/forms/form-basic', exact: true, name: 'Forms Elements', component: FormsElements },
    { path: '/tables/bootstrap', exact: true, name: 'Bootstrap Table', component: BootstrapTable },
    { path: '/charts/nvd3', exact: true, name: 'Nvd3 Chart', component: Nvd3Chart },
    { path: '/maps/google-map', exact: true, name: 'Google Map', component: GoogleMap },
    { path: '/sample-page', exact: true, name: 'Sample Page', component: OtherSamplePage },
    { path: '/docs', exact: true, name: 'Documentation', component: OtherDocs },
    { path: ROUTEPATH.MASTER_DATA_CATEGORY_PRODUCT, exact: true, name: 'Product Category', component: ProductCategory },
    { path: ROUTEPATH.MASTER_DATA_ADD_CATEGORY_PRODUCT, exact: true, name: 'Product Category Add', component: AddProductCategory },
    { path: ROUTEPATH.MASTER_DATA_UPDATE_CATEGORY_PRODUCT, exact: true, name: 'Product Category Add', component: AddProductCategory },
    { path: ROUTEPATH.MANAGEMENT_PLAFOND, exact: true, name: 'Manajemen Plafond', component: ManagementPlafond },
    { path: ROUTEPATH.MANAGEMENT_PLAFOND_DETAIL, exact: true, name: 'Manajemen Plafond Details', component: DetailsManagementPlafond },
    { path: ROUTEPATH.MANAGEMENT_MITRA, exact: true, name: 'Manajemen Mitra', component: ManagementMitra },
    { path: ROUTEPATH.MANAGEMENT_MITRA_ADD, exact: true, name: 'Tambah Manajemen Mitra ', component: AddManagemenMitra },
    { path: ROUTEPATH.MANAGEMENT_MITRA_UPDATE, exact: true, name: 'Tambah Manajemen Mitra ', component: AddManagemenMitra },
    { path: ROUTEPATH.MANAGEMENT_MITRA_DETAIL, exact: true, name: 'Edit Manajemen Mitra ', component: DetailManagementMitra },
    { path: ROUTEPATH.SHIPPING_METHOD, exact: true, name: 'Metode Pengiriman ', component: ShippingMethod },
    { path: ROUTEPATH.SHIPPING_METHOD_ADD, exact: true, name: 'Metode Pengiriman ', component: AddShippingMethod },
    { path: ROUTEPATH.SHIPPING_METHOD_UPDATE, exact: true, name: 'Metode Pengiriman ', component: AddShippingMethod },
    { path: ROUTEPATH.MANAGEMENT_ROLE, exact: true, name: 'Role ', component: ManagementRole },
    { path: ROUTEPATH.MANAGEMENT_ROLE_ADD, exact: true, name: 'Role ', component: AddRole },
    { path: ROUTEPATH.MANAGEMENT_ROLE_UPDATE, exact: true, name: 'Role ', component: AddRole },
    { path: ROUTEPATH.MANAGEMENT_CONTENT_FAQ, exact: true, name: 'FAQ ', component: ManagementContentFAQ },
    { path: ROUTEPATH.MANAGEMENT_CONTENT_FAQ_ADD, exact: true, name: 'FAQ ', component: AddFAQ },
    { path: ROUTEPATH.MANAGEMENT_CONTENT_FAQ_UPDATE, exact: true, name: 'FAQ ', component: AddFAQ },
    { path: ROUTEPATH.MANAGEMENT_CONTENT_PROMO, exact: true, name: 'PROMO ', component: ManagementContentPromo },
    { path: ROUTEPATH.MANAGEMENT_CONTENT_PROMO_ADD, exact: true, name: 'PROMO ', component: AddPromo },
    { path: ROUTEPATH.MANAGEMENT_CONTENT_PROMO_UPDATE, exact: true, name: 'PROMO ', component: AddPromo },
    { path: ROUTEPATH.SETTING, exact: true, name: 'Pengaturan', component: Setting },
    { path: ROUTEPATH.MASTER_DATA_SATUAN, exact: true, name: 'Pengaturan', component: MasterDataSatuan },
    { path: ROUTEPATH.MASTER_DATA_SATUAN_ADD, exact: true, name: 'Pengaturan', component: AddSatuan},
    { path: ROUTEPATH.MASTER_DATA_SATUAN_UPDATE, exact: true, name: 'Pengaturan', component: AddSatuan},
    { path: ROUTEPATH.MASTER_DATA_GROUP_MITRA, exact: true, name: 'Pengaturan', component: GroupMitra },
    { path: ROUTEPATH.MASTER_DATA_GROUP_MITRA_ADD, exact: true, name: 'Pengaturan', component: AddGroupMitra},
    { path: ROUTEPATH.MASTER_DATA_GROUP_MITRA_UPDATE, exact: true, name: 'Pengaturan', component: AddGroupMitra},
    { path: ROUTEPATH.MEDIA, exact: true, name: 'Pengaturan', component: PageMedia},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT, exact: true, name: 'Product', component: Product},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_ADD, exact: true, name: 'Product Add', component: AddProduct},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_UPDATE, exact: true, name: 'Product Update', component: AddProduct},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_DETAIL, exact: true, name: 'Product Details', component: Details},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_UNGGULAN, exact: true, name: 'Product', component: ProductUnggulan},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_MANAGEMENT_STOCK, exact: true, name: 'Manajement Stock', component: ManagementStock},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET, exact: true, name: 'Produk Paket', component: ProductPaket},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET_ADD, exact: true, name: 'Produk Paket Add', component: AddProductPaket},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET_UPDATE, exact: true, name: 'Produk Paket', component: AddProductPaket},
    { path: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET_DETAIL, exact: true, name: 'Produk Details', component: DetailsProductPaket},
    { path: ROUTEPATH.MANAGEMENT_ADMIN, exact: true, name: 'Produk Details', component: PageAdmin},
    { path: ROUTEPATH.MANAGEMENT_ADMIN_ADD, exact: true, name: 'Produk Details', component: AddManagementAdmin},
    { path: ROUTEPATH.MANAGEMENT_ADMIN_UPDATE, exact: true, name: 'Produk Details', component: AddManagementAdmin},
    { path: ROUTEPATH.MANAGEMENT_TRANSACTION, exact: true, name: 'Manajemen Transaksi', component: ManagementTransaction},
    { path: ROUTEPATH.MANAGEMENT_TRANSACTION_DETAILS, exact: true, name: 'Manajemen Transaksi', component: DetailsTransaction},
    { path: ROUTEPATH.MANAGEMENT_TRANSACTION_DETAILS_VIEWS, exact: true, name: 'Manajemen Transaksi', component: DetailsTransaction},
    { path: ROUTEPATH.MANAGEMENT_CONTENT_BANNER, exact: true, name: 'Manajemen Konten Banner', component: ManagementContentBanner},
    { path: ROUTEPATH.MANAGEMENT_CONTENT_BANNER_ADD, exact: true, name: 'Manajemen Konten Banner', component: AddBanner},
    { path: ROUTEPATH.MANAGEMENT_CONTENT_BANNER_UPDATE, exact: true, name: 'Manajemen Konten Banner', component: AddBanner},
    { path: ROUTEPATH.MANAGEMENT_CONTENT_PAGE, exact: true, name: 'Manajemen Konten Page', component: ManagementContentPage},
    { path: ROUTEPATH.MANAGEMENT_CONTENT_PAGE_ADD, exact: true, name: 'Manajemen Konten Page', component: AddPage},
    { path: ROUTEPATH.MANAGEMENT_CONTENT_PAGE_UPDATE, exact: true, name: 'Manajemen Konten Page', component: AddPage},
    { path: ROUTEPATH.REPORT_STOCK, exact: true, name: 'Laporan Stok', component: ReportStock},
    { path: ROUTEPATH.REPORT_SALE, exact: true, name: 'Laporan Penjualan', component: ReportSale},
];

export default routes;