import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import environments from '../../../environments';
import fetchAxios from '../../../helpers/fetchAxios';
import { ValidateTransactions } from '../../../validate';
import Inputs from '../../components/Inputs';
import SweetAlert from '../../components/SweetAlert';

function SendNotif(props) {
    const {
        show,
        handleClose,
        id
    } = props
    let initialPayload = {
        title: "",
        message: ""
    }
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState(initialPayload);

    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateTransactions.sendNotif,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    const onConfirm = async() => {
      const body = {
          transaction_id: id,
          title: payload.title,
          message: payload.message,
      }
      const response = await fetchAxios(
          `${environments.api.transaction}/send-notif` , 
          "post", 
          body);
      if (response.status === 200) {
          formik.resetForm()
          toast.success(response.data.message)
          setIsConfirm(false)
          handleClose()
      } else {
          setIsConfirm(false)
      }
  }
  
  return (
    <Modal 
        show={show} 
        onHide={handleClose}
        backdrop="static"
        centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Kirim Notifikasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
                <Inputs
                    formik={ formik }
                    label="Judul"
                    type='text'
                    id="title"
                    name="title"
                    values={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <Inputs
                    formik={ formik }
                    label="Pesan"
                    type='textarea'
                    id="message"
                    name="message"
                    values={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kembali
          </Button>
          <Button variant="success" type='submit'>
            Kirim
          </Button>
        </Modal.Footer>
      </form>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={`Konfirmasi Kirim Notifikasi`}
            message={`Apakah kamu yakin untuk Kirim Notifikasi Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal22"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Modal>
  )
}

export default SendNotif