/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import fetchAxios from '../../../../helpers/fetchAxios';
import environments from '../../../../environments';
import { encodeQueryData } from '../../../../helpers/endcodeQuery';
import * as ROUTEPATH from '../../../../routePath';
import SweetAlert from '../../../components/SweetAlert';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { ColumProductPaket } from '../../../components/Colums/ColumProduct';

function ProductPaket(props) {
    const [data, setData] = useState([]);
    const [dataTable, setDataTable] = useState();
    const [isConfirmDeleted, setIsConfirmDeleted] = useState(false);
    const [idDeleted, setIdDeleted] = useState('')
    const [keyword, setKeyword] = useState('');
    const searchInput = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [hitParams, setHitParams] = useState({
        orderby: "",
        sort: "desc",
        page: 1,
        per_page: 10,
        status: "",
        key: "",
        product_category_id: "",
        type: "paket",
    })
    const showAlertDeleted = (id) => {
        setIdDeleted(id);
        setIsConfirmDeleted(true)
    }

    props = {
        showAlertDeleted
    }

    useEffect(() => {
        fetchProduct()
    },[hitParams, isConfirmDeleted])

    const fetchProduct = async () => {
        const response = await fetchAxios(
            environments.api.product + (Object.keys(hitParams).length > 0 ? "?" + encodeQueryData(hitParams) : ""),
            'get'
        )
        setData(response.data?.data?.data)
        setDataTable(response.data)
        setIsLoading(false)
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        setHitParams({
            ...hitParams,
            page: page,
            per_page: newPerPage,
        })
	};
    const handlePageChange = page => {
        setHitParams({
            ...hitParams,
            page: page,
        })
	};
    const onConfirm = async() => {
        const response = await fetchAxios(
            `${environments.api.product}/${idDeleted}`,
            "delete"
        )
        if (response.status === 200) {
            toast.success(response?.data?.message)
            setIsConfirmDeleted(false);
        }else {
            setIsConfirmDeleted(false);
        }
    }
    const searchAction = debounce((e) => {
        setHitParams({
            ...hitParams,
            key: searchInput?.current.value
        })
    }, 500)

  return (
    <Fragment>
        <div className='d-flex justify-content-between'>
            <div>
                <Button 
                    variant="success"
                    onClick={() => {window.location.href = ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET_ADD}}
                >Tambah</Button>{' '}
            </div>
            <div>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Pencarian"
                        aria-label="search"
                        aria-describedby="basic-addon1"
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value)
                            searchAction(e);
                        }}
                        ref={ searchInput }
                        style={{ backgroundColor: '#fff' }}
                    />
                </InputGroup>
            </div>
        </div>
        <DataTable
            columns={ColumProductPaket(props)}
            data={data}
            noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
            pagination
            paginationServer
            responsive
            paginationTotalRows={dataTable?.data?.total}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={isLoading}
            paginationRowsPerPageOptions={[10,25,50,100,250]}
        />
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirmDeleted}
            title="Konfirmasi delete Produk"
            message={`Apakah kamu yakin untuk delete Produk Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal"
            onCancel={() => {
              setIsConfirmDeleted(false);
            }}
        />
    </Fragment>
  )
}

export default ProductPaket