import React from "react";
import { Button } from "react-bootstrap";

const ColumManagementPlafond = (props) => {
  return [
    {
      name: "Kode",
      sortable: true,
      selector: (row) => row.code,
    },
    {
      name: "Nama",
      sortable: true,
      width: "200px",
      selector: (row) => row.name,
    },
    {
      name: "Telepon",
      sortable: true,
      omit: true,
      selector: (row) => row.phone,
    },
    {
      name: "Limit",
      sortable: true,
      selector: (row) => Number(row.limit_plafond).toLocaleString("id"),
    },
    {
      name: "Limit Terpakai",
      sortable: true,
      selector: (row) => Number(row.limit_plafond_used).toLocaleString("id"),
    },
    {
      name: "Tempo",
      sortable: true,
      selector: (row) => row.tempo,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
    },
    {
      name: "Aksi",
      width: "200px",
      sortable: true,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-outline-success"
                  size="sm"
                  onClick={() => {
                    window.location.href = `/manajemen-plafond/detail/${row.id}`;
                  }}
                >
                  Lihat
                </Button>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export default ColumManagementPlafond;
