const masterData = "/master-data";
const managementPlafond = "/manajemen-plafond";
const managementMitra = "/manajemen-mitra";
const managementAdmin = "/manajemen-admin";
const managementContent = "/manajemen-konten";
const settings = "/pengaturan";
const media = "/media";
const managementProduct = "/manajemen-produk";
const managementTransaction = "/manajemen-transaksi";
const report = "/laporan";

export const SIGNIN = `/auth/signin`;
export const SIGNUP = `/auth/signup`;
export const FOTGOTE = `/auth/forgot`;
export const RESETPASSWORD = `/auth/reset-password`;

export const DASHBOARD = `/dashboard`;
export const PROFILE = `/profile`;

export const MASTER_DATA_CATEGORY_PRODUCT = `${masterData}/produk-kategori`;
export const MASTER_DATA_ADD_CATEGORY_PRODUCT = `${masterData}/produk-kategori/add`;
export const MASTER_DATA_UPDATE_CATEGORY_PRODUCT = `${masterData}/produk-kategori/:id`;

export const MANAGEMENT_PLAFOND = `${managementPlafond}`;
export const MANAGEMENT_PLAFOND_DETAIL = `${managementPlafond}/detail/:id`;

export const MANAGEMENT_MITRA = `${managementMitra}`;
export const MANAGEMENT_MITRA_ADD = `${managementMitra}/add`;
export const MANAGEMENT_MITRA_UPDATE = `${managementMitra}/:id`;
export const MANAGEMENT_MITRA_DETAIL = `${managementMitra}/detail/:id`;

export const SHIPPING_METHOD = `${masterData}/metode-pengiriman`;
export const SHIPPING_METHOD_ADD = `${masterData}/metode-pengiriman/add`;
export const SHIPPING_METHOD_UPDATE = `${masterData}/metode-pengiriman/:id`;

export const MANAGEMENT_ROLE = `${managementAdmin}/role`;
export const MANAGEMENT_ROLE_ADD = `${managementAdmin}/role/add`;
export const MANAGEMENT_ROLE_UPDATE = `${managementAdmin}/role/:id`;

export const MANAGEMENT_ADMIN = `${managementAdmin}/admin`;
export const MANAGEMENT_ADMIN_ADD = `${managementAdmin}/admin/add`;
export const MANAGEMENT_ADMIN_UPDATE = `${managementAdmin}/admin/:id`;

export const MANAGEMENT_CONTENT_FAQ = `${managementContent}/faq`;
export const MANAGEMENT_CONTENT_FAQ_ADD = `${managementContent}/faq/add`;
export const MANAGEMENT_CONTENT_FAQ_UPDATE = `${managementContent}/faq/:id`;

export const MANAGEMENT_CONTENT_PROMO = `${managementContent}/promo`;
export const MANAGEMENT_CONTENT_PROMO_ADD = `${managementContent}/promo/add`;
export const MANAGEMENT_CONTENT_PROMO_UPDATE = `${managementContent}/promo/:id`;

export const MANAGEMENT_CONTENT_BANNER = `${managementContent}/banner`;
export const MANAGEMENT_CONTENT_BANNER_ADD = `${managementContent}/banner/add`;
export const MANAGEMENT_CONTENT_BANNER_UPDATE = `${managementContent}/banner/:id`;

export const MANAGEMENT_CONTENT_PAGE = `${managementContent}/page`;
export const MANAGEMENT_CONTENT_PAGE_ADD = `${managementContent}/page/add`;
export const MANAGEMENT_CONTENT_PAGE_UPDATE = `${managementContent}/page/:id`;

export const SETTING = `${settings}`;

export const MASTER_DATA_SATUAN = `${masterData}/satuan`;
export const MASTER_DATA_SATUAN_ADD = `${masterData}/satuan/add`;
export const MASTER_DATA_SATUAN_UPDATE = `${masterData}/satuan/:id`;

export const MEDIA = `${media}`;

export const MASTER_DATA_GROUP_MITRA = `${masterData}/group-mitra`;
export const MASTER_DATA_GROUP_MITRA_ADD = `${masterData}/group-mitra/add`;
export const MASTER_DATA_GROUP_MITRA_UPDATE = `${masterData}/group-mitra/:id`;

export const MANAGEMENT_PRODUCT_PRODUCT = `${managementProduct}/produk`;
export const MANAGEMENT_PRODUCT_PRODUCT_ADD = `${managementProduct}/produk/add`;
export const MANAGEMENT_PRODUCT_PRODUCT_DETAIL = `${managementProduct}/produk/detail/:id`;
export const MANAGEMENT_PRODUCT_PRODUCT_UPDATE = `${managementProduct}/produk/:id`;

export const MANAGEMENT_PRODUCT_PRODUCT_UNGGULAN = `${managementProduct}/produk-unggulan`;

export const MANAGEMENT_PRODUCT_PRODUCT_MANAGEMENT_STOCK = `${managementProduct}/manajement-stok`;

export const MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET = `${managementProduct}/product-paket`;
export const MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET_ADD = `${managementProduct}/product-paket/add`;
export const MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET_UPDATE = `${managementProduct}/product-paket/:id`;
export const MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET_DETAIL = `${managementProduct}/product-paket/detail/:id`;

export const MANAGEMENT_TRANSACTION = `${managementTransaction}`;
export const MANAGEMENT_TRANSACTION_DETAILS = `${managementTransaction}/detail/:id`;
export const MANAGEMENT_TRANSACTION_DETAILS_VIEWS = `${managementTransaction}/detail/view/:id`;

export const REPORT_STOCK = `${report}/stok`;
export const REPORT_SALE = `${report}/penjualan`;
