/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import SweetAlert from "../../components/SweetAlert";
import environments from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import fetchAxios from "../../../helpers/fetchAxios";
import { imageError } from "../../../helpers/brokenImg";
import UpdateUploadMedia from "../../components/Modal/UpdateUploadMedia";
import ReactPaginate from "react-paginate";
import "./index.scss";

const PageMedia = () => {
  const [data, setData] = useState([]);
  const [isConfirmDeleted, setIsConfirmDeleted] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [idDeleted, setIdDeleted] = useState("");
  const [idDetail, setIdDetail] = useState();
  const [keyword, setKeyword] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const searchInput = useRef();
  const [hitParams, setHitParams] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 8,
    status: "",
    key: "",
    type: "",
  });
  useEffect(() => {
    fetchMedia();
  }, [hitParams, isConfirmDeleted, isModal]);

  const showAlertDeleted = (id) => {
    setIdDeleted(id);
    setIsConfirmDeleted(true);
  };

  const fetchMedia = async () => {
    const response = await fetchAxios(
      environments.api.media +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setIsLoadingPage(false);
      setData(response.data?.data);
    }
  };

  const handleUpdate = (value) => {
    setIdDetail(value);
    setIsModal(true);
  };

  const onConfirm = async () => {
    const response = await fetchAxios(
      `${environments.api.media}/${idDeleted}`,
      "delete"
    );
    if (response.status === 200) {
      toast.success(response?.data?.message);
      setIsConfirmDeleted(false);
    } else {
      setIsConfirmDeleted(false);
    }
  };

  const searchAction = debounce((e) => {
    setHitParams({
      ...hitParams,
      key: searchInput?.current.value,
    });
  }, 500);

  const handlePageClick = (event) => {
    setHitParams({
      ...hitParams,
      page: event.selected + 1,
    });
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {isLoadingPage ? (
        <Skeleton count={30} />
      ) : (
        <Fragment>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <Button variant="success" onClick={() => setIsModal(true)}>
                Upload File
              </Button>{" "}
            </div>
            <div>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Pencarian"
                  aria-label="search"
                  aria-describedby="basic-addon1"
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    searchAction(e);
                  }}
                  ref={searchInput}
                  style={{ backgroundColor: "#fff" }}
                />
              </InputGroup>
            </div>
          </div>
          <Row xs={1} md={3} lg={5} className="justify-content-between">
            {data?.data?.length === 0 ? (
              <div className="d-flex justify-content-center">
                <div>
                  <p style={{ color: "black", fontSize: "20px" }}>
                    Data tidak ditemukan
                  </p>
                </div>
              </div>
            ) : (
              data?.data?.map((value) => {
                return (
                  <Card className="mx-2">
                    <img
                      onError={(e) => imageError(e)}
                      className="img-fluid center"
                      style={{ height: "200px" }}
                      width={240}
                      src={value.file}
                      alt={value.title}
                    />
                    <Card.Body>
                      <Card.Title>{value.title}</Card.Title>
                      <div className="container-desc">
                        <p className="clamp-line-3">{value.description}</p>
                      </div>
                    </Card.Body>
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="success"
                        onClick={() => handleUpdate(value.id)}
                      >
                        Ubah
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => showAlertDeleted(value.id)}
                      >
                        hapus
                      </Button>
                    </div>
                  </Card>
                );
              })
            )}
          </Row>
          <ReactPaginate
            previousLabel={"kembali"}
            nextLabel={"lanjut"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={data.total / data.per_page}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
          <UpdateUploadMedia
            show={isModal}
            handleClose={() => {
              setIsModal(false);
              setIdDetail("");
            }}
            idDetail={idDetail}
          />
          <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirmDeleted}
            title="Konfirmasi delete Media"
            message={`Apakah kamu yakin untuk delete Media Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal"
            onCancel={() => {
              setIsConfirmDeleted(false);
            }}
          />
        </Fragment>
      )}
    </div>
  );
};

export default PageMedia;
