/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ColumManagementTransaction } from "../../components/Colums/ColumProduct";
import environments from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import fetchAxios from "../../../helpers/fetchAxios";
import Selects from "../../components/Selects";
import Inputs from "../../components/Inputs";
import { useFormik } from "formik";
import { listAllStatus } from "../../../utilities/constants";
import DatePickers from "../../components/DatePickers";
import { Button, Col, Row } from "react-bootstrap";
import dayjs from "dayjs";

export const ManagementTransaction = (props) => {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [listMitra, setListMitra] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hitParams, setHitParams] = useState({
    orderby: "id",
    sort: "desc",
    page: 1,
    per_page: 10,
    status: "",
    code: "",
    mitra_id: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    fetchMitra();
  }, []);

  const fetchMitra = async () => {
    let paramsMitra = {
      orderby: "",
      sort: "desc",
      page: 1,
      per_page: 999,
      status: "",
      key: "",
      mitra_group_id: "",
    };
    const response = await fetchAxios(
      environments.api.mintra_management +
        (Object.keys(paramsMitra).length > 0
          ? "?" + encodeQueryData(paramsMitra)
          : ""),
      "get"
    );
    setListMitra(response.data?.data?.data);
  };

  const optionsMitra = listMitra?.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  useEffect(() => {
    fetchTransaction();
  }, [hitParams]);

  const fetchTransaction = async () => {
    const response = await fetchAxios(
      environments.api.transaction +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    setData(response.data?.data?.data);
    setDataTable(response.data);
    setIsLoading(false);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setHitParams({
      ...hitParams,
      page: page,
      per_page: newPerPage,
    });
  };

  const handlePageChange = (page) => {
    setHitParams({
      ...hitParams,
      page: page,
    });
  };

  const formik = useFormik({
    initialValues: {
      no_transaction: "",
      mitra: "",
      status: "",
      start_date: "",
      end_date: "",
    },
    onSubmit: (values) => {
      setHitParams({
        ...hitParams,
        orderby: "id",
        sort: "desc",
        page: 1,
        per_page: 10,
        status: values.status,
        code: values.no_transaction,
        mitra_id: values.mitra,
        start_date: values.start_date
          ? dayjs(values.start_date).format("YYYY-MM-DD")
          : "",
        end_date: values.end_date
          ? dayjs(values.end_date).format("YYYY-MM-DD")
          : "",
      });
    },
  });

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col sm={4}>
            <Inputs
              formik={formik}
              label=""
              type="text"
              id="no_transaction"
              name="no_transaction"
              placeholder="No Transaksi"
              values={formik.values.no_transaction}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col sm={4}>
            <Selects
              label=""
              name="mitra"
              options={optionsMitra}
              placeholder="Semua Mitra"
              onChange={(e) => {
                formik.setFieldValue("mitra", e ? e.value : "");
              }}
              isClearable={true}
            />
          </Col>
          <Col sm={4}>
            <Selects
              label=""
              name="status"
              options={listAllStatus}
              placeholder="Semua Status"
              onChange={(e) => {
                formik.setFieldValue("status", e ? e.value : "");
              }}
              isClearable={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <DatePickers
              label=""
              name="start_date"
              dateFormat="d MMMM yyyy"
              selected={startDate}
              placeholderText="Tanggal Awal"
              onChange={(e) => {
                setStartDate(e);
                formik.setFieldValue("start_date", e ? e : "");
              }}
            />
          </Col>
          <Col>
            <DatePickers
              label=""
              name="end_date"
              dateFormat="d MMMM yyyy"
              selected={endDate}
              placeholderText="Tanggal Akhir"
              onChange={(e) => {
                setEndDate(e);
                formik.setFieldValue("end_date", e ? e : "");
              }}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Button variant="primary" type="submit">
              Cari Data
            </Button>
          </Col>
        </Row>
      </form>
      <DataTable
        columns={ColumManagementTransaction(props)}
        data={data}
        noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
        pagination
        paginationServer
        paginationTotalRows={dataTable?.data?.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressPending={isLoading}
        paginationRowsPerPageOptions={[10,25,50,100,250]}
      />
    </Fragment>
  );
};
