/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import Button from 'react-bootstrap/Button';

import Inputs from '../../components/Inputs';
import SweetAlert from '../../components/SweetAlert';
import fetchAxios from '../../../helpers/fetchAxios';
import environments from '../../../environments';
import { toast } from 'react-toastify';
import { ValidateMitraManagement } from '../../../validate';

const DetailUpdatePopup = (props) => {
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState();
    const {
        show,
        handleClose,
        allData,
    } = props;
    let initialPayload = {
        total_limit: '',
        tempo: ''
    }
    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateMitraManagement.updatePlafond,
        onSubmit: (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    useEffect(() => {
        if (show) {
            formik.setFieldValue('total_limit', allData?.limit_plafond)
            formik.setFieldValue('tempo', allData?.tempo)
        }
    },[show])
    const onConfirm = async() => {
        const body = {
            limit_plafond: payload.total_limit,
            tempo: payload.tempo
        }
        const response = await fetchAxios(
            `${environments.api.management_plafond}/${allData?.id}` , 
            "put", 
            body);
        if (response.status === 200) {
            toast.success(response.data.message)
            setIsConfirm(false)
            handleClose()
        } else {
            setIsConfirm(false)
            handleClose()
        }
    }
  return (
    <Fragment>
        <Modal 
            show={show} 
            onHide={handleClose} 
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <Modal.Title>Ubah Plafond Mitra</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Inputs
                        formik={ formik }
                        label="Total Limit"
                        type='number'
                        id="total_limit"
                        name="total_limit"
                        values={formik.values.total_limit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Inputs
                        formik={ formik }
                        label="Tempo"
                        type='number'
                        id="tempo"
                        name="tempo"
                        values={formik.values.tempo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Kembali
                </Button>
                <Button variant="primary" type='submit'>
                    Simpan
                </Button>
                </Modal.Footer>
            </form>
        </Modal>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={"Konfirmasi Ubah Plafond"}
            message={`Apakah kamu yakin untuk merubah Plafond Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Fragment>
  )
}

export default DetailUpdatePopup