/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";

import DetailInformations from "./detailInformations";
import DetailTabels from "./detailTabels";
import * as ROUTEPATH from "../../../routePath";
import ModalActive from "./modalActive";

const DetailManagementMitra = () => {
  const [showActive, setShowActive] = useState(false);
  const [allData, setAllData] = useState();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      fetchDetailMitra();
    }
  }, [id, showActive]);
  const fetchDetailMitra = async () => {
    const response = await fetchAxios(
      `${environments.api.mintra_management}/${id}`,
      "get"
    );
    if (response.status === 200) {
      setAllData(response.data?.data);
    }
  };

  return (
    <Fragment>
      <div className="d-flex mb-5 align-items-center">
        <div className="pl-1 pr-3">
          <h3 style={{ color: "#5E5873" }}>Lihat Mitra</h3>
        </div>
        <div>
          <h3>
            <span className="badge bg-secondary">{allData?.code}</span>
          </h3>
        </div>
        <div className="pl-1">
          <p className="pt-2" style={{ color: "green" }}>
            {allData?.status}
          </p>
        </div>
      </div>
      <DetailInformations allData={allData} />
      <DetailTabels />
      <Col className="my-3">
        <div className="d-flex">
          <div>
            <Button
              variant="outline-secondary"
              onClick={() =>
                (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_MITRA}`)
              }
            >
              Kembali
            </Button>{" "}
          </div>
          <div>
            <Button
              variant="success"
              onClick={() => {
                window.location.href = `/manajemen-mitra/${allData?.id}`;
              }}
            >
              Ubah
            </Button>{" "}
          </div>
          <div>
            <Button
              variant="danger"
              onClick={() =>
                (window.location.href = ROUTEPATH.MANAGEMENT_MITRA)
              }
            >
              Hapus
            </Button>{" "}
          </div>
          <div>
            <Button variant="warning" onClick={() => setShowActive(true)}>
              Non Aktif
            </Button>{" "}
          </div>
        </div>
      </Col>
      <ModalActive
        show={showActive}
        handleClose={() => setShowActive(false)}
        allData={allData}
      />
    </Fragment>
  );
};

export default DetailManagementMitra;
