/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { toast } from "react-toastify";
import environments, { env } from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import fetchAxios from "../../../helpers/fetchAxios";
import SweetAlert from "../../components/SweetAlert";
import DetailInformations from "./detailInformations";
import SendNotif from "./sendNotif";
import SendOrder from "./sendOrder";
import * as ROUTEPATH from "../../../routePath";
import ModalCancel from "./cancel";
import ModalConfirmOrder from "./confirmOrder";
import ModalProcessingOrder from "./processOrder";
import Cookies from "js-cookie";
import DetailFaktur from "./detailFaktur";

const DetailsTransaction = () => {
  const role = JSON.parse(Cookies.get("userObj"));
  const { id } = useParams();
  const [isView, setIsView] = useState(false);
  const [isSendOrder, setIsSendOrder] = useState(false);
  const [isSendOrderUpdate, setIsSendOrderUpdate] = useState(false);
  const [isSendNotif, setIsSendNotif] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  const [isRetur, setIsRetur] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isConfirmOrder, setIsConfirmOrder] = useState(false);
  const [isProcessOrder, setIsProcessOrder] = useState(false);
  const [isRoleBack, setIsRoleBack] = useState(false);
  const [isTransactionDone, setIsTransactionDone] = useState(false);
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [isDetailFakture, setDetailFakture] = useState(false);
  const [allData, setAllData] = useState();
  const [listShippingMethod, setListShippingMethod] = useState([]);
  const [hitParams] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 999,
    status: "",
    key: "",
  });
  let history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    if (match.path === "/manajemen-transaksi/detail/view/:id") {
      setIsView(true);
    } else {
      setIsView(false);
    }
  }, []);

  const onConfirmRefund = async () => {
    const body = {
      transaction_id: id,
      is_refund: true,
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-refund`,
      "put",
      body,
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      setIsRefund(false);
    } else {
      setIsRefund(false);
    }
  };

  const onConfirmRetur = async () => {
    const body = {
      transaction_id: id,
      is_retur: true,
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-retur`,
      "put",
      body,
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      setIsRetur(false);
    } else {
      setIsRetur(false);
    }
  };

  const onConfirmSending = async () => {
    const body = {
      transaction_id: id,
      status: "sudah diterima",
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-status`,
      "put",
      body,
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      setIsSending(false);
    } else {
      setIsSending(false);
    }
  };

  const onConfirmSend = async () => {
    const body = {
      transaction_id: id,
      status: "sedang dikirim",
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-status`,
      "put",
      body,
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      setIsSend(false);
    } else {
      setIsSend(false);
    }
  };

  const onConfirm = async () => {
    const body = {
      transaction_id: id,
      status: "selesai",
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-status`,
      "put",
      body,
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      setIsTransactionDone(false);
    } else {
      setIsTransactionDone(false);
    }
  };

  const onConfirmCancelOrder = async () => {
    const body = {
      transaction_id: id,
      cancel_reason: "batalkan pesanan",
      status: "batalkan",
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-status`,
      "put",
      body,
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      setIsCancelOrder(false);
      history.push(ROUTEPATH.MANAGEMENT_TRANSACTION);
    } else {
      setIsCancelOrder(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [
    id,
    isSendOrder,
    isSend,
    isTransactionDone,
    isRetur,
    isRefund,
    isCancel,
    isSending,
    isSendOrderUpdate,
    isSendNotif,
    isConfirmOrder,
  ]);

  const fetchDetails = async () => {
    const response = await fetchAxios(
      `${environments.api.transaction}/${id}`,
      "get",
    );
    if (response.status === 200) {
      setAllData(response.data?.data);
    }
  };

  useEffect(() => {
    fetchListShipping();
  }, []);

  const fetchListShipping = async () => {
    const response = await fetchAxios(
      environments.api.shipping_method +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get",
    );
    setListShippingMethod(response.data?.data?.data);
  };

  const optionsShippingMethod = listShippingMethod.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const raiseInvoiceClicked = () => {
    const url = allData.print_url;
    window.open(url, "_blank");
  };

  const cetakDOClickled = () => {
    const url = allData.print_url + "&type=do";
    window.open(url, "_blank");
  };

  const handleRoleBack = async () => {
    const response = await fetchAxios(
      `${environments.api.transaction}/rollback/${id}`,
      "put",
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`;
    } else {
      window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`;
    }
  };

  let components;
  if (role?.role?.name === "Supply Chain") {
    if (
      allData?.status === "menunggu persetujuan" ||
      allData?.status === "menunggu konfirmasi" ||
      allData?.status === "pesanan telah dikonfirmasi" ||
      allData?.status === "sudah diterima" ||
      allData?.status === "selesai" ||
      allData?.status === "batalkan"
    ) {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
        </div>
      );
    } else if (allData?.status === "sedang diproses") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button
            variant="warning"
            onClick={() => {
              setIsSendOrder(true);
              setIsSendOrderUpdate(true);
            }}
          >
            Kirim Barang
          </Button>
        </div>
      );
    } else if (allData?.status === "sedang dikirim") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="info" onClick={cetakDOClickled}>
            Cetak DO
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              setIsSendOrder(true);
              setIsSendOrderUpdate(true);
            }}
          >
            Update Informasi Driver
          </Button>
        </div>
      );
    }
  } else if (role?.role?.name === "Finance") {
    if (
      allData?.status === "menunggu persetujuan" ||
      allData?.status === "menunggu konfirmasi" ||
      allData?.status === "pesanan telah dikonfirmasi" ||
      allData?.status === "sedang diproses" ||
      allData?.status === "sedang dikirim" ||
      allData?.status === "batalkan"
    ) {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
        </div>
      );
    } else if (allData?.status === "sudah diterima") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="primary" onClick={() => setIsSendNotif(true)}>
            Kirim Notif
          </Button>
          <Button variant="success" onClick={() => setIsTransactionDone(true)}>
            Selesai
          </Button>
        </div>
      );
    } else if (allData?.status === "selesai") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button
            variant="primary"
            onClick={() => {
              setIsRoleBack(!isRoleBack);
            }}
          >
            Role Back
          </Button>{" "}
        </div>
      );
    }
  } else if (role?.role?.name === "Super Admin") {
    components = (
      <div className="mt-3">
        <Button
          variant="secondary"
          onClick={() => {
            !isView === true
              ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
              : history.goBack();
          }}
        >
          Kembali
        </Button>{" "}
        <Button variant="danger" onClick={() => setIsCancelOrder(true)}>
          Batalkan Pesanan
        </Button>
      </div>
    );
  } else {
    if (allData?.status === "menunggu konfirmasi") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="warning" onClick={() => setIsConfirmOrder(true)}>
            Terima Pesanan
          </Button>
          <Button variant="danger" onClick={() => setIsCancelOrder(true)}>
            Batalkan Pesanan
          </Button>
        </div>
      );
    } else if (allData?.status === "pesanan telah dikonfirmasi") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="info" onClick={raiseInvoiceClicked}>
            Cetak SO
          </Button>
          <Button variant="primary" onClick={() => setIsProcessOrder(true)}>
            Pesanan Diproses
          </Button>
          <Button variant="danger" onClick={() => setIsCancelOrder(true)}>
            Batalkan Pesanan
          </Button>
        </div>
      );
    } else if (allData?.status === "sedang diproses") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="info" onClick={raiseInvoiceClicked}>
            Cetak SO
          </Button>
          <Button variant="danger" onClick={() => setIsCancelOrder(true)}>
            Batalkan Pesanan
          </Button>
        </div>
      );
    } else if (allData?.status === "sedang dikirim") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="success" onClick={() => setIsSending(true)}>
            Terkirim
          </Button>
          <Button variant="danger" onClick={() => setIsCancelOrder(true)}>
            Batalkan Pesanan
          </Button>
        </div>
      );
    } else if (allData?.status === "sudah diterima") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="primary" onClick={() => setIsSendNotif(true)}>
            Kirim Notif
          </Button>
          <Button variant="success" onClick={() => setDetailFakture(true)}>
            Detail Faktur
          </Button>
          <Button variant="danger" onClick={() => setIsCancelOrder(true)}>
            Batalkan Pesanan
          </Button>
        </div>
      );
    } else if (
      allData?.status === "selesai" ||
      allData?.status === "batalkan"
    ) {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
        </div>
      );
    } else if (allData?.status === "menunggu persetujuan") {
      components = (
        <div className="mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              !isView === true
                ? (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_TRANSACTION}`)
                : history.goBack();
            }}
          >
            Kembali
          </Button>{" "}
          <Button variant="danger" onClick={() => setIsCancelOrder(true)}>
            Batalkan Pesanan
          </Button>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="d-flex mb-5 align-items-center">
        <div className="pl-1 pr-3">
          <h3 style={{ color: "#5E5873" }}>Lihat Transaksi</h3>
        </div>
        <div>
          <h3>
            <span className="badge bg-secondary">
              {allData ? allData.code : "-"}
            </span>
          </h3>
        </div>
      </div>
      <DetailInformations allData={allData} />
      {components}
      <ModalProcessingOrder
        show={isProcessOrder}
        handleClose={() => setIsProcessOrder(false)}
        id={allData?.id}
      />
      <ModalConfirmOrder
        show={isConfirmOrder}
        handleClose={() => setIsConfirmOrder(false)}
        id={allData?.id}
      />
      <ModalCancel
        show={isCancel}
        handleClose={() => setIsCancel(false)}
        id={allData?.id}
      />
      <SendOrder
        show={isSendOrder}
        handleClose={() => setIsSendOrder(false)}
        id={allData?.id}
        listShippingMethod={listShippingMethod}
        optionsShippingMethod={optionsShippingMethod}
        data={allData}
        isSendOrderUpdate={isSendOrderUpdate}
      />
      <SendNotif
        show={isSendNotif}
        handleClose={() => setIsSendNotif(false)}
        id={allData?.id}
      />
      <DetailFaktur
        show={isDetailFakture}
        handleClose={() => setDetailFakture(false)}
        id={allData?.id}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isRoleBack}
        title="Apa anda yakin akan Role Back ?"
        message=""
        onConfirm={handleRoleBack}
        btnConfirm="Ya"
        btnCancel="Tidak"
        onCancel={() => {
          setIsRoleBack(false);
        }}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isRefund}
        title="Apa anda yakin akan refaund ?"
        message=""
        onConfirm={onConfirmRefund}
        btnConfirm="Ya"
        btnCancel="Tidak"
        onCancel={() => {
          setIsRefund(false);
        }}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isRetur}
        title="Apa anda yakin akan retur ?"
        message=""
        onConfirm={onConfirmRetur}
        btnConfirm="Ya"
        btnCancel="Tidak"
        onCancel={() => {
          setIsRetur(false);
        }}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isSend}
        title="Apa anda yakin akan kirim ?"
        message=""
        onConfirm={onConfirmSend}
        btnConfirm="Ya"
        btnCancel="Tidak"
        onCancel={() => {
          setIsSend(false);
        }}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isSending}
        title="Apa anda yakin akan kirim ?"
        message=""
        onConfirm={onConfirmSending}
        btnConfirm="Ya"
        btnCancel="Tidak"
        onCancel={() => {
          setIsSending(false);
        }}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isTransactionDone}
        title={`Transaksi Telah Selesai ?`}
        message="Limit plafon dari mitra akan ditambahkan sesuai tagihan transaksi"
        onConfirm={onConfirm}
        btnConfirm="Ya"
        btnCancel="Tidak"
        onCancel={() => {
          setIsTransactionDone(false);
        }}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isCancelOrder}
        title={`Anda yakin akan membatalkan pesanan ?`}
        // message="Limit plafon dari mitra akan ditambahkan sesuai tagihan transaksi"
        onConfirm={onConfirmCancelOrder}
        btnConfirm="Ya"
        btnCancel="Tidak"
        onCancel={() => {
          setIsCancelOrder(false);
        }}
      />
    </Fragment>
  );
};

export default DetailsTransaction;
