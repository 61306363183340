import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Col, Row, Form} from 'react-bootstrap';

const DetailInformations = (props) => {
    const {
        allData
    } = props
    
    const status = allData?.status === 'sedang diproses';
    const sedangDikirim = allData?.status === 'sedang dikirim';
    
  return (
    <Fragment>
        <Row>
            <Col>
                {
                    allData?.status !== 'menunggu konfirmasi' ? (
                        <div className='my-1'>
                            <h5>No Sales Order (SO)</h5>
                            <p>{ allData ? allData?.sales_order_number : "-"}</p>
                        </div>
                    ) : null
                }
                <div className='my-1'>
                    <h5>Tanggal</h5>
                    <Form.Text>{allData ? dayjs(allData.registration_date).format("DD MMMM YYYY") : "-"}</Form.Text>
                </div>
                <div className='my-1'>
                    <h5>Mitra</h5>
                    <Form.Text>{allData ? allData.mitra?.name : "-"}</Form.Text>
                    <Form.Text>{allData ? allData.mitra?.phone : "-"}</Form.Text>
                    <Form.Text>{allData ? allData.mitra?.shipping_address : "-"}</Form.Text>
                </div>
            </Col>
            <Col>
                <div className='my-1'>
                    <h5>Metode Pingiriman</h5>
                    <Form.Text>{allData ? allData.shipping_method?.name : "-"}</Form.Text>
                </div>
                <div className='my-1'>
                    <h5>Tanggal Pengiriman</h5>
                    <Form.Text>{allData ? dayjs(allData.shipping_date).format("DD MMMM YYYY") : "-"}</Form.Text>
                </div>
                <div className='my-1'>
                    <h5>Status</h5>
                    <p 
                    className={
                        `${
                           allData ? 
                            allData.status === 'Batalkan' ? 'danger' :
                            allData.status === 'menunggu konfirmasi' ? 'warning' :
                            allData.status === 'selesai' ? 'success' :
                            allData.status === 'sudah diterima' ? 'info' : 
                            allData.status === 'sedang dikirim' ? 'success' : 
                            allData.status === 'sedang diproses' ? 'info' : "" 
                            : ""
                        }`}
                    style={{ textTransform: 'capitalize' }}
                    >{allData ? allData.status : "-"}</p>
                </div>
                {
                    allData?.status.toLowerCase() === 'batalkan' ||
                    allData?.status === 'Permintaan Pembatalan' ? (
                        <div className='my-1'>
                            <h5>Alasan Pembatalan</h5>
                            <p>
                                {allData?.cancel_reason}
                            </p>
                        </div>
                    ) : null
                }
            </Col>
        </Row>
        <Row className='mt-5'>
            <Col>
                <h5>Produk</h5>
            </Col>
            <Col>
                <Row>
                    <Col sm={4}>
                        <h5>Harga</h5>
                    </Col>
                    <Col sm={4}>
                        <h5>Qyt</h5>
                    </Col>
                    <Col sm={3}>
                        <h5>Total</h5>
                    </Col>
                </Row>
            </Col>
        </Row>
        {
            allData ? allData?.items?.map((val) => {
                return (
                    <Row>
                        <Col>
                            <p>{val.product?.name}</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={4}>
                                    <div>
                                        <p>{Number(val?.price).toLocaleString("id")}</p>
                                    </div>
                                </Col>
                                <Col sm={4  }>
                                    <div>
                                        <p>{val?.qty}</p>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div>
                                        <p><p>{Number(val?.subtotal).toLocaleString("id")}</p></p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })
            : null
        }
        <hr />
        <Row>
            <Col>
                <div>
                    <p>Catatan</p>
                </div>
                <div>
                    {
                       allData ? allData.items.map((val) => {
                            return (
                                <p>{val.note ? val.note : "-"}</p>
                            )
                        }) : '-'
                    }
                </div>
                {
                    status && (
                        <div className='d-flex mb-2'>
                            <div className='mr-2'>
                                <div>Nama Driver</div>
                                <div>{ allData?.driver_name }</div>
                            </div>
                            <div className='ml-1'>
                                <div>Nomer Driver</div>
                                <div>{ allData?.driver_phone }</div>
                            </div>
                        </div>
                    )
                }
                {
                    sedangDikirim && (
                        <div className='d-flex mb-2'>
                            <div className='mr-2'>
                                <div>Nama Driver</div>
                                <div>{ allData?.driver_name }</div>
                            </div>
                            <div className='ml-1'>
                                <div>Nomer Driver</div>
                                <div>{ allData?.driver_phone }</div>
                            </div>
                        </div>
                    )
                }
                <div className='d-flex'>
                    <div className='mr-2'>
                        <div>No Resi</div>
                        <div>{ allData?.no_surat }</div>
                    </div>
                    <div className='ml-1'>
                        <div>Ekpedisi</div>
                        <div>{ allData?.shipping_method?.name }</div>
                    </div>
                </div>
            </Col>
            <Col>
                <Row>
                    <Col sm={4}>
                    </Col>
                    <Col sm={4  }>
                        <div>
                            <p>Subtotal</p>
                        </div>
                        <div>
                            <p>Ongkos Kirim</p>
                        </div>
                        <div>
                            <h5>Grand Total</h5>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div>
                            <p>{ Number( allData ? allData?.subtotal : 0).toLocaleString("id")}</p>
                        </div>
                        <div>
                            <p>{ Number(allData ? allData?.shipping_cost : 0).toLocaleString("id")}</p>
                        </div>
                        <div>
                            <h4>{ Number(allData ? allData?.grand_total : 0).toLocaleString("id")}</h4>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Fragment>
  )
}

export default DetailInformations