import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import environments from '../../../environments'
import fetchAxios from '../../../helpers/fetchAxios'
import { ValidateAuth } from '../../../validate'
import Inputs from '../../components/Inputs'
import SweetAlert from '../../components/SweetAlert'

export const ChangePassword = () => {
    const [isUpdate, setIsUpdate] = useState(true)
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState();
    const formik = useFormik({
        initialValues: {
            password: "",
            password_new: "",
            password_confirm: "",
        },
        validationSchema: ValidateAuth.changePassword,
        onSubmit: (values) => {
            setIsConfirm(true)
            setPayload({...values})
        }
    })

    const onConfirm = async() => {
        const body = {
            old_password: payload.password,
            password: payload.password_new,
            password_confirmation: payload.password_confirm
        }
        const response = await fetchAxios(
            `${environments.api.change_password}` , 
            "post", 
            body);
        if (response.status === 200) {
            formik.resetForm()
            toast.success(response.data.message)
            setIsConfirm(false)
            setIsUpdate(!isUpdate)
        } else {
            toast.error(response.data.message)
            setIsConfirm(false)
            setIsUpdate(!isUpdate)
        }
    }
  return (
    <Fragment>
        <form onSubmit={formik.handleSubmit}>
            <div className='d-flex justify-content-between'>
                <h5>Ubah Password</h5>
                <div onClick={() => setIsUpdate(!isUpdate)}>
                    <i 
                        className="icon feather icon-edit" 
                        style={{ fontSize: "20px", cursor: "pointer"}}/>
                </div>
            </div>
            <Inputs
                formik={ formik }
                label="Password Lama"
                type='password'
                id="password"
                name="password"
                values={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isUpdate}
            />
            <Inputs
                formik={ formik }
                label="Password Baru"
                type='password'
                id="password_new"
                name="password_new"
                values={formik.values.password_new}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isUpdate}
            />
            <Inputs
                formik={ formik }
                label="Konfirmasi Password"
                type='password'
                id="password_confirm"
                name="password_confirm"
                values={formik.values.password_confirm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isUpdate}
            />
            {
                !isUpdate && (
                    <Button variant="success" className='mt-3' type='submit'>Simpan</Button>
                )
            }
        </form>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={`Konfirmasi Ubah Password`}
            message={`Apakah kamu yakin untuk Merubah Password ?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal22"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Fragment>
  )
}
