import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";

function DatePickers(props) {
  const {
    label,
    selected,
    formik,
    name,
    dateFormat,
    onChange,
    placeholderText,
    showMonthYearPicker,
    showYearPicker,
    showDayDropdown,
    readOnly,
  } = props;
  return (
    <Fragment>
      <Form.Label>{label}</Form.Label>
      <DatePicker
        name={name}
        selected={selected}
        onChange={onChange}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        showDayDropdown={showDayDropdown}
        showMonthYearPicker={showMonthYearPicker}
        showYearPicker={showYearPicker}
        className="datepicks-default"
        readOnly={readOnly}
      />
      {formik?.touched[name] && formik.errors[name] ? (
        <small className="text-danger">{formik.errors[name]}</small>
      ) : null}
    </Fragment>
  );
}

export default DatePickers;
