import React from "react";
import { Button } from "react-bootstrap";

const ColumMitraManagement = (props) => {
  return [
    {
      name: "Kode",
      sortable: true,
      width: "110px",
      selector: (row) => row.code,
    },
    {
      name: "Nama",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Telepon",
      sortable: true,
      selector: (row) => row.phone,
    },
    {
      name: "Limit",
      sortable: true,
      selector: (row) => Number(row.limit_plafond).toLocaleString("id"),
    },
    {
      name: "Sisa Limit",
      sortable: true,
      selector: (row) => {
        console.log("data :>> ", row);
        return Number(
          row.limit_plafond - row.limit_plafond_used
        ).toLocaleString("id");
      },
    },
    {
      name: "Tempo",
      sortable: true,
      selector: (row) => row.tempo,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
    },
    {
      name: "Aksi",
      width: "300px",
      sortable: true,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-outline-info"
                  size="sm"
                  onClick={() => {
                    window.location.href = `/manajemen-mitra/detail/${row.id}`;
                  }}
                >
                  Lihat
                </Button>
              </span>
            </div>
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-outline-success"
                  size="sm"
                  onClick={() => {
                    window.location.href = `/manajemen-mitra/${row.id}`;
                  }}
                >
                  Ubah
                </Button>
              </span>
            </div>
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-danger"
                  size="sm"
                  onClick={() => props.showAlertDeleted(row.id)}
                >
                  Hapus
                </Button>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export default ColumMitraManagement;
