/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";

import Avatar1 from "../../../../assets/images/user/avatar-1.jpg";
import fetchAxios from "../../../../helpers/fetchAxios";
import environments from "../../../../environments";
import { toast } from "react-toastify";
import { ValidateManagementAdmin } from "../../../../validate";
import { imageError } from "../../../../helpers/brokenImg";
import SweetAlert from "../../../components/SweetAlert";
import * as ROUTEPATH from "../../../../routePath";
import { ModalMedia } from "../../../components/Modal/ModalMedia";
import UploadMedia from "../../../components/Modal/UploadMedia";
import CenterForm from "./centerForm";
import { encodeQueryData } from "../../../../helpers/endcodeQuery";
import RightForm from "./rightForm";

export const AddManagementAdmin = () => {
  let history = useHistory();
  const { id } = useParams();
  const [tempImages, setTempImages] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [payload, setPayload] = useState(initialPayload);
  const [showModal, setShowModal] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [details, setDetails] = useState();
  const avatarRef = useRef();

  let initialPayload = {
    full_name: "",
    email: "",
    role_id: "",
    password: "",
    status: "",
    profile_picture: "",
  };
  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateManagementAdmin.admin,
    onSubmit: async (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  const onConfirm = async () => {
    const formData = new FormData();
    formData.append("full_name", payload.full_name);
    formData.append("email", payload.email);
    formData.append("role_id", payload.role_id);
    formData.append("password", payload.password);
    formData.append("status", payload.status);
    formData.append("profile_picture", payload.profile_picture);
    const response = await fetchAxios(
      `${environments.api.management_admin_admin}${id ? `/${id}` : ""}`,
      id ? "post" : "post",
      formData
    );
    if (response.status === 200) {
      if (!id) {
        setTempImages("");
        formik.resetForm();
        toast.success(response.data.message);
        setIsConfirm(false);
        history.push(ROUTEPATH.MANAGEMENT_ADMIN);
      } else {
        toast.success(response.data.message);
        setIsConfirm(false);
        history.push(ROUTEPATH.MANAGEMENT_ADMIN);
      }
    } else {
      setIsConfirm(false);
    }
  };

  useEffect(() => {
    fetchListRole();
  }, []);

  const fetchListRole = async () => {
    const hitParams = {
      orderby: "",
      sort: "desc",
      page: 1,
      per_page: 999,
      status: "",
      key: "",
    };
    const response = await fetchAxios(
      `${environments.api.management_admin_role}` +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setListRoles(response?.data?.data?.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDetailProductCategory();
    }
  }, [id]);

  const fetchDetailProductCategory = async () => {
    const response = await fetchAxios(
      `${environments.api.management_admin_admin}/${id}`,
      "get"
    );

    if (response.status === 200) {
      setTempImages(response?.data?.data?.profile_picture);
      setDetails(response?.data?.data);
      formik.setFieldValue("full_name", response?.data?.data?.full_name);
      formik.setFieldValue("email", response?.data?.data?.email);
      formik.setFieldValue("role_id", response?.data?.data?.role_id);
      formik.setFieldValue("password", response?.data?.data?.password);
      formik.setFieldValue("status", response?.data?.data?.status);
      formik.setFieldValue(
        "profile_picture",
        response?.data?.data?.profile_picture
      );
    }
  };

  const optionsRole = listRoles.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const handleChooseImage = (value) => {
    setShowModal(false);
    formik.setFieldValue("profile_picture", value.file);
    setTempImages(value.file);
  };

  const changeAvatar = () => {
    let avatar = URL.createObjectURL(avatarRef?.current?.files[0]);
    if (avatar !== undefined && avatar !== tempImages) {
      setTempImages(avatar);
    }
  };

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mb-3">
          <h4>{id ? "Update Admin" : "Tambah Admin"}</h4>
        </Row>
        <Row>
          <Col sm="2" className="justify-content-center align-items-center">
            <div className="d-flex justify-content-center">
              <img
                onError={(e) => imageError(e)}
                className="img-radius avatar"
                src={tempImages ? tempImages : Avatar1}
                alt="Generic placeholder"
              />
            </div>
            {formik.errors.profile_picture && formik.touched.profile_picture && (
              <p
                style={{ color: "red", fontSize: "11px" }}
                className="text-center"
              >
                {formik.errors.profile_picture}
              </p>
            )}
            <div className="d-flex justify-content-center mt-2">
              <label class="custom-file-upload">
                <input
                  accept="image/*"
                  name="profile_picture"
                  type="file"
                  onChange={(e) => {
                    changeAvatar();
                    formik.setFieldValue("profile_picture", e.target.files[0]);
                  }}
                  ref={avatarRef}
                />
                Upload
              </label>
            </div>
          </Col>
          <CenterForm
            formik={formik}
            optionsRole={optionsRole}
            details={details}
            listRoles={listRoles}
          />
          <RightForm formik={formik} />
        </Row>
        <div className="d-flex mt-5">
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            Kembali
          </Button>{" "}
          <Button variant="success" type="submit">
            Simpan
          </Button>{" "}
        </div>
      </form>
      <ModalMedia
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleChooseImage={(value) => handleChooseImage(value)}
        handleNestedModal={() => setShowModalUpload(true)}
      />
      <UploadMedia
        show={showModalUpload}
        handleClose={() => setShowModalUpload(false)}
        reRenderListModal={() => setShowModalUpload(false)}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi ${id ? "Merubah" : "Tambah"} Admini`}
        message={`Apakah kamu yakin untuk ${
          id ? "merubah" : "menambahkan"
        } Admin Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal22"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Fragment>
  );
};
