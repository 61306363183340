import React from "react";
import { Button, Spinner, Row, Col } from "react-bootstrap";
import "./btn.scss";

const ButtonProcess = (p) => {
  let attr = {};

  if (p.className) {
    attr = {
      ...attr,
      className: p.className,
    };
  }

  if (p.outline) {
    attr = {
      ...attr,
      outline: true,
    };
  }

  if (p.block) {
    attr = {
      ...attr,
      block: true,
    };
  }
  if (p.type) {
    attr = {
      ...attr,
      type: p.type,
    };
  }

  return (
    <Button
      color={p.color || "primary"}
      className={p?.class}
      onClick={p.onClick}
      {...attr}
      disabled={p.disabled}
    >
      {!p.onLoading ? (
        <>        
           {p?.children && <span style={{paddingRight: "0.5rem"}}>{p.children}</span>}
           <span className="btn-label">{p.label}</span> 
        </>
 
      ) : (
        <Row className="btn-grid justify-content-center">
          <Col xs={"auto"}>
            <div className="align-middle">
              <Spinner size="sm" type="grow" />
            </div>
          </Col>
          <Col xs={"auto"}>
            <span className="align-middle ml-50">Loading...</span>
          </Col>
        </Row>
      )}
    </Button>
  );
};

export default ButtonProcess;
