/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";

import Avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import Inputs from '../../../components/Inputs';
import { imageError } from '../../../../helpers/brokenImg';
import { ValidateMasterData } from '../../../../validate';
import SweetAlert from '../../../components/SweetAlert';
import { toast } from 'react-toastify';
import environments from '../../../../environments';
import fetchAxios from '../../../../helpers/fetchAxios';
import * as ROUTEPATH from '../../../../routePath';
import { ModalMedia } from '../../../components/Modal/ModalMedia';
import UploadMedia from '../../../components/Modal/UploadMedia';

export const AddShippingMethod = () => {
    let history = useHistory();
    const { id } = useParams();
    const [tempImages, setTempImages] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showModalUpload, setShowModalUpload] = useState(false);
    
    let initialPayload = {
        name: '',
        photo: '',
        status: '',
    }
    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateMasterData.shipping,
        onSubmit: (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    const onConfirm = async() => {
        const body = {
            name: payload.name,
            photo: payload.photo,
            status: payload.status
        }
        const response = await fetchAxios(
            `${environments.api.shipping_method}${id ? `/${id}` : "" }` , 
            id ? "put" : "post", 
            body);
        if (response.status === 200) {
            if (!id) {
                setTempImages('');
                formik.resetForm()
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.SHIPPING_METHOD)
            } else {
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.SHIPPING_METHOD)
            }
        } else {
            setIsConfirm(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchDetailShipping()
        }
    }, [id])

    const fetchDetailShipping = async() => {
        const response = await fetchAxios(
            `${environments.api.shipping_method}/${id}`,
            "get"
        )
        if (response.status === 200) {
            setTempImages(response?.data?.data?.photo);
            formik.setFieldValue('name',response?.data?.data?.name)
            formik.setFieldValue('photo',response?.data?.data?.photo)
            formik.setFieldValue('status',response?.data?.data?.status)
        }
    }

    const handleChooseImage = (value) => {
        setShowModal(false)
        formik.setFieldValue('photo', value.file)
        setTempImages(value.file)
    }
     
  return (
    <Fragment>
        <form onSubmit={formik.handleSubmit}>
            <Row className='mb-3'>
                <h4>{`${id ? 'Merubah' : 'Tambah'} Metode Pengiriman`}</h4>
            </Row>
            <Row>
                <Col sm="2" className='d-flex justify-content-center align-items-center'>
                    <div className='d-flex justify-content-center'>
                        <div>
                            <div>
                                <img 
                                    onError={ e => imageError(e)}
                                    className="img-radius avatar" 
                                    src={ tempImages ? tempImages : Avatar1} 
                                    alt="Generic placeholder"
                                />
                            </div>
                            {
                                formik.errors.photo && formik.touched.photo &&
                                (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.photo }</p>)
                            }
                            <div className="d-flex justify-content-center mt-2">
                                <Button variant="primary" onClick={() => setShowModal(true)}>Upload</Button>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="5">
                    <Inputs
                        formik={ formik }
                        label="Name"
                        type='text'
                        id="name"
                        name="name"
                        values={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <p>Status</p>
                    <Form.Group>
                        <div className='d-flex'>
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="Aktif"
                                name="status"
                                id="active"
                                value='aktif'
                                onChange={(e) => {
                                    formik.setFieldValue('status',e.target.value)
                                }}
                                checked={formik.values.status === "aktif" ? true : false }
                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="Tidak Aktif"
                                name="status"
                                id="notActive"
                                value='tidak aktif'
                                onChange={(e) => {
                                    formik.setFieldValue('status',e.target.value)
                                }}
                                checked={formik.values.status === "tidak aktif" ? true : false }
                            />
                        </div>
                    </Form.Group>
                    {
                        formik.errors.status && formik.touched.status &&
                        (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.status }</p>)
                    }
                    <Row className='mt-4'>
                        <Col sm='3'>
                            <Button className='btn btn-outline-secondary' onClick={() => {window.location.href = `/cms${ROUTEPATH.SHIPPING_METHOD}`}}>Kembali</Button>{' '}
                        </Col>
                        <Col sm='3'>
                            <Button className='btn btn-success' type="submit">Simpan</Button>{' '}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
        <ModalMedia
            show={showModal}
            handleClose={() => setShowModal(false)}
            handleChooseImage={(value) => handleChooseImage(value) }
            handleNestedModal={() => setShowModalUpload(true)}
        />
        <UploadMedia
            show={showModalUpload}
            handleClose={() => setShowModalUpload(false)}
            reRenderListModal={() => setShowModal(!showModal)}
        />
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={`Konfirmasi ${id ? 'Merubah' : 'Tambah'} Metode Pengiriman`}
            message={`Apakah kamu yakin untuk ${id ? 'merubah' : 'menambahkan'}  Metode Pengiriman Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal22"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Fragment>
  )
}
