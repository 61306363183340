import React from 'react'
import { Col } from 'react-bootstrap'
import Inputs from '../../../components/Inputs'
import Selects from '../../../components/Selects'

function CenterForm(props) {
    const {
        formik,
        optionsRole,
        listRoles
    } = props

    const labelRole = listRoles.find((element) => {
        return element.id === formik.values.role_id
    })
  return (
    <Col sm="5">
        <Inputs
            formik={ formik }
            label="Name"
            type='text'
            id="full_name"
            name="full_name"
            values={formik.values.full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <div className='mt-1' />
        <Inputs
            formik={ formik }
            label="Email"
            type='text'
            id="email"
            name="email"
            values={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <div className='mt-1' />
        <Selects
            formik={formik}
            label="Role"
            name="role_id"
            options={optionsRole}
            placeholder="Pilih Role"
            onChange={(e) => { 
                formik.setFieldValue('role_id',e.value)
            }}
            value={{ value: formik.values.role_id, label: labelRole ? labelRole.name : ""}}
        />
    </Col>
  )
}

export default CenterForm