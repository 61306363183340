/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { imageError } from "../../../../helpers/brokenImg";
import Avatar1 from "../../../../assets/images/user/avatar-2.jpg";
import { ModalMedia } from "../../../components/Modal/ModalMedia";
import UploadMedia from "../../../components/Modal/UploadMedia";
import Selects from "../../../components/Selects";
import Inputs from "../../../components/Inputs";
import { listDiscountType } from "../../../../utilities/constants";
import DatePickers from "../../../components/DatePickers";
import dayjs from "dayjs";

const InputAddRight = (props) => {
  const [tempImages, setTempImages] = useState("");
  const [isModalMedia, setIsModalMedia] = useState(false);
  const [isModalUpload, setIsModalUpload] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const {
    formik,
    optionsProductCategory,
    listProductCategory,
    listMasterSatuan,
    optionsMasterSatuan,
    details,
    // optionsMitraGroup,
    // listMitraGroup
  } = props;

  useEffect(() => {
    if (details) {
      setStartDate(new Date(details?.publish_at));
      setTempImages(details.thumbnail);
    }
  }, [details]);

  const handleChooseImage = (value) => {
    setIsModalMedia(false);
    formik.setFieldValue("thumbnail", value.file);
    setTempImages(value.file);
  };

  const detailsLebelCategory = listProductCategory.find((element) => {
    return element.id === formik.values.product_category_id;
  });

  const detailsLebelDiscond = listDiscountType.find((element) => {
    return element.value === formik.values.discount_type;
  });

  const detailsLebelSatuan = listMasterSatuan.find((element) => {
    return element.id === formik.values.satuan_id;
  });

  return (
    <Col sm="5">
      <div className="d-flex justify-content-center">
        <img
          src={tempImages ? tempImages : Avatar1}
          className="img-thumbnail mx-2"
          onError={(e) => imageError(e)}
          width={100}
          style={{ height: "100px" }}
          alt="gallery"
          name="thumbnail"
        />
      </div>
      {formik.errors.thumbnail && formik.touched.thumbnail && (
        <p
          style={{ color: "red", fontSize: "11px" }}
          className="text-center mt-1"
        >
          {formik.errors.thumbnail}
        </p>
      )}
      <div className="d-flex justify-content-center mt-3">
        <Button variant="primary" onClick={() => setIsModalMedia(true)}>
          Upload Thumbnail
        </Button>
      </div>
      <div>
        <Selects
          formik={formik}
          label="Kategory"
          name={`product_category_id`}
          options={optionsProductCategory}
          placeholder="Pilih Mitra"
          onChange={(e) => {
            formik.setFieldValue("product_category_id", e.value);
          }}
          value={{
            value: details?.product_category_id,
            label: detailsLebelCategory ? detailsLebelCategory.name : "",
          }}
        />
      </div>
      <div className="my-2">
        <Inputs
          formik={formik}
          label="SKU"
          type="text"
          id="sku"
          name="sku"
          values={formik.values.sku}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="my-2">
        <Inputs
          formik={formik}
          label="Harga"
          type="number"
          id="price"
          name="price"
          values={formik.values.price}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <Row className="my-2">
        <Col>
          <Selects
            formik={formik}
            label="Diskon"
            name={`discount_type`}
            options={listDiscountType}
            placeholder="Pilih Diskon"
            onChange={(e) => {
              formik.setFieldValue("discount_type", e.value);
            }}
            value={{
              value: details?.discount_type,
              label: detailsLebelDiscond ? detailsLebelDiscond.label : "",
            }}
          />
        </Col>
        <Col>
          <Inputs
            formik={formik}
            label="Nominal"
            type="number"
            id="discount_value"
            name="discount_value"
            values={formik.values.discount_value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col className="my-2">
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Produk Dapat Dijual Tanpa Stok"
              defaultChecked={details?.is_sale_without_stock}
              onClick={(e) =>
                formik.setFieldValue("is_sale_without_stock", e.target.checked)
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <Inputs
            formik={formik}
            label="Berat"
            type="number"
            id="weight"
            name="weight"
            placeholder="Berat"
            values={formik.values.weight}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col>
          <Selects
            formik={formik}
            label="Satuan"
            name={`satuan_id`}
            options={optionsMasterSatuan}
            placeholder="satuan"
            onChange={(e) => {
              formik.setFieldValue("satuan_id", e.value);
            }}
            value={{
              value: details?.satuan_id,
              label: detailsLebelSatuan ? detailsLebelSatuan.name : "",
            }}
          />
        </Col>
      </Row>
      <p style={{ marginBottom: "5px" }} className="mt-1">
        Dimensi (
        <var>
          cm<sup>2</sup>
        </var>
        ){" "}
      </p>
      <Row>
        <Col>
          <Inputs
            formik={formik}
            label=""
            type="number"
            id="length"
            name="length"
            placeholder="panjang"
            values={formik.values.length}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col>
          <Inputs
            formik={formik}
            label=""
            type="number"
            id="width"
            name="width"
            placeholder="lebar"
            values={formik.values.width}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col>
          <Inputs
            formik={formik}
            label=""
            type="number"
            id="height"
            name="height"
            placeholder="tinggi"
            values={formik.values.height}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      {/* <div className='my-2'>
            <Selects
                formik={formik}
                label="Tampilkan Produk ke"
                name={`mitra_id`}
                options={optionsMitraGroup}
                placeholder="Semua Group Mitra"
                onChange={(e) => { 
                    formik.setFieldValue('mitra_id',e.value)
                }}
                value={{ value: details?.mitra_group_id, label: detailsLebelShowProductTo ? detailsLebelShowProductTo.name : ""}}
            />
            <p className='warning'>Biarkan kosong jika ingin menampilkan produk ke seluruh mitra</p>
        </div> */}
      <div className="my-2">
        <DatePickers
          formik={formik}
          label="Tanggal ditampilkan"
          name="publish_at"
          dateFormat="d MMMM yyyy"
          placeholderText="Pilih Tanggal"
          selected={startDate}
          onChange={(e) => {
            setStartDate(e);
            formik.setFieldValue("publish_at", dayjs(e).format("YYYY-MM-DD"));
          }}
        />
      </div>
      <div className="my-2">
        <Form.Label>Jenis Persedian Produk</Form.Label>
        <Form.Group>
          <div className="d-flex">
            <Form.Check
              inline
              custom
              type="radio"
              label="Produk Persediaan"
              name="persedian_type"
              id="persedian_type"
              value="Produk Persediaan"
              onChange={(e) => {
                formik.setFieldValue("persedian_type", e.target.value);
              }}
              checked={
                formik.values.persedian_type === "Produk Persediaan"
                  ? true
                  : false
              }
            />
            <Form.Check
              inline
              custom
              type="radio"
              label="Produk Non Persediaan"
              name="persedian_type"
              id="persedian_type"
              value="Produk Non Persediaan"
              onChange={(e) => {
                formik.setFieldValue("persedian_type", e.target.value);
              }}
              checked={
                formik.values.persedian_type === "Produk Non Persediaan"
                  ? true
                  : false
              }
            />
          </div>
          {formik.errors.persedian_type && formik.touched.persedian_type && (
            <p style={{ color: "red", fontSize: "11px" }}>
              {formik.errors.persedian_type}
            </p>
          )}
        </Form.Group>
      </div>
      <div className="my-2">
        <Form.Label>Jenis BKP</Form.Label>
        <Form.Group>
          <div className="d-flex">
            <Form.Check
              inline
              custom
              type="radio"
              label="Produk BKP"
              name="bkp_type"
              id="bkp_type"
              value="Produk BKP"
              onChange={(e) => {
                formik.setFieldValue("bkp_type", e.target.value);
              }}
              checked={formik.values.bkp_type === "Produk BKP" ? true : false}
            />
            <Form.Check
              inline
              custom
              type="radio"
              label="Produk Non BKP"
              name="bkp_type"
              id="bkp_type"
              value="Produk Non BKP"
              onChange={(e) => {
                formik.setFieldValue("bkp_type", e.target.value);
              }}
              checked={
                formik.values.bkp_type === "Produk Non BKP" ? true : false
              }
            />
            <Form.Check
              inline
              custom
              type="radio"
              label="Produk BKP & Produk Non BKP"
              name="bkp_type"
              id="bkp_type"
              value="Produk BKP & Produk Non BKP"
              onChange={(e) => {
                formik.setFieldValue("bkp_type", e.target.value);
              }}
              checked={
                formik.values.bkp_type === "Produk BKP & Produk Non BKP"
                  ? true
                  : false
              }
            />
          </div>
          {formik.errors.persedian_type && formik.touched.persedian_type && (
            <p style={{ color: "red", fontSize: "11px" }}>
              {formik.errors.persedian_type}
            </p>
          )}
        </Form.Group>
      </div>
      <div className="my-2">
        <Form.Label>Status</Form.Label>
        <Form.Group>
          <div className="d-flex">
            <Form.Check
              inline
              custom
              type="radio"
              label="Aktif"
              name="status"
              value="aktif"
              onChange={(e) => {
                formik.setFieldValue("status", e.target.value);
              }}
              checked={formik.values.status === "aktif" ? true : false}
            />
            <Form.Check
              inline
              custom
              type="radio"
              label="Tidak Aktif"
              name="status"
              value="tidak aktif"
              onChange={(e) => {
                formik.setFieldValue("status", e.target.value);
              }}
              checked={formik.values.status === "tidak aktif" ? true : false}
            />
          </div>
          {formik.errors.status && formik.touched.status && (
            <p style={{ color: "red", fontSize: "11px" }}>
              {formik.errors.status}
            </p>
          )}
        </Form.Group>
      </div>

      <ModalMedia
        show={isModalMedia}
        handleClose={() => setIsModalMedia(false)}
        handleChooseImage={(value) => handleChooseImage(value)}
        handleNestedModal={() => setIsModalUpload(true)}
      />
      <UploadMedia
        show={isModalUpload}
        handleClose={() => setIsModalUpload(false)}
        reRenderListModal={() => setIsModalUpload(false)}
      />
    </Col>
  );
};

export default InputAddRight;
