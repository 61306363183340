/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'
import Left from './left';
import Right from './right';
import * as ROUTEPATH from '../../../../routePath';
import { useHistory, useParams } from 'react-router';
import fetchAxios from '../../../../helpers/fetchAxios';
import environments from '../../../../environments';
import SweetAlert from '../../../components/SweetAlert';
import { toast } from 'react-toastify';

const Details = () => {
    const { id } = useParams();
    const history = useHistory();
    const [allData, setAllData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isConfirmNonActive, setIsConfirmNonActive] = useState(false);
    const [html, setHTML] = useState({__html: ""});
    useEffect(() => {
        fetchDetailProduct()
    },[id])

    const fetchDetailProduct = async() => {
        const response = await fetchAxios(
            `${environments.api.product}/${id}`,
            "get"
        )
        if (response.status === 200) {
            setHTML({__html: response.data?.data?.description})
            setIsLoading(false)
            setAllData(response.data?.data)
        }
    }

    const onConfirm = async() => {
        const response = await fetchAxios(
            `${environments.api.product}/${id}` , 
            "delete");
        if (response.status === 200) {
            toast.success(response.data.message)
            setIsConfirm(false)
            history.push(`${ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT}`)
        } else {
            setIsConfirm(false)
        }
      }

    const onConfirmNonActive = async() => {
        const body = {
            "status": "tidak aktif"
        }
        const response = await fetchAxios(
            `${environments.api.product}/updatestatus/${id}` , 
            "put",
            body
            );
        if (response.status === 200) {
            toast.success(response.data.message)
            setIsConfirm(false)
            history.push(`${ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT}`)
        } else {
            setIsConfirm(false)
        }
      }
    
  return (
    <Fragment>
        {
            isLoading ? <Skeleton count={25} /> : (
                <Fragment>
                    <div className='mb-4'>
                        <h3>Lihat Produk</h3>
                    </div>
                    <Row>
                        <Left allData={allData} html={html}/>
                        <Right allData={allData} />
                    </Row>
                    <Col className='my-3'>
                        <div className='d-flex'>
                            <div>
                                <Button variant="outline-secondary" onClick={() => window.location.href = `/cms${ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT}`} >Kembali</Button>{' '}
                            </div>
                            <div>
                                <Button variant="success" onClick={()=> {window.location.href = `/manajemen-produk/produk/${id}`}}>Ubah</Button>{' '}
                            </div>
                            <div>
                                <Button variant="danger" onClick={() => setIsConfirm(!isConfirm)} >Hapus</Button>{' '}
                            </div>
                            <div>
                                <Button variant="warning" onClick={() => setIsConfirmNonActive(!isConfirmNonActive)}>Non Aktif</Button>{' '}
                            </div>
                        </div>
                    </Col>
                </Fragment>
            )
        }
        <SweetAlert
          type="confirm"
          iconType="question"
          isActive={isConfirm}
          title={`Konfirmasi Delete Produk`}
          message={`Apakah kamu yakin untuk delete Produk Ini?`}
          onConfirm={onConfirm}
          btnConfirm="Konfirmasi"
          btnCancel="Batal22"
          onCancel={() => {
            setIsConfirm(false);
          }}
      />
        <SweetAlert
          type="confirm"
          iconType="question"
          isActive={isConfirmNonActive}
          title={`Konfirmasi Non Aktif`}
          message={`Apakah kamu yakin untuk Non Aktif Produk Ini?`}
          onConfirm={onConfirmNonActive}
          btnConfirm="Konfirmasi"
          btnCancel="Batal22"
          onCancel={() => {
            setIsConfirmNonActive(false);
          }}
      />
    </Fragment>
  )
}

export default Details