import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";

import Inputs from "../../components/Inputs";
import Selects from "../../components/Selects";

const FormsLeft = (props) => {
  const {
    formik,
    optionsProvince,
    optionsCity,
    optionsDistrict,
    optionsMitraGroup,
    startDate,
    setStartDate,
    details,
    listProvince,
    listCity,
    listDistrict,
    listMitraGroup,
    setParamsCity,
    setParamsDistrict,
  } = props;

  const detailsLebelProvinces = listProvince.find((element) => {
    return element.id === formik.values.province_id;
  });

  const detailsLebelCity = listCity.find((element) => {
    return element.id === formik.values.city_id;
  });

  const detailsLebelDistrict = listDistrict.find((element) => {
    return element.id === formik.values.district_id;
  });
  const detailsLebelMitraGroup = listMitraGroup.find((element) => {
    return element.id === formik.values.group_mitra;
  });

  return (
    <Col>
      <Row>
        <Col>
          <Form.Label>Tanggal Register</Form.Label>
          <Flatpickr
            value={startDate}
            name="registration_date"
            onChange={([date]) => {
              setStartDate(date);
              formik.setFieldValue("registration_date", date);
            }}
            className="datepicks-default"
          />
        </Col>
        <Col>
          <Inputs
            formik={formik}
            label="Kode Pelanggan*"
            type="text"
            id="code"
            name="code"
            values={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
      <Inputs
        formik={formik}
        label="Sales Person*"
        type="text"
        id="sales_person"
        name="sales_person"
        values={formik.values.sales_person}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Inputs
        formik={formik}
        label="No KTP*"
        type="number"
        id="ktp_no"
        name="ktp_no"
        values={formik.values.ktp_no}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Inputs
        formik={formik}
        label="Nama Mitra*"
        type="nama_mitra"
        id="name"
        name="name"
        values={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Selects
        formik={formik}
        label="Jenis Pelanggan"
        name="group_mitra"
        options={optionsMitraGroup}
        placeholder="Pilih Jenis Pelanggan"
        onChange={(e) => {
          formik.setFieldValue("group_mitra", e.value);
        }}
        value={{
          value: details?.mitra_group?.id,
          label: detailsLebelMitraGroup ? detailsLebelMitraGroup.name : "",
        }}
      />
      <Inputs
        formik={formik}
        label="Alamat Pengirim*"
        type="textarea"
        id="shipping_address"
        name="shipping_address"
        values={formik.values.shipping_address}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Row>
        <Col>
          <Selects
            formik={formik}
            label="Provinsi*"
            name="province_id"
            options={optionsProvince}
            placeholder="Pilih Provinsi"
            onChange={(e) => {
              setParamsCity(e.value);
              formik.setFieldValue("province_id", e.value);
            }}
            value={{
              value: details?.province_id,
              label: detailsLebelProvinces ? detailsLebelProvinces.name : "",
            }}
          />
        </Col>
        <Col>
          <Selects
            formik={formik}
            label="Kota*"
            name="city_id"
            options={optionsCity}
            placeholder="Pilih Kota"
            onChange={(e) => {
              setParamsDistrict(e.value);
              formik.setFieldValue("city_id", e.value);
            }}
            value={{
              value: details?.city_id,
              label: detailsLebelCity ? detailsLebelCity.name : "",
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Selects
            formik={formik}
            label="Kecamatan*"
            name="district_id"
            options={optionsDistrict}
            placeholder="Pilih Kecamatan"
            onChange={(e) => {
              formik.setFieldValue("district_id", e.value);
            }}
            value={{
              value: details?.district_id,
              label: detailsLebelDistrict ? detailsLebelDistrict.name : "",
            }}
          />
        </Col>
        <Col>
          <div>
            <Inputs
              formik={formik}
              label="Kelurahan*"
              type="text"
              id="village"
              name="village"
              values={formik.values.village}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Inputs
            formik={formik}
            label="Telepon*"
            type="number"
            id="phone"
            name="phone"
            values={formik.values.phone}
            isChangeCustome
            onChange={(e) => {
              formik.setFieldValue("phone", e.target.value);
            }}
          />
        </Col>
        <Col>
          <Inputs
            formik={formik}
            label="Fax"
            type="number"
            id="fax"
            name="fax"
            values={formik.values.fax}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default FormsLeft;
