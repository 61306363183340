import * as Yup from "yup";

const satuan = Yup.object({
  name: Yup.string().required("Silahkan isi nama"),
  desc: Yup.string().required("Silahkan pilih deskripsi"),
  galleries: Yup.string().required("Silahkan pilih gallery"),
  product_prices: Yup.array().of(
    Yup.object().shape({
      mitra_group_id: Yup.string().required("Silahkan pilih Mitra"),
      min_qty: Yup.number().required("Silahkan isi Min Qty"),
      max_qty: Yup.number().required("Silahkan isi Max Qty"),
      price: Yup.number().required("Silahkan isi price"),
    })
  ),
  product_limits: Yup.array().of(
    Yup.object().shape({
      mitra_group_id: Yup.string().required("Silahkan pilih Group Mitra"),
      min_qty: Yup.number().required("Silahkan isi Min Qty"),
      max_qty: Yup.number().required("Silahkan isi Max Qty"),
    })
  ),
  thumbnail: Yup.string().required("Silahkan pilih Image Thumbnail"),
  product_category_id: Yup.string().required("Silahkan pilih Kategory"),
  sku: Yup.string().required("Silahkan isi SKU"),
  price: Yup.string().required("Silahkan isi Harga"),
  // discount_type: Yup.string().required('Silahkan pilih Diskon'),
  // discount_value: Yup.string().required('Silahkan isi nominal'),
  weight: Yup.string().required("Silahkan isi Berat"),
  satuan_id: Yup.string().required("Silahkan Pilih Satuan"),
  length: Yup.string().required("Silahkan isi Panjang"),
  width: Yup.string().required("Silahkan isi Lebar"),
  height: Yup.string().required("Silahkan isi Tinggi"),
  // mitra_id: Yup.string().required('Silahkan pilih Produk ke'),
  publish_at: Yup.string().required("Silahkan pilih Tanggal"),
  persedian_type: Yup.string().required("Silahkan pilih Persediaan Produk"),
  bkp_type: Yup.string().required("Silahkan pilih Jenis BKP"),
  status: Yup.string().required("Silahkan pilih status"),
});

const paket = Yup.object({
  name: Yup.string().required("Silahkan isi nama"),
  desc: Yup.string().required("Silahkan pilih deskripsi"),
  galleries: Yup.string().required("Silahkan pilih gallery"),
  product_items: Yup.array().of(
    Yup.object().shape({
      product_id: Yup.string().required("Silahkan pilih Produk"),
      qty: Yup.number().required("Silahkan isi Qty"),
    })
  ),
  // product_prices: Yup.array().of(
  //     Yup.object().shape({
  //         mitra_group_id: Yup.string().required("Silahkan pilih Mitra"),
  //         min_qty: Yup.number().required("Silahkan isi Min Qty"),
  //         max_qty: Yup.number().required("Silahkan isi Max Qty"),
  //         price: Yup.number().required("Silahkan isi price"),
  //     })
  // ),
  // product_limits: Yup.array().of(
  //     Yup.object().shape({
  //         mitra_group_id: Yup.string().required("Silahkan pilih Group Mitra"),
  //         min_qty: Yup.number().required("Silahkan isi Min Qty"),
  //         max_qty: Yup.number().required("Silahkan isi Max Qty"),
  //     })
  // ),
  thumbnail: Yup.string().required("Silahkan pilih Image Thumbnail"),
  product_category_id: Yup.string().required("Silahkan pilih Kategory"),
  sku: Yup.string().required("Silahkan isi SKU"),
  price: Yup.string().required("Silahkan isi Harga"),
  // discount_type: Yup.string().required('Silahkan pilih Diskon'),
  // discount_value: Yup.string().required('Silahkan isi nominal'),
  weight: Yup.string().required("Silahkan isi Berat"),
  satuan_id: Yup.string().required("Silahkan Pilih Satuan"),
  // length: Yup.string().required('Silahkan isi Panjang'),
  // width: Yup.string().required('Silahkan isi Lebar'),
  // height: Yup.string().required('Silahkan isi Tinggi'),
  // mitra_id: Yup.string().required('Silahkan pilih Produk ke'),
  publish_at: Yup.string().required("Silahkan pilih Tanggal"),
  persedian_type: Yup.string().required("Silahkan pilih Persediaan Produk"),
  bkp_type: Yup.string().required("Silahkan pilih Jenis BKP"),
  status: Yup.string().required("Silahkan pilih status"),
});

export { satuan, paket };
