import React from "react";
import DEMO from "./../../../../../store/constant";
import Aux from "../../../../../hoc/_Aux";
import Icons from "../../../../../assets/images/icons-mitrafs.png";

const navLogo = (props) => {
  let toggleClass = ["mobile-menu"];
  if (props.collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  return (
    <Aux>
      <div className="navbar-brand header-logo">
        <img
          src={Icons}
          alt="icon"
          style={{
            width: "50px",
            hight: "50px",
          }}
        />
        {/* <a href={DEMO.BLANK_LINK} className="b-brand">
          <div className="b-bg">
            <i className="feather icon-trending-up" />
          </div>
        </a> */}
        <a
          href={DEMO.BLANK_LINK}
          className={toggleClass.join(" ")}
          id="mobile-collapse"
          onClick={props.onToggleNavigation}
        >
          <span />
        </a>
      </div>
    </Aux>
  );
};

export default navLogo;
