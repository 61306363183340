/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Left from "./left";
import Right from "./right";
import * as ROUTEPATH from "../../../../routePath";
import { useParams } from "react-router";
import fetchAxios from "../../../../helpers/fetchAxios";
import environments from "../../../../environments";

const DetailsProductPaket = () => {
  const { id } = useParams();
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [html, setHTML] = useState({ __html: "" });
  useEffect(() => {
    fetchDetailProduct();
  }, [id]);

  const fetchDetailProduct = async () => {
    const response = await fetchAxios(
      `${environments.api.product}/${id}`,
      "get"
    );

    if (response.status === 200) {
      setHTML({ __html: response.data?.data?.description });
      setIsLoading(false);
      setAllData(response.data?.data);
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Skeleton count={25} />
      ) : (
        <Fragment>
          <div className="mb-4">
            <h3>Lihat Produk Paket</h3>
          </div>
          <Row>
            <Left allData={allData} html={html} />
            <Right allData={allData} />
          </Row>
          <Col className="my-3">
            <div className="d-flex">
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    (window.location.href = `/cms${ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET}`)
                  }
                >
                  Kembali
                </Button>{" "}
              </div>
              <div>
                <Button
                  variant="success"
                  onClick={() => {
                    window.location.href = `/manajemen-produk/product-paket/${id}`;
                  }}
                >
                  Ubah
                </Button>{" "}
              </div>
              <div>
                <Button
                  variant="danger"
                  onClick={() =>
                    (window.location.href =
                      ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET)
                  }
                >
                  Hapus
                </Button>{" "}
              </div>
              <div>
                <Button variant="warning">Non Aktif</Button>{" "}
              </div>
            </div>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailsProductPaket;
