export const encodeQueryData = (data) => {
    let ret = [];
  
    for (let d in data) {
      let value = data[d];
  
      if (typeof value == "object") {
        value = data[d].join(",");
      }
  
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(value));
    }
  
    return ret.join("&");
};