/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import environments from '../../../../environments';
import fetchAxios from '../../../../helpers/fetchAxios';
import { ValidateContent } from '../../../../validate';
import Inputs from '../../../components/Inputs';
import SweetAlert from '../../../components/SweetAlert';
import ReactQuill from 'react-quill';
import * as ROUTEPATH from '../../../../routePath';
import { imageError } from '../../../../helpers/brokenImg';
import Avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import { ModalMedia } from '../../../components/Modal/ModalMedia';
import UploadMedia from '../../../components/Modal/UploadMedia';
import ComponentStatus from './componentStatus';

const AddBanner = () => {
    let initialPayload = {
        title: '',
        link: '',
        desc: '',
        status: '',
        type: '',
        photo: ''
    }
    let history = useHistory();
    const { id } = useParams();
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [tempImages, setTempImages] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalUpload, setShowModalUpload] = useState(false);

    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateContent.banner,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    useEffect(() => {
        if (id) {
            fetchDetailContent()
        }
    }, [id])

    const fetchDetailContent = async() => {
        const response = await fetchAxios(
            `${environments.api.management_content}/${id}`,
            "get"
        )
        if (response.status === 200) {
            setTempImages(response?.data?.data?.image)
            formik.setFieldValue('title',response?.data?.data?.title)
            formik.setFieldValue('link',response?.data?.data?.content?.link)
            formik.setFieldValue('desc',response?.data?.data?.content?.description)
            formik.setFieldValue('status',response?.data?.data?.status)
            formik.setFieldValue('type',response?.data?.data?.type)
            formik.setFieldValue('photo',response?.data?.data?.image)
        }
    }
    
    const onConfirm = async() => {
        const body = {
            type: "banner",
            title: payload.title,
            image: payload.photo,
            content: {
                link: payload.link,
                description: payload.desc
            },
            position: payload.type,
            status: payload.status
        }
        const response = await fetchAxios(
            `${environments.api.management_content}${id ? `/${id}` : "" }` , 
            id ? "put" : "post", 
            body);
        if (response.status === 200) {
            if (!id) {
                formik.resetForm()
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.MANAGEMENT_CONTENT_BANNER)
            } else {
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.MANAGEMENT_CONTENT_BANNER)
            }
        } else {
            setIsConfirm(false)
        }
    }

    const handleChooseImage = (value) => {
        setShowModal(false)
        formik.setFieldValue('photo', value.file)
        setTempImages(value.file)
    }

  return (
    <Fragment>
        <form onSubmit={formik.handleSubmit}>
            <Row className='mb-3'>
                <h4>{ id ? 'Update Banner' : "Tambah Banner" }</h4>
            </Row>
            <Row>
                <Col sm="2" className='justify-content-center align-items-center'>
                    <div className="d-flex justify-content-center">
                        <img 
                            onError={ e => imageError(e)}
                            className="img-radius avatar" 
                            src={ tempImages ? tempImages : Avatar1} 
                            alt="Generic placeholder"/>
                    </div>
                    {
                        formik.errors.photo && formik.touched.photo &&
                        (<p style={{ color: 'red', fontSize:'11px' }} className='text-center'>{ formik.errors.photo }</p>)
                    }
                    <div className="d-flex justify-content-center mt-2">
                        <Button variant="primary" onClick={() => setShowModal(true)}>Upload</Button>
                    </div>
                </Col>
                <Col>
                    <Inputs
                        formik={ formik }
                        label="Nama"
                        type='text'
                        id="title"
                        name="title"
                        values={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Inputs
                        formik={ formik }
                        label="Link"
                        type='text'
                        id="link"
                        name="link"
                        values={formik.values.link}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <div className='mb-3'>
                        <p>Deskripsi</p>
                        <ReactQuill 
                            name='desc'
                            value={formik.values.desc}
                            onChange={(value) => {
                                formik.setFieldValue('desc', value)
                            }}
                        />
                        {
                            formik.errors.desc && formik.touched.desc &&
                            (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.desc }</p>)
                        }
                    </div>
                    <ComponentStatus
                        formik={formik}
                    />
                    <Col>
                        <Row className='mt-5'>
                            <div>
                                <Button className='btn btn-outline-secondary' onClick={() => {window.location.href = `/cms${ROUTEPATH.MANAGEMENT_CONTENT_BANNER}`}}>Kembali</Button>{' '}
                            </div>
                            <div>
                                <Button className='btn btn-success' type='submit'>Simpan</Button>{' '}
                            </div>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </form>
        <ModalMedia
            show={showModal}
            handleClose={() => setShowModal(false)}
            handleChooseImage={(value) => handleChooseImage(value) }
            handleNestedModal={() => setShowModalUpload(true)}
        />
        <UploadMedia
            show={showModalUpload}
            handleClose={() => setShowModalUpload(false)}
            reRenderListModal={() => setShowModalUpload(false)}
        />
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={id ? "Konfirmasi Update Banner" : "Konfirmasi Tambah Banner"}
            message={`Apakah kamu yakin untuk ${id ? 'merubah' : 'menambahkan'} Banner Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal2"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
        
    </Fragment>
  )
}

export default AddBanner