import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { listJenisPerusahaan } from '../../../utilities/constants';
import Inputs from '../../components/Inputs';
import Selects from '../../components/Selects';

const FormsRight = (props) => {
    const { 
        formik
    } = props
    const detailsLebelCompanyType = listJenisPerusahaan.find((element) => {
        return element.value === formik.values.company_type
    })
  return (
    <Col>
        <Inputs
            formik={ formik }
            label="Nama Pemilik*"
            type='text'
            id="owner_name"
            name="owner_name"
            values={formik.values.owner_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Inputs
            formik={ formik }
            label="Alamat Pemilik*"
            type='text'
            id="owner_address"
            name="owner_address"
            values={formik.values.owner_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Inputs
            formik={ formik }
            label="Kontak Pembayaran*"
            type='text'
            id="billing_contact"
            name="billing_contact"
            values={formik.values.billing_contact}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Inputs
            formik={ formik }
            label="Email*"
            type='text'
            id="email"
            name="email"
            values={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Selects
            formik={formik}
            label="Jenis Perusahaan*"
            name="company_type"
            options={listJenisPerusahaan}
            placeholder="Pilih Perusahaan"
            onChange={(e) => { 
                formik.setFieldValue('company_type',e.value)
            }}
            value={{ 
                value: formik.values.company_type, 
                label: detailsLebelCompanyType ? detailsLebelCompanyType.value : ""
            }}
        />
        <Inputs
            formik={ formik }
            label="No NPWP*"
            type='number'
            id="npwp_no"
            name="npwp_no"
            values={formik.values.npwp_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Inputs
            formik={ formik }
            label="No STDUD"
            type='number'
            id="stdud_no"
            name="stdud_no"
            values={formik.values.stdud_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Inputs
            formik={ formik }
            label="No NIB"
            type='number'
            id="nib_no"
            name="nib_no"
            values={formik.values.nib_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Inputs
            formik={ formik }
            label="Kode Pos*"
            type='number'
            id="post_code"
            name="post_code"
            values={formik.values.post_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Inputs
            formik={ formik }
            label="Keterangan"
            type='textarea'
            id="note"
            name="note"
            values={formik.values.note}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <Form.Label className='pt-1'>Status*</Form.Label>
        <div className='d-flex'>
            <Form.Group>
                <div className='d-flex'>
                    <Form.Check
                        inline
                        custom
                        type="radio"
                        label="Aktif"
                        name="status"
                        id="active"
                        value='aktif'
                        onChange={(e) => {
                            formik.setFieldValue('status',e.target.value)
                        }}
                        checked={formik.values.status === "aktif" ? true : false }
                    />
                    <Form.Check
                        inline
                        custom
                        type="radio"
                        label="Tidak Aktif"
                        name="status"
                        id="notActive"
                        value="tidak aktif"
                        onChange={(e) => {
                            formik.setFieldValue('status',e.target.value)
                        }}
                        checked={formik.values.status === "tidak aktif" ? true : false }
                    />
                </div>
                {
                    formik.errors.status && formik.touched.status &&
                    (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.status }</p>)
                }
            </Form.Group>
        </div>
    </Col>
  )
}

export default FormsRight