/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import environments from '../../../../environments';
import fetchAxios from '../../../../helpers/fetchAxios';
import { ValidateContent } from '../../../../validate';
import Inputs from '../../../components/Inputs';
import SweetAlert from '../../../components/SweetAlert';
import ReactQuill from 'react-quill';
import * as ROUTEPATH from '../../../../routePath';

const AddPromo = () => {
    const { id } = useParams();
    const history = useHistory()
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState();
    let initialPayload = {
        title: '',
        desc: '',
        status: '',
    }

    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateContent.addPromo,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    useEffect(() => {
        if (id) {
            fetchDetailPromo()
        }
    }, [id])

    const fetchDetailPromo = async() => {
        const response = await fetchAxios(
            `${environments.api.management_content}/${id}`,
            "get"
        )
        if (response.status === 200) {
            formik.setFieldValue('title',response?.data?.data?.title)
            formik.setFieldValue('desc',response?.data?.data?.content?.description)
            formik.setFieldValue('status',response?.data?.data?.status)
        }
    }
    
    const onConfirm = async() => {
        const body = {
            title: payload.title,
            type: "promo",
            content: {
                description: payload.desc
            },
            status: payload.status
        }
        const response = await fetchAxios(
            `${environments.api.management_content}${id ? `/${id}` : "" }` , 
            id ? "put" : "post", 
            body);
        if (response.status === 200) {
            if (!id) {
                formik.resetForm()
                toast.success(response?.data?.message)
                setIsConfirm(false)
                setTimeout(() => {
                    history.goBack()
                }, 1000);
            } else {
                toast.success(response?.data?.message)
                setIsConfirm(false)
                setTimeout(() => {
                    history.goBack()
                }, 1000);
            }
        } else {
            setIsConfirm(false)
        }
    }

  return (
    <Fragment>
        <form onSubmit={formik.handleSubmit}>
            <Row className='mb-3'>
                <h4>{ id ? 'Update Promo' : "Tambah Promo" }</h4>
            </Row>
            <Col>
                <Inputs
                    formik={ formik }
                    label="Judul"
                    type='text'
                    id="title"
                    name="title"
                    values={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <div className='mb-3'>
                    <p>Deskripsi</p>
                    <ReactQuill 
                        name='desc'
                        value={formik.values.desc}
                        onChange={(value) => {
                            formik.setFieldValue('desc', value)
                        }}
                    />
                    {
                        formik.errors.desc && formik.touched.desc &&
                        (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.desc }</p>)
                    }
                </div>
                <p>Status</p>
                <Form.Group>
                    <div className='d-flex'>
                        <Form.Check
                            inline
                            custom
                            type="radio"
                            label="Aktif"
                            name="status"
                            id="active"
                            value='aktif'
                            onChange={(e) => {
                                formik.setFieldValue('status',e.target.value)
                            }}
                            checked={formik.values.status === "aktif" ? true : false }
                        />
                        <Form.Check
                            inline
                            custom
                            type="radio"
                            label="Tidak Aktif"
                            name="status"
                            value="tidak aktif"
                            id="notActive"
                            onChange={(e) => {
                                formik.setFieldValue('status',e.target.value)
                            }}
                            checked={formik.values.status === "tidak aktif" ? true : false }
                        />
                    </div>
                </Form.Group>
                {
                    formik.errors.status && formik.touched.status &&
                    (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.status }</p>)
                }
                <Col>
                    <Row className='mt-3'>
                        <div>
                            <Button className='btn btn-outline-secondary' onClick={() => {window.location.href = `/cms${ROUTEPATH.MANAGEMENT_CONTENT_PROMO}`}}>Kembali</Button>{' '}
                        </div>
                        <div>
                            <Button className='btn btn-success' type='submit'>Simpan</Button>{' '}
                        </div>
                    </Row>
                </Col>
            </Col>
        </form>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={id ? "Konfirmasi Update Promo" : "Konfirmasi Tambah Promo"}
            message={`Apakah kamu yakin untuk ${id ? 'merubah' : 'menambahkan'} Promo Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal2"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
        
    </Fragment>
  )
}

export default AddPromo