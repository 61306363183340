/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./index.scss";
import { imageError } from "../../../helpers/brokenImg";
import fetchAxios from "../../../helpers/fetchAxios";
import environments from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import "../../Screens/Media/index.scss";

export const ModalMedia = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [listMedia, setListMedia] = useState([]);
  const [values, setValues] = useState();
  const [checkedImage, setCheckedImage] = useState();
  const [hitParams, setHitParams] = useState({
    orderby: "id",
    sort: "desc",
    page: 1,
    per_page: 10,
    status: "",
    key: "",
  });
  const { show, handleClose, handleChooseImage, handleNestedModal } = props;

  useEffect(() => {
    setCheckedImage();
    fetchMedia();
  }, [show, hitParams]);

  const fetchMedia = async () => {
    const response = await fetchAxios(
      environments.api.media +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );

    if (response.status === 200) {
      setIsLoading(false);
      setListMedia(response?.data?.data);
    }
  };

  const handlePageClick = (event) => {
    setHitParams({
      ...hitParams,
      page: event.selected + 1,
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <div className="d-flex justify-content-end">
        <div className="mx-4 my-2 close" onClick={handleClose}>
          X
        </div>
      </div>
      <div className="text-center">
        <h3>Pilih Media</h3>
      </div>
      <div className="cont-main my-3 px-2">
        {isLoading ? (
          <div style={{ width: "100%" }}>
            <Skeleton count={5} />
          </div>
        ) : (
          <Fragment>
            {listMedia.data?.map((value) => {
              return (
                <div className="cont-checkbox">
                  <input
                    onError={(e) => imageError(e)}
                    type="checkbox"
                    id={`myCheckbox-${value.id}`}
                    value={value.title}
                    onChange={() => {
                      setCheckedImage(value.id);
                      setValues(value);
                    }}
                    checked={checkedImage === value.id}
                  />
                  <label for={`myCheckbox-${value.id}`}>
                    <img src={value.file} onError={(e) => imageError(e)} />
                    <span className="cover-checkbox">
                      <svg viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </svg>
                    </span>
                    <div className="info">{value.title}</div>
                  </label>
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
      <div className="d-flex mr-2 justify-content-end">
        <ReactPaginate
          previousLabel={"kembali"}
          nextLabel={"lanjut"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={listMedia.total / listMedia.per_page}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleNestedModal}>
          Upload From Computer
        </Button>
        <Button
          variant="primary"
          onClick={() => (checkedImage ? handleChooseImage(values) : null)}
        >
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
