/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import SweetAlert from "../../../components/SweetAlert";
import fetchAxios from "../../../../helpers/fetchAxios";
import environments from "../../../../environments";
import { toast } from "react-toastify";
import FormLeft from "./formLeft";
import FormRight from "./formRight";
import { ValidateManagementAdmin } from "../../../../validate";
import * as ROUTEPATH from "../../../../routePath";

export const AddRole = () => {
  const { id } = useParams();
  const [isConfirm, setIsConfirm] = useState(false);
  const [allMenu, setAllMenu] = useState([]);
  const [menus, setMenus] = useState([]);
  const [detailMenu, setDetailMenu] = useState([]);
  const [payload, setPayload] = useState();
  const [modeEdit, setModeEdit] = useState(false);
  var tmpArr = [];
  const checkedValue = useRef();
  let initialPayload = {
    name: "",
    status: "",
    menus: [],
  };

  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateManagementAdmin.role,
    onSubmit: (values) => {
      allMenu.map((value) => {
        if (value?.checked) {
          tmpArr.push(value.id);
          if (value?.children.length > 0) {
            value.children.map((child) => {
              if (child.checked) {
                tmpArr.push(child.id);
              }
            });
          }
        }
      });
      setMenus(tmpArr);
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  useEffect(() => {
    fetchAllMenu();
  }, []);

  const fetchAllMenu = async () => {
    const response = await fetchAxios(
      `${environments.api.all_menu}${id ? `/${id}` : ""}`,
      "get"
    );
    if (response.status === 200) {
      setAllMenu(response?.data?.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDetailRole();
      setModeEdit(true);
    }
  }, [id]);

  const fetchDetailRole = async () => {
    const response = await fetchAxios(
      `${environments.api.management_admin_role}/${id}`,
      "get"
    );

    if (response.status === 200) {
      formik.setFieldValue("name", response?.data?.data?.name);
      formik.setFieldValue("status", response?.data?.data?.status);
      setDetailMenu(response?.data?.data?.menus);
      // formik.setFieldValue('menus',response?.data?.data?.photo)
    }
  };

  const onConfirm = async () => {
    const body = {
      name: payload.name,
      status: payload.status,
      menus: menus,
    };
    const response = await fetchAxios(
      `${environments.api.management_admin_role}${id ? `/${id}` : ""}`,
      id ? "put" : "post",
      body
    );
    if (response.status === 200) {
      setModeEdit(false);
      formik.resetForm();
      toast.success(response.data.message);
      setIsConfirm(false);
      window.location.href = ROUTEPATH.MANAGEMENT_ROLE;
    } else {
      setModeEdit(false);
      setIsConfirm(false);
    }
  };

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mb-3">
          <h4>{`${id ? "Ubah" : "Tambah"} Role`}</h4>
        </Row>
        <Row>
          <FormLeft formik={formik} modeEdit={modeEdit} />
          <FormRight
            allMenu={allMenu}
            setAllMenu={setAllMenu}
            checkedValue={checkedValue}
            detailMenu={detailMenu}
            id={id}
            modeEdit={modeEdit}
          />
        </Row>
        <Col>
          <Row className="mt-2">
            <div>
              <Button
                className="btn btn-outline-secondary"
                onClick={() => {
                  window.location.href = `/cms${ROUTEPATH.MANAGEMENT_ROLE}`;
                }}
              >
                Kembali
              </Button>{" "}
            </div>
            <div>
              <Button className="btn btn-success" type="submit">
                Simpan
              </Button>{" "}
            </div>
          </Row>
        </Col>
      </form>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi ${id ? "Ubah" : "Tambah"} Role`}
        message={`Apakah kamu yakin untuk ${id ? "Ubah" : "Tambah"} Role Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Fragment>
  );
};
