import * as Yup from 'yup';

const addSatuan = Yup.object({
    name: Yup.string().required('Silahkan isi nama'),
    status: Yup.string().required('Silahkan isi status'),
});

const addProduct = Yup.object({
    name: Yup.string().required('Silahkan isi nama'),
    status: Yup.string().required('Silahkan pilih status'),
    photo: Yup.string().required('Silahkan upload image'),
});

const groupMitra = Yup.object({
    name: Yup.string().required('Silahkan isi nama'),
    status: Yup.string().required('Silahkan pilih status'),
});

const shipping = Yup.object({
    name: Yup.string().required('Silahkan isi nama'),
    status: Yup.string().required('Silahkan pilih status'),
    // photo: Yup.string().required('Silahkan upload image'),
});

const uploadMedia = Yup.object({
    name: Yup.string().required('Silahkan isi nama'),
    // desc: Yup.string().required('Silahkan isi deskripsi'),
    photo: Yup.string().required('Silahkan upload image'),
});

export { addSatuan, addProduct, shipping, groupMitra, uploadMedia };