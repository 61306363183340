/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import environments from "../../../../environments";
import fetchAxios from "../../../../helpers/fetchAxios";
import { ValidateContent } from "../../../../validate";
import Inputs from "../../../components/Inputs";
import SweetAlert from "../../../components/SweetAlert";
import * as ROUTEPATH from "../../../../routePath";

const AddFAQ = () => {
  let history = useHistory();
  const { id } = useParams();
  const [isConfirm, setIsConfirm] = useState(false);
  const [payload, setPayload] = useState();
  let initialPayload = {
    question: "",
    answare: "",
    status: "",
  };

  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateContent.addFAQ,
    onSubmit: async (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  useEffect(() => {
    if (id) {
      fetchDetailFAQ();
    }
  }, [id]);

  const fetchDetailFAQ = async () => {
    const response = await fetchAxios(
      `${environments.api.management_content}/${id}`,
      "get",
    );
    if (response.status === 200) {
      formik.setFieldValue("question", response?.data?.data?.title);
      formik.setFieldValue("answare", response?.data?.data?.content?.answer);
      formik.setFieldValue("status", response?.data?.data?.status);
    }
  };

  const onConfirm = async () => {
    const body = {
      title: payload.question,
      type: "faq",
      content: {
        answer: payload.answare,
      },
      status: payload.status,
    };
    const response = await fetchAxios(
      `${environments.api.management_content}${id ? `/${id}` : ""}`,
      id ? "put" : "post",
      body,
    );
    if (response.status === 200) {
      if (!id) {
        formik.resetForm();
        toast.success(response.data.message);
        setIsConfirm(false);
        history.push(ROUTEPATH.MANAGEMENT_CONTENT_FAQ);
      } else {
        toast.success(response.data.message);
        setIsConfirm(false);
        history.push(ROUTEPATH.MANAGEMENT_CONTENT_FAQ);
      }
    } else {
      setIsConfirm(false);
    }
  };
  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mb-3">
          <h4>{id ? "Update FAQ" : "Tambah FAQ"}</h4>
        </Row>
        <Col>
          <Inputs
            formik={formik}
            label="Pertanyaan"
            type="text"
            id="question"
            name="question"
            values={formik.values.question}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Inputs
            formik={formik}
            label="Jawaban"
            type="textarea"
            id="answare"
            name="answare"
            values={formik.values.answare}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <p>Status</p>
          <Form.Group>
            <div className="d-flex">
              <Form.Check
                inline
                custom
                type="radio"
                label="Aktif"
                name="status"
                id="active"
                value="aktif"
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.value);
                }}
                checked={formik.values.status === "aktif" ? true : false}
              />
              <Form.Check
                inline
                custom
                type="radio"
                label="Tidak Aktif"
                name="status"
                value="tidak aktif"
                id="notActive"
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.value);
                }}
                checked={formik.values.status === "tidak aktif" ? true : false}
              />
            </div>
          </Form.Group>
          {formik.errors.status && formik.touched.status && (
            <p style={{ color: "red", fontSize: "11px" }}>
              {formik.errors.status}
            </p>
          )}
          <Col>
            <Row className="mt-4">
              <div>
                <Button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    window.location.href = `/cms${ROUTEPATH.MANAGEMENT_CONTENT_FAQ}`;
                  }}
                >
                  Kembali
                </Button>{" "}
              </div>
              <div>
                <Button className="btn btn-success" type="submit">
                  Simpan
                </Button>{" "}
              </div>
            </Row>
          </Col>
        </Col>
      </form>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={id ? "Konfirmasi Update FAQ" : "Konfirmasi Tambah FAQ"}
        message={`Apakah kamu yakin untuk ${
          id ? "merubah" : "menambahkan"
        } FAQ Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal2"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Fragment>
  );
};

export default AddFAQ;
