/* eslint-disable array-callback-return */
import React from "react";
import { Col, Form } from "react-bootstrap";

const FormRight = (props) => {
  const { allMenu, setAllMenu, checkedValue } = props;

  return (
    <Col>
      <p>Pilih Menu</p>
      {allMenu?.map((val) => {
        return (
          <Form.Group>
            <Form.Check
              checked={val.checked}
              custom
              type="checkbox"
              id={val.title}
              name={val.title}
              label={val.title}
              value={val.id}
              ref={checkedValue}
              onChange={(e) => {
                const isChecked = val["checked"] ? true : false;
                const newAllMenu = allMenu;
                const a = val.children;
                // // looping untuk childs menu
                for (const child of a) {
                  child["checked"] = !isChecked;
                }
                val["checked"] = !isChecked;
                setAllMenu([...newAllMenu]);
              }}
            />
            {val?.children?.map((child, index) => {
              return (
                <div className="ml-3" key={index}>
                  <Form.Check
                    checked={child.checked}
                    custom
                    type="checkbox"
                    label={child.title}
                    value={index}
                    onChange={(e) => {
                      const newAllMenu = allMenu;
                      const isChecked = child["checked"] ? true : false;
                      child["checked"] = !isChecked;
                      setAllMenu([...newAllMenu]);
                    }}
                  />
                </div>
              );
            })}
          </Form.Group>
        );
      })}
    </Col>
  );
};

export default FormRight;
