import React from 'react';
// import Forgot from './App/Screens/Authentication/Forgot';
import * as ROUTEPATH from './routePath';

const SignUp = React.lazy(() => import('./App/Screens/Authentication/SignUp/SignUp'));
const Signin = React.lazy(() => import('./App/Screens/Authentication/SignIn/SignIn'));
const Forgot = React.lazy(() => import('./App/Screens/Authentication/Forgot/index'));
const ResetPassword = React.lazy(() => import('./App/Screens/Authentication/ResetPassword/index'));

const route = [
    { path: ROUTEPATH.SIGNUP, exact: true, name: 'Signup', component: SignUp },
    { path: ROUTEPATH.FOTGOTE, exact: true, name: 'Forgote', component: Forgot },
    { path: ROUTEPATH.RESETPASSWORD, exact: true, name: 'Reset Password', component: ResetPassword },
    { path: ROUTEPATH.SIGNIN, exact: true, name: 'Signin', component: Signin }
];

export default route;