import React, { useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { imageError } from '../../../helpers/brokenImg'
import Avatar1 from '../../../assets/images/user/avatar-1.jpg';
import { useFormik } from 'formik';
import Inputs from '../Inputs';
import { ValidateMasterData } from '../../../validate';
import fetchAxios from '../../../helpers/fetchAxios';
import environments from '../../../environments';
import { toast } from 'react-toastify';

const UploadMedia = (props) => {
  const [tempImages, setTempImages] = useState('');
  const [loading, setLoading] = useState(false);
  const avatarRef = useRef();
    const {
        show,
        handleClose,
    } = props 
    let initialPayload = {
        name: '',
        desc: '',
        photo: '',
    }
    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateMasterData.uploadMedia,
        onSubmit: async(values) => {
          setLoading(true)
          const formData = new FormData();
          formData.append('title', values.name);
          formData.append('file', values.photo);
          formData.append('description', values.desc);
          const response = await fetchAxios(
              `${environments.api.media}` , 
              "post",
              formData);
          if (response.status === 200) {
            // reRenderListModal()
            setLoading(false)
            setTempImages('');
            toast.success(response.data.message)
            handleClose()
          } else {
              setLoading(false)
              setTempImages('');
              handleClose()
          }
        },
    });

  const changeAvatar = () => {
      let avatar = URL.createObjectURL(avatarRef?.current?.files[0]);
      if (avatar !== undefined && avatar !== tempImages) {
        setTempImages(avatar);
      }
  };

  return (
    <Modal 
        show={show} 
        onHide={handleClose}
        size="xl"
    >
        <form onSubmit={formik.handleSubmit}>
          <div className='d-flex justify-content-end'>
              <div 
                className='mx-4 my-2 close'
                onClick={handleClose}
              >X</div>
          </div>
          <div className='mx-4 my-2 title'>
            Upload Media
          </div>
          <Modal.Body>
            <Row>
              <Col sm="2">
                <div className='d-flex justify-content-center mt-2'>
                    <img 
                        onError={ e => imageError(e)}
                        className="img-fluid"
                        width={200}
                        height={200}
                        src={ tempImages ? tempImages : Avatar1} 
                        alt="Generic placeholder"
                    />
                </div>
                {
                    formik.errors.photo && formik.touched.photo &&
                    (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.photo }</p>)
                }
                <div className="d-flex justify-content-center mt-2">
                    <label class="custom-file-upload">
                        <input
                            accept='image/*'
                            name='photo'
                            type="file"
                            onChange={(e)=>{
                                changeAvatar()
                                formik.setFieldValue('photo',e.target.files[0])
                            }}
                            ref={ avatarRef }
                        />
                        Upload
                    </label>
                </div>
              </Col>
              <Col>
                <Inputs
                    formik={ formik }
                    label="Nama"
                    type='text'
                    id="name"
                    name="name"
                    values={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <Inputs
                    formik={ formik }
                    label="Deskripsi"
                    type='textarea'
                    id="desc"
                    name="desc"
                    values={formik.values.desc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Kembali
            </Button>
            <Button variant="primary" type='submit' disabled={loading}>
              {loading ? 'Loading…' : 'Simpan'}
            </Button>
          </Modal.Footer>
        </form>
    </Modal>
  )
}

export default UploadMedia