import React from "react";
import { Button } from "react-bootstrap";

const ColumGroupMitra = (props) => {
  return [
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Aksi",
      sortable: true,
      width: "300px",
      cell: (row) => {
        return (
          <div className="d-flex">
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-outline-success"
                  size="sm"
                  onClick={() => {
                    window.location.href = `/master-data/group-mitra/${row.id}`;
                  }}
                >
                  Lihat
                </Button>
              </span>
            </div>
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-danger"
                  size="sm"
                  onClick={() => props.showAlertDeleted(row.id)}
                >
                  Hapus
                </Button>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export default ColumGroupMitra;
