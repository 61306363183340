/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";
import { ValidateTransactions } from "../../../validate";
import Inputs from "../../components/Inputs";
import Selects from "../../components/Selects";
import SweetAlert from "../../components/SweetAlert";
import DatePickers from "../../components/DatePickers";
import dayjs from "dayjs";

function SendOrder(props) {
  let initialPayload = {
    transaction_id: "",
    shipping_method_id: "",
    shipping_cost: "",
    no_surat: "",
    expedition: "",
    driver_name: "",
    driver_phone: "",
    sales_order: "",
    shipping_date: "",
    sales_order_number: "",
  };
  const [isConfirm, setIsConfirm] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [payload, setPayload] = useState(initialPayload);
  const {
    show,
    handleClose,
    id,
    optionsShippingMethod,
    listShippingMethod,
    data,
    isSendOrderUpdate,
  } = props;

  useEffect(() => {
    if (show) {
      formik.setFieldValue("expedition", data?.shipping_method?.name);
    }
  }, [show]);

  useEffect(() => {
    if (data) {
      formik.setFieldValue("shipping_method_id", data?.shipping_method_id);
      formik.setFieldValue("shipping_cost", data?.shipping_cost);
      formik.setFieldValue("no_surat", data?.no_surat);
      formik.setFieldValue("expedition", data?.expedition);
      formik.setFieldValue("driver_name", data?.driver_name);
      formik.setFieldValue("driver_phone", data?.driver_phone);
      formik.setFieldValue("shipping_date", data?.shipping_date);
      formik.setFieldValue("sales_order_number", data?.sales_order_number);
      setStartDate(new Date(data?.shipping_date));
    }
  }, [show, isSendOrderUpdate]);

  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateTransactions.sendOrder,
    onSubmit: async (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  const onConfirm = async () => {
    const body = {
      transaction_id: id,
      status: "sedang dikirim",
      shipping_method_id: payload.shipping_method_id,
      shipping_cost: payload.shipping_cost,
      no_surat: payload.no_surat,
      expedition: payload.expedition,
      driver_name: payload.driver_name,
      driver_phone: payload.driver_phone,
      shipping_date: dayjs(payload.shipping_date).format("YYYY-MM-DD"),
      sales_order_number: payload.sales_order_number,
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-status`,
      "put",
      body,
    );
    if (response.status === 200) {
      formik.resetForm();
      toast.success(response.data.message);
      setIsConfirm(false);
      handleClose();
    } else {
      setIsConfirm(false);
      handleClose();
    }
  };

  const detailsLebelShipping = listShippingMethod.find((element) => {
    return element.id === formik.values.shipping_method_id;
  });

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Kirim Barang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Selects
            formik={formik}
            label="Metode Pengiriman"
            name="shipping_method_id"
            options={optionsShippingMethod}
            placeholder="Pilih "
            onChange={(e) => {
              formik.setFieldValue("shipping_method_id", e.value);
            }}
            value={{
              value: data?.shipping_method_id,
              label: detailsLebelShipping ? detailsLebelShipping.name : "",
            }}
            isDisabled
          />

          <DatePickers
            formik={formik}
            label="Tanggal Pengiriman"
            name="shipping_date"
            dateFormat="d MMMM yyyy"
            selected={startDate}
            onChange={(e) => {
              setStartDate(e);
              formik.setFieldValue("shipping_date", e);
            }}
            readOnly
          />

          <Inputs
            formik={formik}
            label="Ongkos Kirim"
            type="number"
            id="shipping_cost"
            name="shipping_cost"
            values={formik.values.shipping_cost}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
          <Inputs
            formik={formik}
            label="Nomer Sales Order (SO)"
            type="text"
            id="sales_order_number"
            name="sales_order_number"
            values={formik.values.sales_order_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
          <Inputs
            formik={formik}
            label="Nomor Surat Jalan"
            type="text"
            id="no_surat"
            name="no_surat"
            values={formik.values.no_surat}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Inputs
            formik={formik}
            label="Ekspedisi"
            type="text"
            id="expedition"
            name="expedition"
            values={formik.values.expedition}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Inputs
            formik={formik}
            label="Nama Driver"
            type="text"
            id="driver_name"
            name="driver_name"
            values={formik.values.driver_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Inputs
            formik={formik}
            label="Nomer Telepon"
            type="number"
            id="driver_phone"
            name="driver_phone"
            values={formik.values.driver_phone}
            isChangeCustome
            onChange={(e) => {
              formik.setFieldValue("driver_phone", e.target.value);
            }}
            // onBlur={formik.handleBlur}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kembali
          </Button>
          <Button variant="success" type="submit">
            Simpan
          </Button>
        </Modal.Footer>
      </form>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi Merubah Kirim Barang`}
        message={`Apakah kamu yakin untuk merubah Kirim Barang Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal22"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Modal>
  );
}

export default SendOrder;
