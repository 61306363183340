import React from 'react'
import { Col, Form } from 'react-bootstrap'
import Inputs from '../../../components/Inputs'

function RightForm(props) {
    const {
        formik
    } = props
  return (
    <Col sm="5">
        <Inputs
            formik={ formik }
            label="Password"
            type='password'
            id="password"
            name="password"
            values={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        <div className='mt-2' />
        <p>Status</p>
        <Form.Group>
            <div className='d-flex'>
                <Form.Check
                    inline
                    custom
                    type="radio"
                    label="Aktif"
                    name="status"
                    id="active"
                    value='aktif'
                    onChange={(e) => {
                        formik.setFieldValue('status',e.target.value)
                    }}
                    checked={formik.values.status === "aktif" ? true : false }
                />
                <Form.Check
                    inline
                    custom
                    type="radio"
                    label="Tidak Aktif"
                    name="status"
                    value="tidak aktif"
                    id="notActive"
                    onChange={(e) => {
                        formik.setFieldValue('status',e.target.value)
                    }}
                    checked={formik.values.status === "tidak aktif" ? true : false }
                />
            </div>
        </Form.Group>
        {
            formik.errors.status && formik.touched.status &&
            (<p style={{ color: 'red', fontSize:'11px', paddingTop: '2px' }}>{ formik.errors.status }</p>)
        }
    </Col>
  )
}

export default RightForm