export const PATH_DEFAULT = "/api/admin/v1";

export const listPelanggan = [
  { label: "Super Market", value: "super_market" },
  { label: "Trader", value: "trader" },
  { label: "Instansi", value: "intansi" },
  { label: "Grosir", value: "grosir" },
];

export const listJenisPerusahaan = [
  { label: "Badan Usaha", value: "Badan Usaha" },
  { label: "Perorangan", value: "Perorangan" },
];

export const listDiscountType = [
  { label: "Persentase", value: "persentase" },
  { label: "Harga", value: "harga" },
];

export const listStatus = [
  { label: "Aktif", value: "aktif" },
  { label: "Tidak Aktif", value: "tidak aktif" },
];

export const listAllStatus = [
  // { label: "Aktif", value: "aktif" },
  // { label: "Tidak Aktif", value: "tidak aktif" },
  { label: "Menunggu Konfirmasi", value: "menunggu konfirmasi" },
  { label: "Pesanan Telah Dikonfirmasi", value: "pesanan telah dikonfirmasi" },
  { label: "Sedang Diproses", value: "sedang diproses" },
  { label: "Batalkan", value: "batalkan" },
  { label: "Sedang Dikirim", value: "sedang dikirim" },
  { label: "Sudah Diterima", value: "sudah diterima" },
  { label: "Selesai", value: "selesai" },
  { label: "Menunggu Persetujuan", value: "menunggu persetujuan" },
];
