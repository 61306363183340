/* eslint-disable no-use-before-define */
import React, { useState} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import windowSize from 'react-window-size';

import Aux from "../../../../../hoc/_Aux";
import NavGroup from './NavGroup';
import DEMO from "../../../../../store/constant";
import * as actionTypes from "../../../../../store/actions";
import Skeleton from 'react-loading-skeleton';

export const NavContent = (props) => {
    let mainContent = '';
    const [scrollWidth, setScrollWidth] = useState(0)
    const [prevDisable, setPrevDisable] = useState(true)
    const [nextDisable, setNextDisable] = useState(true)

    let itemMenu = [
        {
            id: 'navigation',
            title: 'Page',
            type: 'group',
            icon: 'icon-navigation',
            children: props.allMenu ? props.allMenu : []
        }
    ]

    const scrollPrevHandler = () => {
        const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;

        let scrollWidth = scrollWidth - wrapperWidth;
        if(scrollWidth < 0) {
            setScrollWidth(0)
            setPrevDisable(true)
            setNextDisable(false)
        } else {
            setPrevDisable(false)
        }
    };

    const scrollNextHandler = () => {
        const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;
        const contentWidth = document.getElementById('sidenav-horizontal').clientWidth;

        let scrollWidth = scrollWidth + (wrapperWidth - 80);
        if (scrollWidth > (contentWidth - wrapperWidth)) {
            scrollWidth = contentWidth - wrapperWidth + 80;
            setScrollWidth(scrollWidth)
            setPrevDisable(false)
            setNextDisable(true)
        } else {
            setScrollWidth(scrollWidth)
            setPrevDisable(false)
        }
    };

    const navItems = itemMenu.map(item => {
        return <NavGroup layout={props.layout} key={item.id} group={item} items={props.allMenu}/>;
    });

    // const navItems = props.navigation.map(item => {
    //     return <NavGroup layout={props.layout} key={item.id} group={item}/>;
    // });
    
    if (props.layout === 'horizontal') {
        let prevClass = ['sidenav-horizontal-prev'];
        if (prevDisable) {
            prevClass = [...prevClass, 'disabled'];
        }
        let nextClass = ['sidenav-horizontal-next'];
        if (nextDisable) {
            nextClass = [...nextClass, 'disabled'];
        }
        mainContent = (
            <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
                <a href={DEMO.BLANK_LINK} className={prevClass.join(' ')} onClick={scrollPrevHandler}><span/></a>
                <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
                    <ul id="sidenav-horizontal" className="nav pcoded-inner-navbar sidenav-inner" onMouseLeave={props.onNavContentLeave} style={{marginLeft: '-'+scrollWidth+'px'}}>
                        {navItems}
                    </ul>
                </div>
                <a href={DEMO.BLANK_LINK} className={nextClass.join(' ')} onClick={scrollNextHandler}><span/></a>
            </div>
        );
    } else {
        mainContent = (
            <div className="navbar-content datta-scroll">
                <PerfectScrollbar>
                    <ul className="nav pcoded-inner-navbar">
                        {navItems}
                    </ul>
                </PerfectScrollbar>
            </div>
        );
    }

  return (
    <Aux>
        { 
            !props.isLoadingMenu ? (
                <div className='mx-2'>
                    <Skeleton style={{ borderRadius: 10 , backgroundColor: '#cbd5e1'}} count={30} />
                </div>
            ) : mainContent
        }
    </Aux>
  )
}

const mapStateToProps = state => {
    return {
        layout: state.layout,
        collapseMenu: state.collapseMenu,
        allMenu: state.allMenu,
        isLoadingMenu: state.isLoadingMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onNavContentLeave: () => dispatch({type: actionTypes.NAV_CONTENT_LEAVE}),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps) (windowSize(NavContent)));