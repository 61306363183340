/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import environments from '../../../../environments';
import fetchAxios from '../../../../helpers/fetchAxios';
import { ValidateMasterData } from '../../../../validate';
import Inputs from '../../../components/Inputs';
import SweetAlert from '../../../components/SweetAlert';
import * as ROUTEPATH from '../../../../routePath';

const AddSatuan = () => {
    let history = useHistory();
    const { id } = useParams();
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState();
    let initialPayload = {
        name: '',
        status: '',
    }

    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateMasterData.addSatuan,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    useEffect(() => {
        if (id) {
            fetchDetailSatuan()
        }
    }, [id])

    const fetchDetailSatuan = async() => {
        const response = await fetchAxios(
            `${environments.api.master_data_satuan}/${id}`,
            "get"
        )
        if (response.status === 200) {
            formik.setFieldValue('name',response?.data?.data?.name)
            formik.setFieldValue('status',response?.data?.data?.status)
        }
    }
    
    const onConfirm = async() => {
        const body = {
            name: payload.name,
            status: payload.status
        }
        const response = await fetchAxios(
            `${environments.api.master_data_satuan}${id ? `/${id}` : "" }` , 
            id ? "put" : "post", 
            body);
        if (response.status === 200) {
            if (!id) {
                formik.resetForm()
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.MASTER_DATA_SATUAN)
            } else {
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.MASTER_DATA_SATUAN)
            }
        } else {
            setIsConfirm(false)
        }
    }
  return (
    <Fragment>
        <form onSubmit={formik.handleSubmit}>
            <Row className='mb-3'>
                <h4>{ id ? 'Update Satuan' : "Tambah Satuan" }</h4>
            </Row>
            <Col>
                <Inputs
                    formik={ formik }
                    label="Nama"
                    type='text'
                    id="name"
                    name="name"
                    values={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <div className='mt-2' />
                <p>Status</p>
                <Form.Group>
                    <div className='d-flex'>
                        <Form.Check
                            inline
                            custom
                            type="radio"
                            label="Aktif"
                            name="status"
                            id="active"
                            value='aktif'
                            onChange={(e) => {
                                formik.setFieldValue('status',e.target.value)
                            }}
                            checked={formik.values.status === "aktif" ? true : false }
                        />
                        <Form.Check
                            inline
                            custom
                            type="radio"
                            label="Tidak Aktif"
                            name="status"
                            value="tidak aktif"
                            id="notActive"
                            onChange={(e) => {
                                formik.setFieldValue('status',e.target.value)
                            }}
                            checked={formik.values.status === "tidak aktif" ? true : false }
                        />
                    </div>
                </Form.Group>
                {
                    formik.errors.status && formik.touched.status &&
                    (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.status }</p>)
                }
                <Col>
                    <Row className='mt-4'>
                        <div>
                            <Button className='btn btn-outline-secondary' onClick={() => {window.location.href = `/cms${ROUTEPATH.MASTER_DATA_SATUAN}`}}>Kembali</Button>{' '}
                        </div>
                        <div>
                            <Button className='btn btn-success' type='submit'>Simpan</Button>{' '}
                        </div>
                    </Row>
                </Col>
            </Col>
        </form>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={id ? "Konfirmasi Update Satuan" : "Konfirmasi Tambah Satuan"}
            message={`Apakah kamu yakin untuk ${id ? 'merubah' : 'menambahkan'} Satuan Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
        
    </Fragment>
  )
}

export default AddSatuan