import React, { Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Inputs from "../../../components/Inputs";
import Selects from "../../../components/Selects";

const ConfigProductItems = (props) => {
  const { formik, details, optionsProduct } = props;

  const addRow = () => {
    let product_items = formik.values.product_items;
    product_items.push({
      product_id: "",
      qty: "",
    });
    formik.setFieldValue("product_items", product_items);
  };

  const removeRow = (index) => {
    let product_items = formik.values.product_items;
    if (product_items.length < 1) {
      return;
    }
    product_items.splice(index, 1);
    formik.setFieldValue("product_items", product_items);
  };

  return (
    <Fragment>
      <div className="mt-2">
        {formik.values.product_items.map((value, index) => {
          return (
            <Row className="align-items-center mt-2">
              <Col sm={8}>
                <Selects
                  formik={formik}
                  label="Pilih Produk"
                  name={`product_items.${index}.product_id`}
                  options={optionsProduct}
                  placeholder={
                    details
                      ? details?.items[index]?.product?.name
                      : "Pilih Produk"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `product_items.${index}.product_id`,
                      e.value
                    );
                  }}
                  isCostumeValidate
                  errors={formik.errors.product_items?.[index]?.product_id}
                  toucheds={formik.touched.product_items?.[index]?.product_id}
                />
              </Col>
              <Col sm={3}>
                <Inputs
                  formik={formik}
                  label="Min Qty"
                  type="number"
                  id="qty"
                  name={`product_items.${index}.qty`}
                  values={formik.values.product_items?.[index]?.qty}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isCostumeValidate
                  errors={formik.errors.product_items?.[index]?.qty}
                  toucheds={formik.touched.product_items?.[index]?.qty}
                />
              </Col>
              <Col>
                {formik.values.product_items.length > 1 && (
                  <div className="mt-4" style={{ cursor: "pointer" }}>
                    <i
                      className="feather icon-trash"
                      style={{ fontSize: "20px" }}
                      onClick={() => removeRow(index)}
                    />
                  </div>
                )}
              </Col>
            </Row>
          );
        })}
      </div>
      <Row className="mt-2">
        <Col>
          <Button variant="primary" onClick={addRow}>
            + Tambah Produk
          </Button>{" "}
        </Col>
      </Row>
    </Fragment>
  );
};

export default ConfigProductItems;
