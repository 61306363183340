import * as Yup from 'yup';

const authSignin = Yup.object({
    email: Yup.string().email('Format wajib email').required('Email harap diisi'),
    password: Yup.string()
        .required('Password Harap Diisi')
});

const authForgot = Yup.object({
    email: Yup.string().email('Invalid email format').required('The email address is required'),
});

const changePassword = Yup.object({
    password: Yup.string().required('Password harap diisi'),
    password_new: Yup.string().required('Password Baru harap diisi'),
    password_confirm: Yup.string()
    .oneOf([Yup.ref('password_new'), null], 'Password harus sesuai')
    .required('Password konfirmasi harap diisi'),
});

const authResetPassword = Yup.object({
    new_password: Yup.string()
        .required('The password is required'),
    confirm_password: Yup.string()
        .required('The password confirmation is required')
        .oneOf([Yup.ref('new_password'), null], 'Password Tidak sama dengan password baru')
});

export { authSignin,authForgot, authResetPassword, changePassword };