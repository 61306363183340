/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";

import ChatList from "./ChatList";
import Aux from "../../../../../hoc/_Aux";

import Cookies from "js-cookie";
import * as ROUTEPATH from "../../../../../routePath";
import { imageError } from "../../../../../helpers/brokenImg";
import fetchAxios from "../../../../../helpers/fetchAxios";
import environments from "../../../../../environments";
import { encodeQueryData } from "../../../../../helpers/endcodeQuery";

function NavRight(props) {
  const [listOpen, setListOpent] = useState(false);
  const users = JSON.parse(Cookies.get("userObj"));
  const [hitParams, setHitParams] = useState({
    orderby: "id",
    sort: "desc",
    page: 1,
    per_page: 999,
    status: "",
    key: "",
  });
  const [dataNotif, setDataNotif] = useState();
  const [reRender, setReRender] = useState(false);

  const handleLogout = () => {
    Cookies.remove("tokenY");
    Cookies.remove("userObj");
    window.location.href = ROUTEPATH.SIGNIN;
  };

  useEffect(() => {
    fetchNotif();
  }, [reRender]);

  const fetchNotif = async () => {
    const response = await fetchAxios(
      environments.api.notification +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );

    if (response.status === 200) {
      setDataNotif(response.data?.data);
    }
  };

  const handleNotif = async (notification_id, user_id) => {
    const response = await fetchAxios(
      environments.api.notification + `/read/${notification_id}`,
      "post"
    );
    if (response.status === 200) {
      window.location.href = `/manajemen-transaksi/detail/${user_id}`;
      setReRender(!reRender);
    }
  };

  return (
    <Aux>
      <ul className="navbar-nav ml-auto">
        <li>
          <Dropdown alignRight={!props.rtlLayout}>
            <Dropdown.Toggle variant={"link"} id="dropdown-basic">
              <i className="icon feather icon-bell" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="notification">
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0">Notifications</h6>
              </div>
              <ul className="noti-body">
                <li className="n-title">
                  <div className="d-flex align-items-center">
                    <div className="mr-2">New Message</div>
                    <div>
                      <Badge bg="info">{dataNotif?.total_unread}</Badge>
                    </div>
                  </div>
                </li>
                <div style={{ height: "300px", overflow: "scroll" }}>
                  {dataNotif?.notifications?.data.map((value, i) => {
                    return (
                      <li
                        key={i}
                        className="notification"
                        style={{
                          backgroundColor:
                            value.is_read === 0 ? "#7dd3fc" : "#fff",
                        }}
                        onClick={() => handleNotif(value.id, value.ref_id)}
                      >
                        <div className="media">
                          <div className="media-body">
                            <p>
                              <strong>{value.title}</strong>
                            </p>
                            <p>{value.message}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </div>
              </ul>
              {/* <div className="noti-footer">
                <a href={DEMO.BLANK_LINK}>show all</a>
              </div> */}
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li>
          <Dropdown alignRight={!props.rtlLayout} className="drp-user">
            <Dropdown.Toggle variant={"link"} id="dropdown-basic">
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <img
                  src={users.profile_picture}
                  onError={(e) => imageError(e)}
                  className="img-radius"
                  alt="User Profile"
                  style={{ width: "50px", height: "50px" }}
                />
                <span>{users.full_name}</span>
                <div className="dud-logout">
                  <i className="feather icon-log-out" onClick={handleLogout} />
                </div>
              </div>
              <ul className="pro-body">
                {/* <li>
                  <a href={DEMO.BLANK_LINK} className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </a>
                </li> */}
                <li>
                  <a href={ROUTEPATH.PROFILE} className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </a>
                </li>
                {/* <li><a href={DEMO.BLANK_LINK} className="dropdown-item"><i className="feather icon-mail"/> My Messages</a></li>
                            <li><a href={DEMO.BLANK_LINK} className="dropdown-item"><i className="feather icon-lock"/> Lock Screen</a></li> */}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
      <ChatList
        listOpen={listOpen}
        closed={() => {
          setListOpent(false);
        }}
      />
    </Aux>
  );
}

export default NavRight;
