import dayjs from "dayjs";
import Cookies from "js-cookie";
import React from "react";
import { Button } from "react-bootstrap";

const ColumProduct = (props) => {
  return [
    {
      name: "Nama",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Kategori",
      sortable: true,
      selector: (row) => row.product_category?.name,
    },
    {
      name: "Harga",
      sortable: true,
      selector: (row) => row.price.toLocaleString("id"),
    },
    {
      name: "Stok",
      sortable: true,
      selector: (row) => row.stock,
    },
    {
      name: "Terjual",
      sortable: true,
      selector: (row) => row.total_sell,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
    },
    {
      name: "Aksi",
      width: props.type === "view" ? "" : "300px",
      cell:
        props.type === "view"
          ? null
          : (row) => {
              return (
                <div className="d-flex">
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-outline-info"
                        size="sm"
                        onClick={() => {
                          window.location.href = `/manajemen-produk/produk/detail/${row.id}`;
                        }}
                      >
                        Lihat
                      </Button>
                    </span>
                  </div>
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-outline-success"
                        size="sm"
                        onClick={() => {
                          window.location.href = `/manajemen-produk/produk/${row.id}`;
                        }}
                      >
                        Ubah
                      </Button>
                    </span>
                  </div>
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-danger"
                        size="sm"
                        onClick={() => props.showAlertDeleted(row.id)}
                      >
                        Hapus
                      </Button>
                    </span>
                  </div>
                </div>
              );
            },
    },
  ];
};

export default ColumProduct;

export const ColumProductPaket = (props) => {
  return [
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Kategori",
      selector: (row) => row.product_category?.name,
      sortable: true,
    },
    {
      name: "Harga",
      selector: (row) => row.price.toLocaleString("id"),
      sortable: true,
    },
    {
      name: "Stok",
      selector: (row) => row.stock,
      sortable: true,
    },
    {
      name: "Terjual",
      selector: (row) => row.is_sale_without_stock,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Aksi",
      width: props.type === "view" ? "" : "300px",
      sortable: true,
      cell:
        props.type === "view"
          ? null
          : (row) => {
              return (
                <div className="d-flex">
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-outline-info"
                        size="sm"
                        onClick={() => {
                          window.location.href = `/manajemen-produk/product-paket/detail/${row.id}`;
                        }}
                      >
                        Lihat
                      </Button>
                    </span>
                  </div>
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-outline-success"
                        size="sm"
                        onClick={() => {
                          window.location.href = `/manajemen-produk/product-paket/${row.id}`;
                        }}
                      >
                        Ubah
                      </Button>
                    </span>
                  </div>
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-danger"
                        size="sm"
                        onClick={() => props.showAlertDeleted(row.id)}
                      >
                        Hapus
                      </Button>
                    </span>
                  </div>
                </div>
              );
            },
    },
  ];
};

export const ColumProductItems = (props) => {
  return [
    {
      name: "Produk item",
      selector: (row) => row.product?.name,
      sortable: true,
    },
    {
      name: "Min Qty",
      selector: (row) => row.qty,
      sortable: true,
    },
  ];
};

export const ColumProductPrice = (props) => {
  return [
    {
      name: "Jenis Pelanggan",
      selector: (row) => row.mitra_group?.name,
      sortable: true,
    },
    {
      name: "Min Qty",
      selector: (row) => row.min_qty,
      sortable: true,
    },
    {
      name: "Max Qty",
      selector: (row) => row.max_qty,
      sortable: true,
    },
    {
      name: "Harga / Unit",
      selector: (row) => row.price.toLocaleString("id"),
      sortable: true,
    },
  ];
};

export const ColumProductLimit = (props) => {
  return [
    {
      name: "Jenis Pelanggan",
      selector: (row) => row.mitra_group?.name,
      sortable: true,
    },
    {
      name: "Min Qty",
      selector: (row) => row.min_qty,
      sortable: true,
    },
    {
      name: "Max Qty",
      selector: (row) => row.max_qty,
      sortable: true,
    },
  ];
};

export const ColumManagementTransaction = (props) => {
  const user = JSON.parse(Cookies.get("userObj"));

  return [
    {
      name: "No",
      selector: (row) => `#${row.code}`,
      sortable: true,
    },
    {
      name: "Tanggal Order",
      width: "130px",
      selector: (row) =>
        row.transaction_date
          ? dayjs(row.transaction_date).format("DD MMMM YYYY")
          : "-",
      sortable: true,
    },
    {
      name: "No Sales Order",
      selector: (row) =>
        row.sales_order_number ? row.sales_order_number : "-",
      sortable: true,
    },
    {
      name: "Faktur",
      selector: (row) => (row.no_faktur ? row.no_faktur : "-"),
      sortable: true,
    },
    {
      name: "Tanggal Faktur",
      width: "150px",
      selector: (row) =>
        row.tgl_faktur ? dayjs(row.tgl_faktur).format("DD MMMM YYYY") : "-",
      sortable: true,
    },
    {
      name: "Nama Mitra",
      selector: (row) => (row.mitra?.name ? row.mitra?.name : "-"),
      sortable: true,
    },
    {
      name: "Tanggal Pengiriman",
      width: "165px",
      selector: (row) => dayjs(row.shipping_date).format("DD MMMM YYYY"),
      sortable: true,
      omit: user.role?.name === "Admin GT" ? true : false,
    },
    {
      name: "Total",
      selector: (row) => Number(row.grand_total)?.toLocaleString("id"),
      sortable: true,
    },
    {
      name: "Status Order",
      width: props.type === "view" ? "" : "200px",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Aksi",
      cell:
        props.type === "view"
          ? null
          : (row) => {
              return (
                <div className="d-flex">
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-outline-info"
                        size="sm"
                        onClick={() => {
                          window.location.href = `/manajemen-transaksi/detail/${row.id}`;
                        }}
                      >
                        Lihat
                      </Button>
                    </span>
                  </div>
                </div>
              );
            },
    },
  ];
};

export const ColumBaseSaller = (props) => {
  return [
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Kategori",
      selector: (row) => row.product_category?.name,
      sortable: true,
    },
    {
      name: "Harga",
      selector: (row) => Number(row.price)?.toLocaleString("id"),
      sortable: true,
    },
    {
      name: "Stok",
      selector: (row) => row.stock,
      sortable: true,
    },
    {
      name: "Terjual",
      selector: (row) => row.total_sell,
      sortable: true,
    },
    {
      name: "Aksi",
      sortable: true,
      cell:
        props.type === "view"
          ? null
          : (row) => {
              return (
                <div className="d-flex">
                  <div>
                    <span>
                      <Button
                        outline
                        className="btn btn-outline-info"
                        size="sm"
                        onClick={() => {
                          window.location.href = `/manajemen-produk/produk/detail/${row.id}`;
                        }}
                      >
                        Lihat
                      </Button>
                    </span>
                  </div>
                </div>
              );
            },
    },
  ];
};
