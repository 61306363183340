import dayjs from "dayjs";
import React from "react";
import { Button } from "react-bootstrap";

const ColumTransactions = (props) => {
  return [
    {
      name: "No",
      sortable: true,
      selector: (row) => row.code,
    },
    {
      name: "Tanggal Order",
      sortable: true,
      width: "150px",
      selector: (row) =>
        row.transaction_date
          ? dayjs(row.transaction_date).format("DD MMMM YYYY")
          : "-",
    },
    {
      name: "No Sales Order",
      sortable: true,
      width: "140px",
      selector: (row) => row.sales_order_number,
    },
    {
      name: "Faktur ",
      width: "130px",
      sortable: true,
      selector: (row) => (row.no_faktur ? row.no_faktur : "-"),
    },
    {
      name: "Tanggal Faktur ",
      sortable: true,
      width: "150px",
      selector: (row) =>
        row.no_faktur ? dayjs(row.tgl_faktur).format("DD MMMM YYYY") : "-",
    },
    {
      name: "Nama Mitra",
      sortable: true,
      width: "130px",
      selector: (row) => (row.mitra?.name ? row.mitra?.name : "-"),
    },
    {
      name: "Total",
      sortable: true,
      selector: (row) => Number(row.grand_total)?.toLocaleString("id"),
    },
    {
      name: "Status Order",
      sortable: true,
      width: "130px",
      selector: (row) => row.status,
    },
    {
      name: "Aksi",
      sortable: true,
      width: "200px",
      cell: (row) => {
        return (
          <div className="d-flex">
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-outline-success"
                  size="sm"
                  onClick={() => {
                    window.location.href = `/manajemen-transaksi/detail/view/${row.id}`;
                  }}
                >
                  Lihat
                </Button>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export default ColumTransactions;
