/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import environments from "../../../../environments";
import { imageError } from "../../../../helpers/brokenImg";
import { encodeQueryData } from "../../../../helpers/endcodeQuery";
import fetchAxios from "../../../../helpers/fetchAxios";
import { ModalMedia } from "../../../components/Modal/ModalMedia";
import UploadMedia from "../../../components/Modal/UploadMedia";
import SweetAlert from "../../../components/SweetAlert";
import { InputAddLeft } from "./inputAddLeft";
import InputAddRight from "./InputAddRight";
import * as ROUTEPATH from "../../../../routePath";
import "./index.scss";
import { ValidateProduct } from "../../../../validate";

const AddProduct = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isModalGallery, setIsModalGallery] = useState(false);
  const [isModalUpload, setIsModalUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [listMitraGroup, setMitraGroup] = useState([]);
  const [listMasterSatuan, setListMasterSatuan] = useState([]);
  const [listProductCategory, setListProductCategory] = useState([]);
  const [details, setDetails] = useState();
  const [payload, setPayload] = useState();
  let initialPayload = {
    name: "",
    desc: "",
    galleries: "",
    product_prices: [],
    product_limits: [],
    thumbnail: "",
    product_category_id: "",
    sku: "",
    price: "",
    discount_type: "",
    discount_value: "",
    is_sale_without_stock: false,
    weight: "",
    satuan_id: "",
    length: "",
    width: "",
    height: "",
    mitra_id: "",
    publish_at: "",
    persedian_type: "",
    bkp_type: "",
    status: "",
  };

  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateProduct.satuan,
    onSubmit: (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  const onConfirm = async () => {
    let tempArray = [];
    payload.mitra_id.length > 0
      ? payload.mitra_id?.map((val) => {
          tempArray.push(val.value);
        })
      : null;
    const body = {
      type: "satuan",
      name: payload.name,
      description: payload.desc,
      galleries: selectedFiles,
      product_prices: payload.product_prices,
      product_limits: payload.product_limits,
      thumbnail: payload.thumbnail,
      product_category_id: payload.product_category_id,
      sku: payload.sku,
      price: payload.price,
      discount_type: payload.discount_type,
      discount_value: payload.discount_value,
      is_sale_without_stock: payload.is_sale_without_stock,
      weight: payload.weight,
      satuan_id: payload.satuan_id,
      length: payload.length,
      width: payload.width,
      height: payload.height,
      mitra_group_id: payload.mitra_id,
      publish_at: payload.publish_at,
      persedian_type: payload.persedian_type,
      bkp_type: payload.bkp_type,
      status: payload.status,
    };

    const response = await fetchAxios(
      `${environments.api.product}${id ? `/${id}` : ""}`,
      id ? "put" : "post",
      body
    );
    if (response.status === 200) {
      if (!id) {
        formik.resetForm();
        toast.success(response.data.message);
        setIsConfirm(false);
        history.push(ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT);
      } else {
        toast.success(response.data.message);
        setIsConfirm(false);
        history.goBack();
        // history.push(ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT)
      }
    } else {
      setIsConfirm(false);
    }
  };

  useEffect(() => {
    fetchMasterDataGroupMitra();
    fetchMasterDataProductCategory();
    fetchMasterSatuan();
  }, []);

  useEffect(() => {
    if (id) {
      fetchDetailProduct();
    }
  }, [id]);

  const fetchDetailProduct = async () => {
    const response = await fetchAxios(
      environments.api.product + "/" + id,
      "get"
    );
    if (response.status === 200) {
      let initialStateProductPrice = formik.values.product_prices;
      let initialStateProductLimit = formik.values.product_limits;
      setDetails(response.data?.data);
      formik.setFieldValue("name", response?.data?.data?.name);
      formik.setFieldValue("desc", response?.data?.data?.description);
      let arrPath = [];
      response?.data?.data?.galleries?.map((path) => {
        arrPath.push(path);
        formik.setFieldValue("galleries", response?.data?.data?.thumbnail);
      });
      setSelectedFiles(arrPath);
      response?.data?.data?.product_prices?.map((val) => {
        initialStateProductPrice.push({
          mitra_group_id: val.mitra_group_id,
          min_qty: val.min_qty,
          max_qty: val.max_qty,
          price: val.price,
          regular_price: val.regular_price,
        });
      });
      response?.data?.data?.product_limits?.map((val) => {
        initialStateProductLimit.push({
          mitra_group_id: val.mitra_group_id,
          min_qty: val.min_qty,
          max_qty: val.max_qty,
        });
      });
      formik.setFieldValue("thumbnail", response?.data?.data?.thumbnail);
      formik.setFieldValue(
        "product_category_id",
        response?.data?.data?.product_category_id
      );
      formik.setFieldValue("sku", response?.data?.data?.sku);
      formik.setFieldValue("price", response?.data?.data?.price);
      formik.setFieldValue(
        "discount_type",
        response?.data?.data?.discount_type
      );
      formik.setFieldValue(
        "discount_value",
        response?.data?.data?.discount_value
      );
      formik.setFieldValue(
        "is_sale_without_stock",
        response?.data?.data?.is_sale_without_stock
      );
      formik.setFieldValue("weight", response?.data?.data?.weight);
      formik.setFieldValue("satuan_id", response?.data?.data?.satuan_id);
      formik.setFieldValue("length", response?.data?.data?.length);
      formik.setFieldValue("width", response?.data?.data?.width);
      formik.setFieldValue("height", response?.data?.data?.height);
      formik.setFieldValue("mitra_id", response?.data?.data?.mitra_group_id);
      formik.setFieldValue("publish_at", response?.data?.data?.publish_at);
      formik.setFieldValue(
        "persedian_type",
        response?.data?.data?.persedian_type
      );
      formik.setFieldValue("bkp_type", response?.data?.data?.bkp_type);
      formik.setFieldValue("status", response?.data?.data?.status);
    }
  };

  const fetchMasterSatuan = async () => {
    const params = {
      orderby: "",
      sort: "",
      page: "",
      per_page: 999,
      status: "aktif",
      key: "",
    };
    const response = await fetchAxios(
      environments.api.master_data_satuan +
        (Object.keys(params).length > 0 ? "?" + encodeQueryData(params) : ""),
      "get"
    );
    if (response.status === 200) {
      setListMasterSatuan(response.data?.data?.data);
    }
  };

  const fetchMasterDataGroupMitra = async () => {
    const params = {
      orderby: "",
      sort: "",
      page: "",
      per_page: 999,
      status: "aktif",
      key: "",
    };
    const response = await fetchAxios(
      environments.api.master_data_mitra_group +
        (Object.keys(params).length > 0 ? "?" + encodeQueryData(params) : ""),
      "get"
    );
    if (response.status === 200) {
      setMitraGroup(response.data?.data?.data);
    }
  };

  const fetchMasterDataProductCategory = async () => {
    const params = {
      orderby: "",
      sort: "",
      page: "",
      per_page: 999,
      status: "aktif",
      key: "",
    };
    const response = await fetchAxios(
      environments.api.product_category +
        (Object.keys(params).length > 0 ? "?" + encodeQueryData(params) : ""),
      "get"
    );
    if (response.status === 200) {
      setListProductCategory(response.data?.data?.data);
    }
  };

  const optionsMitraGroup = listMitraGroup.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const optionsProductCategory = listProductCategory.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const optionsMasterSatuan = listMasterSatuan.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const handleImageChange = (value) => {
    setIsModalGallery(false);
    formik.setFieldValue("galleries", value.file);
    setSelectedFiles((state) => [...state, value.file]);
  };

  const handleDeleteImg = (path) => {
    const deletePhoto = selectedFiles.filter((value) => value !== path);
    setSelectedFiles(deletePhoto);
  };

  const renderPhotos = (source) => {
    return (source || []).map((photo, index) => {
      return (
        <div className="m-1">
          <img
            src={photo}
            key={photo}
            className="position-relative"
            onError={(e) => imageError(e)}
            width={100}
            style={{ height: "100px" }}
            alt="gallery"
          />
          <button
            type="button"
            onClick={() => handleDeleteImg(photo)}
            className="position-absolute top-0 start-0 button-close-image"
          >
            <span>&times;</span>
          </button>
        </div>
      );
    });
  };

  return (
    <Fragment>
      <div className="mb-4">
        <h3>{id ? "Ubah Product" : "Tambah Product"}</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <InputAddLeft
            formik={formik}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            renderPhotos={renderPhotos}
            showModalChooseGallery={() => setIsModalGallery(true)}
            optionsMitraGroup={optionsMitraGroup}
            details={details}
          />
          <InputAddRight
            formik={formik}
            optionsProductCategory={optionsProductCategory}
            listProductCategory={listProductCategory}
            listMasterSatuan={listMasterSatuan}
            optionsMasterSatuan={optionsMasterSatuan}
            details={details}
            optionsMitraGroup={optionsMitraGroup}
            listMitraGroup={listMitraGroup}
          />
        </Row>
        <Row>
          <div className="mx-2">
            <Button
              variant="secondary"
              onClick={() => {
                window.location.href = `/cms${ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT}`;
              }}
            >
              Kembali
            </Button>
          </div>
          <div>
            <Button variant="success" type="submit">
              Simpan
            </Button>
          </div>
        </Row>
      </form>
      <ModalMedia
        show={isModalGallery}
        handleClose={() => setIsModalGallery(false)}
        handleChooseImage={(value) => handleImageChange(value)}
        handleNestedModal={() => setIsModalUpload(true)}
      />
      <UploadMedia
        show={isModalUpload}
        handleClose={() => setIsModalUpload(false)}
        listProductCategory={listProductCategory}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi ${id ? "Merubah" : "Tambah"} Produk`}
        message={`Apakah kamu yakin untuk ${
          id ? "merubah" : "menambahkan"
        } Produk Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal22"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Fragment>
  );
};

export default AddProduct;
