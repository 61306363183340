/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import environments from '../../../environments';
import fetchAxios from '../../../helpers/fetchAxios';
import { ValidateTransactions } from '../../../validate';
import Inputs from '../../components/Inputs';
import SweetAlert from '../../components/SweetAlert';

function ModalProcessingOrder(props) {
    const {
        show,
        handleClose,
        id
    } = props
    let initialPayload = {
        no_so: ""
    }
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState(initialPayload);

    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateTransactions.processOrder,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    const onConfirm = async() => {
      const body = {
        transaction_id: id,
        sales_order_number: payload.no_so
      }
      const response = await fetchAxios(
          `${environments.api.transaction}/update-sales-order` , 
          "put",
          body);
      if (response.status === 200) {
          toast.success(response.data.message)
          setIsConfirm(false)
          handleClose()
      } else {
          setIsConfirm(false)
          handleClose()
      }
    }

    useEffect(() => {
      formik.resetForm()
    }, [show])
  
  return (
    <Modal 
        show={show} 
        onHide={handleClose}
        backdrop="static"
        centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Proses Pesanan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
                <Inputs
                    formik={ formik }
                    label="Nomer Sales Order (Accurate)"
                    type='text'
                    id="no_so"
                    name="no_so"
                    values={formik.values.no_so}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kembali
          </Button>
          <Button variant="success" type='submit'>
            Kirim
          </Button>
        </Modal.Footer>
      </form>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={`Apa anda akan Proses Pesanan ini ?`}
            message={`Apakah kamu yakin untuk Proses Pesanan Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal22"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Modal>
  )
}

export default ModalProcessingOrder