/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import Inputs from "../../../components/Inputs";
import SweetAlert from "../../../components/SweetAlert";
import { toast } from "react-toastify";
import environments from "../../../../environments";
import fetchAxios from "../../../../helpers/fetchAxios";

function UpdateStock(props) {
  const [payload, setPayload] = useState();
  const [isConfirm, setIsConfirm] = useState(false);
  const { show, handleClose, detailUpdate } = props;

  let initialPayload = {
    stock: "",
  };

  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: Yup.object({
      stock: Yup.number()
        .test(
          "Is positive?",
          "Stock tidak boleh di awali dengan minus",
          (value) => value >= 0,
        )
        .required("Silahkan isi telepon"),
    }),
    onSubmit: (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  useEffect(() => {
    if (Object.keys(detailUpdate).length > 0) {
      formik.setFieldValue("stock", detailUpdate.stock);
    }
  }, [detailUpdate]);

  const onConfirm = async () => {
    const body = {
      data: [
        {
          product_id: detailUpdate.id,
          stock: payload.stock,
        },
      ],
    };

    const response = await fetchAxios(
      `${environments.api.product}/updatestock`,
      "put",
      body,
    );
    if (response.status === 200) {
      formik.resetForm();
      toast.success(response.data.message);
      setIsConfirm(false);
      handleClose();
    } else {
      handleClose();
      setIsConfirm(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex justify-content-end">
          <div className="mx-4 my-2 close" onClick={handleClose}>
            X
          </div>
        </div>
        <Modal.Header>
          <Modal.Title>Update Stok</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Inputs
            formik={formik}
            label="Stock"
            type="number"
            id="stock"
            name="stock"
            values={formik.values.stock}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" type="submit">
            Simpan Stock
          </Button>
        </Modal.Footer>
      </form>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi Merubah Stok`}
        message={`Apakah kamu yakin untuk merubah Stok Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal22"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Modal>
  );
}

export default UpdateStock;
