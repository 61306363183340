/* eslint-disable no-dupe-keys */
export const env = process.env.REACT_APP_BE_URL;
const version = "/api/admin/v1";
export default {
  api: {
    signin: `${env}${version}/login`,
    forgot: `${env}${version}/forgot-password`,
    reset_password: `${env}${version}/reset-password`,
    mintra_management: `${env}${version}/mitra`,
    product_category: `${env}${version}/product-category`,
    master_data_province: `${env}${version}/province`,
    master_data_city: `${env}${version}/city`,
    master_data_district: `${env}${version}/district`,
    master_data_mitra_group: `${env}${version}/mitra-group`,
    management_admin_role: `${env}${version}/role`,
    management_admin_admin: `${env}${version}/user`,
    management_content: `${env}${version}/content`,
    setting: `${env}${version}/setting`,
    master_data_satuan: `${env}${version}/satuan`,
    management_plafond: `${env}${version}/mitra/plafond`,
    shipping_method: `${env}${version}/shipping-method`,
    all_menu: `${env}${version}/role/allmenu`,
    menu_role: `${env}${version}/user-menu`,
    media: `${env}${version}/media`,
    product: `${env}${version}/product`,
    transaction: `${env}${version}/transaction`,
    change_password: `${env}${version}/change-password`,
    profile_me: `${env}${version}/me`,
    update_profile: `${env}${version}/update-profile`,
    dashboard: `${env}${version}/dashboard`,
    notification: `${env}${version}/notification`,
  },
};
