/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Col, Row } from "react-bootstrap";
import { imageError } from "../../../helpers/brokenImg";
import Avatar1 from "../../../assets/images/user/avatar-1.jpg";
import Inputs from "../../components/Inputs";
import { useFormik } from "formik";
import { ChangePassword } from "./changePassword";
import fetchAxios from "../../../helpers/fetchAxios";
import environments from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import SweetAlert from "../../components/SweetAlert";
import { toast } from "react-toastify";
export const Profile = () => {
  const [tempImages, setTempImages] = useState("");
  const avatarRef = useRef();
  const [listRoles, setListRoles] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [payload, setPayload] = useState();
  const [reRender, setReRender] = useState(false);

  const changeAvatar = () => {
    let avatar = URL.createObjectURL(avatarRef?.current?.files[0]);
    if (avatar !== undefined && avatar !== tempImages) {
      setTempImages(avatar);
    }
  };

  useEffect(() => {
    fetchDetailProfile();
  }, []);

  const fetchDetailProfile = useCallback(async () => {
    const response = await fetchAxios(`${environments.api.profile_me}`, "get");
    if (response.status === 200) {
      let data = response?.data?.data;
      formik.setFieldValue("name", data.full_name);
      formik.setFieldValue("email", data.email);
      formik.setFieldValue("profile_picture", data.profile_picture);
      setTempImages(data.profile_picture);
    }
  }, [reRender]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      profile_picture: "",
    },
    onSubmit: (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  useEffect(() => {
    fetchListRole();
  }, []);

  const fetchListRole = async () => {
    const hitParams = {
      orderby: "",
      sort: "desc",
      page: 1,
      per_page: 999,
      status: "",
      key: "",
    };
    const response = await fetchAxios(
      `${environments.api.management_admin_role}` +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setListRoles(response?.data?.data?.data);
    }
  };

  // const optionsRole = listRoles.map((val) => {
  //   return {
  //     value: val.id,
  //     label: val.name,
  //   };
  // });

  const onConfirm = async () => {
    const formData = new FormData();
    formData.append("full_name", payload.name);
    formData.append("email", payload.email);
    formData.append("profile_picture", payload.profile_picture);

    const response = await fetchAxios(
      `${environments.api?.update_profile}`,
      "POST",
      formData
    );
    if (response.status === 200) {
      setIsConfirm(false);
      toast.success(response.data.message);
      setReRender(!reRender);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="d-flex justify-content-center">
            <img
              onError={(e) => imageError(e)}
              className="img-radius avatar"
              src={tempImages ? tempImages : Avatar1}
              alt="Generic placeholder"
            />
          </div>
          {formik.errors.profile_picture && formik.touched.profile_picture && (
            <p
              style={{ color: "red", fontSize: "11px" }}
              className="text-center"
            >
              {formik.errors.profile_picture}
            </p>
          )}
          <div className="mt-2 pr-5 pl-5">
            <label class="custom-file-upload">
              <input
                accept="image/*"
                name="profile_picture"
                type="file"
                onChange={(e) => {
                  changeAvatar();
                  formik.setFieldValue("profile_picture", e.target.files[0]);
                }}
                ref={avatarRef}
              />
              Upload
            </label>
          </div>
        </Col>
        <Col>
          <form onSubmit={formik.handleSubmit}>
            <Inputs
              formik={formik}
              label="Nama"
              type="text"
              id="name"
              name="name"
              values={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Inputs
              formik={formik}
              label="Email"
              type="email"
              id="email"
              name="email"
              values={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* <Selects
              label="Role"
              options={optionsRole}
              placeholder="Semua Status"
              onChange={(e) => {
                // setHitParams({
                //     ...hitParams,
                //     status: e ? e.value : "",
                // })
              }}
              isClearable={true}
            /> */}
            <Button variant="success" className="mt-3" type="submit">
              Simpan
            </Button>{" "}
          </form>
        </Col>
        <Col>
          <ChangePassword />
        </Col>
      </Row>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi Update Profile`}
        message={`Apakah kamu yakin untuk update Profile `}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal22"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Fragment>
  );
};
