/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";
import ChartMitraTransaction from "./chartMitraTransaction";
import ChartProductTransaction from "./chartProductTransaction";
import Chart from "./chartSummeryTransaction";
import ChartTopCostumer from "./chartTopCostumer";
import DashboardTransaction from "./transactions";

const Dashboard = () => {
  const [isActive, setIsActive] = useState({
    transaction: true,
    summeryTransaction: false,
    transactionMitra: false,
    transactionProduct: false,
    topCustomer: false,
  });

  const [summery, setSummery] = useState([]);

  const [paramsSummeryTransaction, setParamsSummeryTransaction] = useState({
    month: "11",
    year: "2022",
    status: "sudah diterima",
  });
  const [month, setMonth] = useState(new Date());

  const [dataChartTransaction, setDataChartTransaction] = useState();

  useEffect(() => {
    fetchSummery();
  }, [paramsSummeryTransaction]);

  const fetchSummery = async () => {
    const response = await fetchAxios(
      environments.api.dashboard +
        `/sales?month=${paramsSummeryTransaction.month}&year=${paramsSummeryTransaction.year}&status[]=${paramsSummeryTransaction.status}`,
      "get"
    );
    if (response.status === 200) {
      setSummery(response.data?.data?.chart);
    }
  };

  const handleMonth = (e) => {
    setParamsSummeryTransaction({
      ...paramsSummeryTransaction,
      month: dayjs(e).format("MM"),
      year: dayjs(e).format("YYYY"),
    });
    setMonth(e);
  };

  const componentChart = () => {
    if (isActive.transaction) {
      return <DashboardTransaction />;
    } else if (isActive.summeryTransaction) {
      return (
        <Chart
          summery={summery}
          dataChartTransaction={dataChartTransaction}
          month={month}
          setMonth={(e) => handleMonth(e)}
          // years={years}
          // setYears={(e) => handleYears(e)}
        />
      );
    } else if (isActive.topCustomer) {
      return <ChartTopCostumer />;
    } else if (isActive.transactionMitra) {
      return <ChartMitraTransaction />;
    } else if (isActive.transactionProduct) {
      return <ChartProductTransaction />;
    }
  };

  return (
    <Fragment>
      <ButtonGroup aria-label="Basic example">
        <Button
          variant={isActive.transaction ? "primary" : "outline-secondary"}
          onClick={() =>
            setIsActive({
              transaction: !isActive.transaction,
            })
          }
        >
          Transaksi
        </Button>
        <Button
          variant={
            isActive.summeryTransaction ? "primary" : "outline-secondary"
          }
          onClick={() =>
            setIsActive({
              summeryTransaction: !isActive.summeryTransaction,
            })
          }
        >
          Summary Transaksi
        </Button>
        <Button
          variant={isActive.topCustomer ? "primary" : "outline-secondary"}
          onClick={() =>
            setIsActive({
              topCustomer: !isActive.topCustomer,
            })
          }
        >
          Top Customer
        </Button>
        <Button
          variant={isActive.transactionMitra ? "primary" : "outline-secondary"}
          onClick={() =>
            setIsActive({
              transactionMitra: !isActive.transactionMitra,
            })
          }
        >
          Transaksi Mitra
        </Button>
        <Button
          variant={
            isActive.transactionProduct ? "primary" : "outline-secondary"
          }
          onClick={() =>
            setIsActive({
              transactionProduct: !isActive.transactionProduct,
            })
          }
        >
          Transaksi Product
        </Button>
      </ButtonGroup>
      {componentChart()}
    </Fragment>
  );
};

export default Dashboard;
