/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";

import Inputs from '../../../components/Inputs';
import fetchAxios from '../../../../helpers/fetchAxios';
import environments from '../../../../environments';
import { toast } from 'react-toastify';
import { ValidateMasterData } from '../../../../validate';
import SweetAlert from '../../../components/SweetAlert';
import * as ROUTEPATH from '../../../../routePath';

export const AddGroupMitra = () => {
    let history = useHistory();
    const { id } = useParams();
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState();
    let initialPayload = {
        name: '',
        status: '',
    }
    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateMasterData.groupMitra,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    const onConfirm = async() => {
        const body = {
            name: payload.name,
            status: payload.status
        }
        const response = await fetchAxios(
            `${environments.api.master_data_mitra_group}${id ? `/${id}` : "" }` , 
            id ? "put" : "post", 
            body);
        if (response.status === 200) {
            if (!id) {
                formik.resetForm()
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.MASTER_DATA_GROUP_MITRA)
            } else {
                toast.success(response.data.message)
                setIsConfirm(false)
                history.push(ROUTEPATH.MASTER_DATA_GROUP_MITRA)
            }
        } else {
            setIsConfirm(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchDetailGroupMitra()
        }
      }, [id])

    const fetchDetailGroupMitra = async() => {
        const response = await fetchAxios(
            `${environments.api.master_data_mitra_group}/${id}`,
            "get"
        )
        
        if (response.status === 200) {
            formik.setFieldValue('name',response?.data?.data?.name)
            formik.setFieldValue('status',response?.data?.data?.status)
        }
    }
    
  return (
    <Fragment>
        <form onSubmit={formik.handleSubmit}>
            <Row className='mb-3'>
                <h4>{ id ? 'Update Group Mitra' : "Tambah Group Mitra" }</h4>
            </Row>
            <Row>
                <Col sm="5">
                    <Inputs
                        formik={ formik }
                        label="Name"
                        type='text'
                        id="name"
                        name="name"
                        values={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <div className='mt-2' />
                    <p>Status</p>
                    <Form.Group>
                        <div className='d-flex'>
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="Aktif"
                                name="status"
                                id="active"
                                value='aktif'
                                onChange={(e) => {
                                    formik.setFieldValue('status',e.target.value)
                                }}
                                checked={formik.values.status === "aktif" ? true : false }
                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="Tidak Aktif"
                                name="status"
                                value="tidak aktif"
                                id="notActive"
                                onChange={(e) => {
                                    formik.setFieldValue('status',e.target.value)
                                }}
                                checked={formik.values.status === "tidak aktif" ? true : false }
                            />
                        </div>
                    </Form.Group>
                    {
                        formik.errors.status && formik.touched.status &&
                        (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.status }</p>)
                    }
                    <Row className='mt-4'>
                        <Col sm='3'>
                            <Button className='btn btn-outline-secondary' onClick={() => {window.location.href = `/cms${ROUTEPATH.MASTER_DATA_GROUP_MITRA}`}}>Kembali</Button>{' '}
                        </Col>
                        <Col sm='3'>
                            <Button className='btn btn-success' type='submit'>Simpan</Button>{' '}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={`Konfirmasi ${id ? 'Merubah' : 'Tambah'} Group Mitra`}
            message={`Apakah kamu yakin untuk ${id ? 'merubah' : 'menambahkan'} Group Mitra Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal22"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Fragment>
  )
}
