/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { debounce } from "lodash";
import DataTable from "react-data-table-component";
import fetchAxios from "../../../../helpers/fetchAxios";
import environments from "../../../../environments";
import { encodeQueryData } from "../../../../helpers/endcodeQuery";
import ColumManagementStock from "../../../components/Colums/ColumManagementStock";
import UpdateStock from "./updateStock";

function ManagementStock(props) {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [detailUpdate, setDetailUpdate] = useState({});
  const [keyword, setKeyword] = useState("");
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const searchInput = useRef();
  const [hitParams, setHitParams] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 10,
    status: "",
    key: "",
    is_sale_without_stock: false,
  });

  const showUpdateStock = (row) => {
    setDetailUpdate(row);
    setIsUpdateModal(true);
  };

  props = {
    showUpdateStock,
  };

  useEffect(() => {
    fetchProduct();
  }, [hitParams, isUpdateModal]);

  const fetchProduct = async () => {
    const response = await fetchAxios(
      environments.api.product +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setData(response.data?.data?.data);
      setDataTable(response.data);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setHitParams({
      ...hitParams,
      page: page,
      per_page: newPerPage,
    });
  };

  const handlePageChange = (page) => {
    setHitParams({
      ...hitParams,
      page: page,
    });
  };

  const searchAction = debounce((e) => {
    setHitParams({
      ...hitParams,
      key: searchInput?.current.value,
    });
  }, 500);

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Pencarian"
              aria-label="search"
              aria-describedby="basic-addon1"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                searchAction(e);
              }}
              ref={searchInput}
              style={{ backgroundColor: "#fff" }}
            />
          </InputGroup>
        </div>
      </div>
      <DataTable
        columns={ColumManagementStock(props)}
        data={data}
        noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
        pagination
        paginationServer
        responsive
        paginationTotalRows={dataTable?.data?.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
      />
      <UpdateStock
        show={isUpdateModal}
        handleClose={() => setIsUpdateModal(false)}
        detailUpdate={detailUpdate}
      />
    </Fragment>
  );
}

export default ManagementStock;
