/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import environments from "../../../../environments";
import { encodeQueryData } from "../../../../helpers/endcodeQuery";
import fetchAxios from "../../../../helpers/fetchAxios";
import { ColumStock } from "../../../components/Colums/ColumReport";
import Selects from "../../../components/Selects";

function ReportStock() {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [listProductCategory, setListProductCategory] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [downloadExcel, setDownloadExcel] = useState("");
  const searchInput = useRef();
  const [hitParams, setHitParams] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 10,
    key: "",
    product_category_id: "",
  });

  useEffect(() => {
    fetchStock();
  }, [hitParams]);

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = async () => {
    const params = {
      orderby: "",
      sort: "",
      page: "",
      per_page: 999,
      status: "aktif",
      key: "",
    };
    const response = await fetchAxios(
      environments.api.product_category +
        (Object.keys(params).length > 0 ? "?" + encodeQueryData(params) : ""),
      "get"
    );
    if (response.status === 200) {
      setListProductCategory(response.data?.data?.data);
    }
  };

  const fetchStock = async () => {
    const response = await fetchAxios(
      `${environments.api.product}/report` +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    setData(response.data?.data?.rows?.data);
    setDataTable(response.data?.data?.rows);
    setDownloadExcel(response.data?.data?.export_link);
  };

  const optionsProductCategory = listProductCategory.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const handlePerRowsChange = async (newPerPage, page) => {
    setHitParams({
      ...hitParams,
      page: page,
      per_page: newPerPage,
    });
  };

  const handlePageChange = (page) => {
    setHitParams({
      ...hitParams,
      page: page,
    });
  };

  const handleSubmit = () => {
    setHitParams({
      ...hitParams,
      key: keyword,
      product_category_id: selectCategory,
    });
  };

  return (
    <Fragment>
      <Row xs={1} md={4} className="grid gap-3">
        <Col className="mb-2">
          <Selects
            label=""
            name={`product_category_id`}
            options={optionsProductCategory}
            placeholder="Pilih Kategori"
            onChange={(e) => {
              setSelectCategory(e ? e.value : "");
            }}
            isClearable={true}
          />
        </Col>
        <Col className="mb-2">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Pencarian"
              aria-label="search"
              aria-describedby="basic-addon1"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              ref={searchInput}
              style={{ backgroundColor: "#fff" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  setHitParams({
                    ...hitParams,
                    key: e.target.value,
                    product_category_id: selectCategory,
                  });
                }
              }}
            />
          </InputGroup>
        </Col>
        <Col md={6} className="mb-2">
          <div className="d-flex">
            <div>
              <Button variant="primary" onClick={handleSubmit}>
                Cari Data
              </Button>
            </div>
            <div>
              <Button variant="success" href={downloadExcel}>
                Download Excel
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <DataTable
        columns={ColumStock()}
        data={data}
        noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
        pagination
        paginationServer
        paginationTotalRows={dataTable?.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
      />
    </Fragment>
  );
}

export default ReportStock;
