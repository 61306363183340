import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import environments from '../../../environments';
import fetchAxios from '../../../helpers/fetchAxios';
import { ValidateTransactions } from '../../../validate';
import DatePickers from '../../components/DatePickers';
import Inputs from '../../components/Inputs';
import Selects from '../../components/Selects';
import SweetAlert from '../../components/SweetAlert';

function ModalConfirmOrder(props) {
    const {
        show,
        handleClose,
        id
    } = props
    let initialPayload = {
        send_method: "",
        send_date: "",
        shipping_cost: "",
        note: ""
    }
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [startDate, setStartDate] = useState(null);
    const [listShippingMethod, setListShippingMethod] = useState([]);

    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateTransactions.confirm,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });

    const onConfirm = async() => {
      const body = {
        transaction_id: id,
        shipping_method_id: payload.send_method,
        shipping_date: dayjs(payload.send_date).format("YYYY-MM-DD"),
        shipping_cost: payload.shipping_cost,
        note: payload.note
      }
      
      const response = await fetchAxios(
          `${environments.api.transaction}/confirm` , 
          "put",
          body);
      if (response.status === 200) {
          toast.success(response.data.message)
          setIsConfirm(false)
          handleClose()
      } else {
          setIsConfirm(false)
          handleClose()
      }
    }

    useEffect(() =>{
      fetchListShippingMethod()
    },[show])

    const fetchListShippingMethod = async() => {
      const response = await fetchAxios(
        `${environments.api.shipping_method}`,
        "get")
      if (response.status === 200) {
          setListShippingMethod(response?.data?.data?.data)
      }
    }

    const optionListShippingMethod = listShippingMethod.map((val) => {
      return {
        value: val.id,
        label: val.name,
      }
    })
  
  return (
    <Modal 
        show={show} 
        onHide={handleClose}
        backdrop="static"
        centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Konfirmasi Pesanan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
                <Selects
                    formik={formik}
                    label="Metode Pengiriman"
                    name="send_method"
                    options={optionListShippingMethod}
                    placeholder="Pilih Pengiriman"
                    onChange={(e) => { 
                        formik.setFieldValue('send_method',e ? e.value : "")
                    }}
                    isClearable={true}
                />
                <DatePickers
                  formik={formik}
                  label="Tanggal Pengiriman"
                  name="send_date"
                  dateFormat="d MMMM yyyy"
                  selected={startDate}
                  onChange={(e) => {
                      setStartDate(e)
                      formik.setFieldValue('send_date',e)
                  }}
                />
                <Inputs
                    formik={ formik }
                    label="Ongkos Kirim"
                    type='number'
                    id="shipping_cost"
                    name="shipping_cost"
                    values={formik.values.shipping_cost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <Inputs
                    formik={ formik }
                    label="Note"
                    type='textarea'
                    id="note"
                    name="note"
                    values={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kembali
          </Button>
          <Button variant="success" type='submit'>
            Kirim
          </Button>
        </Modal.Footer>
      </form>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={`Apa anda akan Konfirmasi Pesanan ini ?`}
            message={`Apakah kamu yakin untuk Konfirmasi Pesanan Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal22"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Modal>
  )
}

export default ModalConfirmOrder