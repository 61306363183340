/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./index.scss";
import { AlertCircle } from "react-feather";
import { Button } from "react-bootstrap";
import ButtonProcess from "../ButtonProcess";

const SweetAlert = (p) => {
  let classShow = "popup";
  let component = "";

  if (p.isActive) {
    classShow += " show";
    document.body.classList.add("popup-open");
  } else {
    document.body.classList.remove("popup-open");
  }

  if (p.type === "confirm") {
    component = (
      <div
        className={`row justify-content-center align-items-center button-group ${
          p.buttonReversed ? "button-reversed" : ""
        }`}
      >
        <div className="col-auto">
          <ButtonProcess
            onLoading={p.onLoading}
            label={p.btnConfirm}
            onClick={() => {
              if (!p.onLoading) {
                p.onConfirm();
              }
            }}
            disabled={p.inputForm ? true : false}
          />
        </div>
        <div className="col-auto">
          <Button color="danger" outline onClick={p.onCancel}>
            Batal
          </Button>
        </div>
      </div>
    );
  } else {
    component = (
      <ButtonProcess 
          onLoading={p.onLoading}
          color={p.buttonColor} 
          label={p.btnConfirm ?? 'Ok'}
          onClick={() => {
            if (!p.onLoading) {
                p.onConfirm()
          }
      }}/>
    )
  }

  return (
    <div
      className={classShow + (p.onLoading ? " on-loading-btn" : "")}
      role="dialog"
      tabIndex="-1"
      aria-labelledby="popup"
      aria-modal="true"
    >
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();

          p.onCancel();
        }}
        className="overlay-popup"
      />
      <div className="popup-dialog popup-dialog-centered">
        <div className="popup-content text-center">
          <div className="image">{p.icon || <AlertCircle size={80} />}</div>
          <div className="title">{p.title}</div>
          <div className="message">{p.message}</div>
          {component}
        </div>
      </div>
    </div>
  );
};

export default SweetAlert;
