/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import environments from '../../../environments';
import fetchAxios from '../../../helpers/fetchAxios';
import { ValidateMitraManagement } from '../../../validate';
import SweetAlert from '../../components/SweetAlert';

const ModalActive = (props) => {
    const [isConfirm, setIsConfirm] = useState(false);
    const [payload, setPayload] = useState();
    const {
        show,
        handleClose,
        allData
    } = props
    let initialPayload = {
        status: '',
    }
    const formik = useFormik({
        initialValues: initialPayload,
        validationSchema: ValidateMitraManagement.active,
        onSubmit: async (values) => {
            setIsConfirm(true)
            setPayload({...values})
        },
    });
    useEffect(() => {
        if (allData) {
            formik.setFieldValue('status', allData.status)
        }
    },[show])

    const onConfirm = async() => {
        const body = {
            status: payload.status
        }
        const response = await fetchAxios(
            `${environments.api.mintra_management}/update-status/${allData.id}` , 
            "put", 
            body);
        if (response.status === 200) {
            formik.resetForm()
            toast.success(response.data.message)
            setIsConfirm(false)
            handleClose()
        } else {
            setIsConfirm(false)
        }
    }
  return (
    <Fragment>
        <Modal show={show} onHide={handleClose}>
            <form onSubmit={formik.handleSubmit}>
                <div className='d-flex justify-content-end'>
                    <div 
                        className='mx-4 my-2 close'
                        onClick={handleClose}
                    >X</div>
                </div>
                <div className='text-center'>
                    <h4>Status</h4>
                </div>
                <Modal.Body>
                    <Form.Group>
                        <div className='d-flex justify-content-center'>
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="Aktif"
                                name="status"
                                id="active"
                                value='aktif'
                                onChange={(e) => {
                                    formik.setFieldValue('status',e.target.value)
                                }}
                                checked={formik.values.status === "aktif" ? true : false }
                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="Tidak Aktif"
                                name="status"
                                value="tidak aktif"
                                id="notActive"
                                onChange={(e) => {
                                    formik.setFieldValue('status',e.target.value)
                                }}
                                checked={formik.values.status === "tidak aktif" ? true : false }
                            />
                        </div>
                    </Form.Group>
                    {
                        formik.errors.status && formik.touched.status &&
                        (
                        <p 
                            style={{ color: 'red', fontSize:'14px', }} 
                            className='text-center mt-2'>
                            { formik.errors.status }
                        </p>)
                    }
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Kembali
                </Button>
                <Button variant="primary" type="submit">
                    Simpan
                </Button>
                </Modal.Footer>
            </form>
        </Modal>
        <SweetAlert
            type="confirm"
            iconType="question"
            isActive={isConfirm}
            title={`Konfirmasi Merubah Status`}
            message={`Apakah kamu yakin untuk merubah Status Ini?`}
            onConfirm={onConfirm}
            btnConfirm="Konfirmasi"
            btnCancel="Batal22"
            onCancel={() => {
              setIsConfirm(false);
            }}
        />
    </Fragment>
  )
}

export default ModalActive