import React, { Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Inputs from "../../../components/Inputs";
import Selects from "../../../components/Selects";

const ConfigPrice = (props) => {
  const { formik, optionsMitraGroup, details } = props;

  const addRow = () => {
    let product_prices = formik.values.product_prices;
    product_prices.push({
      mitra_group_id: "",
      min_qty: "",
      max_qty: "",
      price: "",
      regular_price: "",
    });
    formik.setFieldValue("product_prices", product_prices);
  };
  const removeRow = (index) => {
    let product_prices = formik.values.product_prices;
    if (product_prices.length < 1) {
      return;
    }
    product_prices.splice(index, 1);
    formik.setFieldValue("moladinLegendTarget", product_prices);
  };

  return (
    <Fragment>
      <div className="mt-2">
        <p>Harga Bertingkat</p>
        {formik.values.product_prices.map((value, index) => {
          return (
            <Row className="align-items-center" xs={2} md={4} lg={3}>
              <Col>
                <Selects
                  formik={formik}
                  label="Jenis Pelanggan"
                  name={`product_prices.${index}.mitra_group_id`}
                  options={optionsMitraGroup}
                  placeholder={
                    details
                      ? details?.product_prices[index]?.mitra_group?.name
                      : "Jenis Pelanggan"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `product_prices.${index}.mitra_group_id`,
                      e.value
                    );
                  }}
                  isCostumeValidate
                  errors={formik.errors.product_prices?.[index]?.mitra_group_id}
                  toucheds={
                    formik.touched.product_prices?.[index]?.mitra_group_id
                  }
                />
              </Col>
              <Col>
                <div className="mt-2">
                  <Inputs
                    formik={formik}
                    label="Min Qty"
                    type="number"
                    id="min_qty"
                    name={`product_prices.${index}.min_qty`}
                    values={formik.values.product_prices?.[index]?.min_qty}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isCostumeValidate
                    errors={formik.errors.product_prices?.[index]?.min_qty}
                    toucheds={formik.touched.product_prices?.[index]?.min_qty}
                  />
                </div>
              </Col>
              <Col>
                <div className="mt-2">
                  <Inputs
                    formik={formik}
                    label="Max Qty"
                    type="number"
                    id="max_qty"
                    name={`product_prices.${index}.max_qty`}
                    values={formik.values.product_prices?.[index]?.max_qty}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isCostumeValidate
                    errors={formik.errors.product_prices?.[index]?.max_qty}
                    toucheds={formik.touched.product_prices?.[index]?.max_qty}
                  />
                </div>
              </Col>
              <Col>
                <div className="mt-2">
                  <Inputs
                    formik={formik}
                    label="Harga / Unit"
                    type="number"
                    id="price"
                    name={`product_prices.${index}.price`}
                    values={formik.values.product_prices?.[index]?.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isCostumeValidate
                    errors={formik.errors.product_prices?.[index]?.price}
                    toucheds={formik.touched.product_prices?.[index]?.price}
                  />
                </div>
              </Col>
              <Col>
                <div className="mt-2">
                  <Inputs
                    formik={formik}
                    label="Harga Normal"
                    type="number"
                    id="regular_price"
                    name={`product_prices.${index}.regular_price`}
                    values={
                      formik.values.product_prices?.[index]?.regular_price
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isCostumeValidate
                    errors={
                      formik.errors.product_prices?.[index]?.regular_price
                    }
                    toucheds={
                      formik.touched.product_prices?.[index]?.regular_price
                    }
                  />
                </div>
              </Col>
              <Col>
                <div className="mt-4" style={{ cursor: "pointer" }}>
                  <i
                    className="feather icon-trash"
                    style={{ fontSize: "20px", color: "red" }}
                    onClick={() => removeRow(index)}
                  />
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
      <Row className="mt-2">
        <Col>
          <Button variant="primary" onClick={addRow}>
            + Tambah Harga
          </Button>{" "}
        </Col>
      </Row>
    </Fragment>
  );
};

export default ConfigPrice;
