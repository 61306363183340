/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Row, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";

import FormsLeft from "./formsLeft";
import FormsRight from "./formsRight";
import { ValidateMitraManagement } from "../../../validate";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import { toast } from "react-toastify";
import SweetAlert from "../../components/SweetAlert";
import { MANAGEMENT_MITRA } from "../../../routePath";

const AddManagemenMitra = () => {
  let history = useHistory();
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listMitraGroup, setMitraGroup] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [details, setDetails] = useState();
  const [labelMitraGroup, setLabelMitraGroup] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [paramsCity, setParamsCity] = useState({
    orderby: "id",
    sort: "desc",
    page: 1,
    per_page: 999,
    status: "",
    key: "",
    province_id: "",
  });
  const [paramsDistrict, setParamsDistrict] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 999,
    status: "",
    key: "",
    city_id: "",
  });
  const { id } = useParams();
  let initialPayload = {
    registration_date: "",
    name: "",
    code: "",
    group_mitra: "",
    shipping_address: "",
    province_id: "",
    city_id: "",
    district_id: "",
    village: "",
    post_code: "",
    phone: "",
    fax: "",
    owner_name: "",
    owner_address: "",
    sales_person: "",
    billing_contact: "",
    email: "",
    company_type: "",
    ktp_no: "",
    npwp_no: "",
    stdud_no: "",
    nib_no: "",
    note: "",
    status: "",
    mode_edit: false,
  };
  const [payload, setPayload] = useState(initialPayload);
  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateMitraManagement.addMitra,
    onSubmit: async (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  useEffect(() => {
    fetchMasterDataProvince();
    fetchMasterDataGroupMitra();
  }, []);

  useEffect(() => {
    fetchMasterDataDistrict();
  }, [paramsDistrict]);

  useEffect(() => {
    fetchMasterDataCity();
  }, [paramsCity]);

  useEffect(() => {
    if (id) {
      fetchDetailMitra();
    }
  }, [id]);

  const fetchDetailMitra = async () => {
    const response = await fetchAxios(
      `${environments.api.mintra_management}/${id}`,
      "get",
    );
    if (response.status === 200) {
      setParamsDistrict({
        ...paramsDistrict,
        city_id: response?.data?.data?.city_id,
      });
      setDetails(response?.data?.data);
      setLabelMitraGroup(response?.data?.data?.mitra_group?.name);
      setStartDate(new Date(response?.data?.data?.registration_date));
      formik.setFieldValue(
        "registration_date",
        response?.data?.data?.registration_date,
      );
      formik.setFieldValue("name", response?.data?.data?.name);
      formik.setFieldValue("code", response?.data?.data?.code);
      formik.setFieldValue("group_mitra", response?.data?.data?.mitra_group_id);
      formik.setFieldValue(
        "shipping_address",
        response?.data?.data?.shipping_address,
      );
      formik.setFieldValue("province_id", response?.data?.data?.province_id);
      formik.setFieldValue("city_id", response?.data?.data?.city_id);
      formik.setFieldValue("district_id", response?.data?.data?.district_id);
      formik.setFieldValue("village", response?.data?.data?.village);
      formik.setFieldValue("post_code", response?.data?.data?.post_code);
      formik.setFieldValue("phone", response?.data?.data?.phone);
      formik.setFieldValue("fax", response?.data?.data?.fax);
      formik.setFieldValue("owner_name", response?.data?.data?.owner_name);
      formik.setFieldValue(
        "owner_address",
        response?.data?.data?.owner_address,
      );
      formik.setFieldValue("sales_person", response?.data?.data?.sales_person);
      formik.setFieldValue(
        "billing_contact",
        response?.data?.data?.billing_contact,
      );
      formik.setFieldValue("email", response?.data?.data?.email);
      formik.setFieldValue("company_type", response?.data?.data?.company_type);
      formik.setFieldValue("ktp_no", response?.data?.data?.ktp_no);
      formik.setFieldValue("npwp_no", response?.data?.data?.npwp_no);
      formik.setFieldValue("stdud_no", response?.data?.data?.stdud_no);
      formik.setFieldValue("nib_no", response?.data?.data?.nib_no);
      formik.setFieldValue("note", response?.data?.data?.note);
      formik.setFieldValue("status", response?.data?.data?.status);
      formik.setFieldValue("mode_edit", true);
    }
  };
  const fetchMasterDataProvince = async () => {
    const params = {
      orderby: "",
      sort: "",
      page: "",
      per_page: "",
      status: "",
      key: "",
    };
    const response = await fetchAxios(
      environments.api.master_data_province +
        (Object.keys(params).length > 0 ? "?" + encodeQueryData(params) : ""),
      "get",
    );
    if (response.status === 200) {
      setListProvince(response.data?.data?.data);
    }
  };
  const fetchMasterDataCity = async () => {
    const response = await fetchAxios(
      environments.api.master_data_city +
        (Object.keys(paramsCity).length > 0
          ? "?" + encodeQueryData(paramsCity)
          : ""),
      "get",
    );
    if (response.status === 200) {
      setListCity(response.data?.data?.data);
    }
  };
  const fetchMasterDataDistrict = async () => {
    const response = await fetchAxios(
      environments.api.master_data_district +
        (Object.keys(paramsDistrict).length > 0
          ? "?" + encodeQueryData(paramsDistrict)
          : ""),
      "get",
    );
    if (response.status === 200) {
      setListDistrict(response.data?.data?.data);
    }
  };
  const fetchMasterDataGroupMitra = async () => {
    const params = {
      orderby: "",
      sort: "",
      page: "",
      per_page: "",
      status: "aktif",
      key: "",
    };
    const response = await fetchAxios(
      environments.api.master_data_mitra_group +
        (Object.keys(params).length > 0 ? "?" + encodeQueryData(params) : ""),
      "get",
    );
    if (response.status === 200) {
      setMitraGroup(response.data?.data?.data);
    }
  };
  const optionsProvince = listProvince.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });
  const optionsCity = listCity.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const optionsDistrict = listDistrict.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });
  const optionsMitraGroup = listMitraGroup.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  const onConfirm = async () => {
    const body = {
      registration_date: dayjs(payload.registration_date).format("YYYY-MM-DD"),
      name: payload.name,
      code: payload.code,
      mitra_group_id: payload.group_mitra,
      shipping_address: payload.shipping_address,
      province_id: payload.province_id,
      city_id: payload.city_id,
      district_id: payload.district_id,
      village: payload.village,
      post_code: payload.post_code,
      phone: payload.phone,
      fax: payload.fax,
      owner_name: payload.owner_name,
      owner_address: payload.owner_address,
      sales_person: payload.sales_person,
      billing_contact: payload.billing_contact,
      email: payload.email,
      company_type: payload.company_type,
      ktp_no: payload.ktp_no,
      npwp_no: payload.npwp_no,
      stdud_no: payload.stdud_no,
      nib_no: payload.nib_no,
      note: payload.note,
      status: payload.status,
    };
    const response = await fetchAxios(
      `${environments.api?.mintra_management}${id ? `/${id}` : ""}`,
      `${id ? "put" : "post"}`,
      body,
    );
    if (response.status === 200) {
      if (!id) {
        formik.resetForm();
        history.push(MANAGEMENT_MITRA);
      }
      toast.success(response.data.message);
      history.push(MANAGEMENT_MITRA);
    }
  };

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <h3>{id ? "Update Mitra" : "Tambah Mitra"}</h3>
        </div>
        <Row>
          <FormsLeft
            formik={formik}
            optionsProvince={optionsProvince}
            optionsCity={optionsCity}
            optionsDistrict={optionsDistrict}
            optionsMitraGroup={optionsMitraGroup}
            startDate={startDate}
            setStartDate={setStartDate}
            details={details}
            labelMitraGroup={labelMitraGroup}
            listProvince={listProvince}
            listCity={listCity}
            listDistrict={listDistrict}
            listMitraGroup={listMitraGroup}
            setParamsCity={(value) =>
              setParamsCity({ ...paramsCity, province_id: value })
            }
            setParamsDistrict={(value) =>
              setParamsDistrict({ ...paramsDistrict, city_id: value })
            }
          />
          <FormsRight formik={formik} />
        </Row>
        <div className="d-flex">
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            Kembali
          </Button>{" "}
          <Button variant="success" type="submit">
            Simpan
          </Button>{" "}
        </div>
      </form>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi ${id ? "Merubah" : "Tambah"} Mitra`}
        message={`Apakah kamu yakin untuk ${
          id ? "merubah" : "menambahkan"
        } Mitra Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal22"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Fragment>
  );
};

export default AddManagemenMitra;
