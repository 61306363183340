/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

import DataTable from "react-data-table-component";
import environments from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import fetchAxios from "../../../helpers/fetchAxios";
import { listAllStatus } from "../../../utilities/constants";
import ColumTransactions from "../../components/Colums/ColumTransactions";
import Selects from "../../components/Selects";

const DetailTabels = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hitParams, setHitParams] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 10,
    status: "",
    key: "",
    code: "",
    mitra_id: id,
  });
  useEffect(() => {
    fetchTransaction();
  }, [hitParams, id]);

  const fetchTransaction = async () => {
    // setHitParams({ ...hitParams, mitra_id: allData?.id });
    const response = await fetchAxios(
      environments.api.transaction +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setData(response.data?.data?.data);
      setDataTable(response.data);
      setIsLoading(false);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setHitParams({
      ...hitParams,
      page: page,
      per_page: newPerPage,
    });
  };
  const handlePageChange = (page) => {
    setHitParams({
      ...hitParams,
      page: page,
    });
  };

  return (
    <Fragment>
      <Col className="my-3">
        <div className="d-flex justify-content-between align-items-center">
          <Col>
            <Form.Label>Riwayat Transaksi (4)</Form.Label>
          </Col>
          <Col sm={3}>
            <Selects
              label=""
              options={listAllStatus}
              placeholder="Semua Status"
              onChange={(e) => {
                setHitParams({
                  ...hitParams,
                  status: e ? e.value : "",
                });
              }}
              isClearable={true}
            />
          </Col>
        </div>
      </Col>
      <Col className="my-1">
        <DataTable
          columns={ColumTransactions()}
          data={data}
          noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
          pagination
          paginationServer
          paginationTotalRows={dataTable?.data?.total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressPending={isLoading}
        />
      </Col>
    </Fragment>
  );
};

export default DetailTabels;
