import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { imageError } from "../../../../helpers/brokenImg";
import {
  ColumProductItems,
  ColumProductLimit,
  ColumProductPrice,
} from "../../../components/Colums/ColumProduct";
import "./index.scss";

const Left = (props) => {
  const { allData, html } = props;

  return (
    <Col sm={8}>
      <div>
        <p className="title_custome">Nama</p>
        <p>{allData?.name ? allData?.name : "-"}</p>
      </div>
      <div>
        <p className="title_custome">Deskripsi</p>
        <div dangerouslySetInnerHTML={html} />
      </div>
      <div>
        <p className="title_custome">Galery</p>
        <Row xs={3} md={4} lg={5}>
          {allData?.galleries?.map((path) => (
            <img
              src={path}
              className="img-thumbnail mx-2"
              onError={(e) => imageError(e)}
              width={250}
              style={{ height: "160px" }}
              alt="gallery"
            />
          ))}
        </Row>
      </div>
      <div>
        <p className="title_custome">Produk</p>
        <DataTable
          columns={ColumProductItems(props)}
          data={allData?.items}
          noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
          responsive
        />
      </div>
      <div className="py-3">
        <p className="title_custome">Harga Bertingkat</p>
        <DataTable
          columns={ColumProductPrice(props)}
          data={allData?.product_prices}
          noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
          responsive
        />
      </div>
      <div className="py-3">
        <p className="title_custome">Limit Pembelian</p>
        <DataTable
          columns={ColumProductLimit(props)}
          data={allData?.product_limits}
          noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
          responsive
        />
      </div>
    </Col>
  );
};

export default Left;
