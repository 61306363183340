import React from 'react';
import { Route, Redirect } from 'react-router';
import Cookies from 'js-cookie';

import AdminLayout from './App/layout/AdminLayout';
import * as ROUTEPATH from './routePath';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = Cookies.get('tokenY');
    return (
        <Route
          { ...rest }
          render={ props => {
            return token ? (
              <Route path="/" component={AdminLayout} />
            ) : (
              <Redirect
                to={ {
                  pathname: ROUTEPATH.SIGNIN,
                } }
              />
            );
          } }
        />
      );
}

export default PrivateRoute