import React from "react";
import { Button } from "react-bootstrap";

const ColumManagementStock = (props) => {
  return [
    {
      name: "Nama",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Kategori",
      sortable: true,
      selector: (row) => row.product_category?.name,
    },
    {
      name: "Tipe",
      sortable: true,
      selector: (row) => row.type,
    },
    {
      name: "Harga",
      sortable: true,
      selector: (row) => row.price.toLocaleString("id"),
    },
    {
      name: "Stok",
      sortable: true,
      selector: (row) => row.stock,
    },

    {
      name: "Aksi",
      width: "300px",
      sortable: true,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-success"
                  size="sm"
                  onClick={() => props.showUpdateStock(row)}
                >
                  Ubah Stok
                </Button>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export default ColumManagementStock;
