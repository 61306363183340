/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import environments from '../../../../environments';
import { encodeQueryData } from '../../../../helpers/endcodeQuery';
import fetchAxios from '../../../../helpers/fetchAxios';
import { listAllStatus } from '../../../../utilities/constants';
import { ColumReportSale } from '../../../components/Colums/ColumReport';
import DatePickers from '../../../components/DatePickers';
import Selects from '../../../components/Selects';

function ReportSale() {
    const [data, setData] = useState([]);
    const [dataTable, setDataTable] = useState();
    const [listMitra, setListMitra] = useState();
    const [listAllProduct, setListAllProduct] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isLoadingMitra, setIsLoadingMitra] = useState(true);
    const [downloadExcel, setDownloadExcel] = useState('');
    const [totalSales, setTotalSales] = useState('');
    const [hitParams, setHitParams] = useState({
        orderby: "",
        sort: "desc",
        page: 1,
        per_page: 10,
        status: "",
        mitra_id: "",
        product_id: "",
        start_date: "",
        end_date: ""
    })

    const formik = useFormik({
        initialValues: {
            status: "",
            mitra: "",
            product_id: "",
            start_date: "",
            end_date: "",
        },
        onSubmit: (values)=>{
            setHitParams({
                ...hitParams,
                orderby: "id",
                sort: "desc",
                page: 1,
                per_page: 10,
                status: values.status,
                mitra_id: values.mitra,
                product_id: values.product_id,
                start_date: values.start_date ?  dayjs(values.start_date).format("YYYY-MM-DD") : "",
                end_date: values.end_date ?  dayjs(values.end_date).format("YYYY-MM-DD") : "",
            })
        }
    })

    useEffect(() => {
        fetchSale()
    },[hitParams])

    const fetchSale = async () => {
        const response = await fetchAxios(
            `${environments.api.transaction}/report` + (Object.keys(hitParams).length > 0 ? "?" + encodeQueryData(hitParams) : ""),
            'get'
        )
        setData(response.data?.data?.rows?.data)
        setDataTable(response.data?.data?.rows)
        setDownloadExcel(response.data?.data?.export_link)
        setTotalSales(response.data?.data?.total_sales)
    }

    useEffect(() => {
        fetchMitra()
    },[])

    useEffect(() => {
        fetchProduk()
    },[])

    const fetchProduk = async () => {
        let paramsMitra ={
            orderby: "",
            sort: "desc",
            page: 1,
            per_page: 999,
            status: "aktif",
            key: "",
            mitra_group_id: "",
            type: ""
        }
        const response = await fetchAxios(
            environments.api.product + (Object.keys(paramsMitra).length > 0 ? "?" + encodeQueryData(paramsMitra) : ""),
            'get'
        )
        if (response.status === 200) {
            setListAllProduct(response.data?.data?.data)
        }
    }

    const fetchMitra = async () => {
        let paramsMitra ={
            orderby: "",
            sort: "desc",
            page: 1,
            per_page: 999,
            status: "",
            key: "",
            mitra_group_id: ""
        }
        const response = await fetchAxios(
            environments.api.mintra_management + (Object.keys(paramsMitra).length > 0 ? "?" + encodeQueryData(paramsMitra) : ""),
            'get'
        )
        if (response.status === 200) {
            setListMitra(response.data?.data?.data)
            setIsLoadingMitra(false)
        }
    }

    const optionsMitra = listMitra?.map((val) => {
        return {
            value: val.id,
            label: val.name,
        }
    })

    const optionsProduct = listAllProduct?.map((val) => {
        return {
            value: val.id,
            label: val.name,
        }
    })

    const handlePerRowsChange = async (newPerPage, page) => {
        setHitParams({
            ...hitParams,
            page: page,
            per_page: newPerPage,
        })
	};

    const handlePageChange = page => {
        setHitParams({
            ...hitParams,
            page: page,
        })
	};
    
  return (
    <Fragment>
        <form onSubmit={formik.handleSubmit}>
            <Row>
                <Col sm={4}>
                    <Selects
                        label=""
                        name="product_id"
                        options={optionsProduct}
                        placeholder="Semua Produk"
                        onChange={(e) => { 
                            formik.setFieldValue('product_id',e ? e.value : "")
                        }}
                        isClearable={true}
                        isLoading={isLoadingMitra}
                    />
                </Col>
                <Col sm={4}>
                    <Selects
                        label=""
                        name="mitra"
                        options={optionsMitra}
                        placeholder="Semua Mitra"
                        onChange={(e) => { 
                            formik.setFieldValue('mitra',e ? e.value : "")
                        }}
                        isClearable={true}
                        isLoading={isLoadingMitra}
                    />
                </Col>
                <Col sm={4}>
                    <Selects
                        label=""
                        name="status"
                        options={listAllStatus}
                        placeholder="Semua Status"
                        onChange={(e) => { 
                            formik.setFieldValue('status',e ? e.value : "")
                        }}
                        isClearable={true}
                    />
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <DatePickers
                        label=""
                        name="start_date"
                        dateFormat="d MMMM yyyy"
                        selected={startDate}
                        placeholderText="Tanggal Awal"
                        onChange={(e) => {
                            setStartDate(e)
                            formik.setFieldValue('start_date',e ? e : "")
                        }}
                    />
                </Col>
                <Col>
                    <DatePickers
                        label=""
                        name="end_date"
                        dateFormat="d MMMM yyyy"
                        selected={endDate}
                        placeholderText="Tanggal Akhir"
                        onChange={(e) => {
                            setEndDate(e)
                            formik.setFieldValue('end_date',e ? e : "")
                        }}
                    />
                </Col>
                <Col>
                    <Button variant="primary" type="submit">Cari Data</Button>
                    <Button variant="success" href={downloadExcel}>Download Excel</Button>
                </Col>
            </Row>
        </form>
        <DataTable
            columns={ColumReportSale()}
            data={data}
            noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
            pagination
            paginationServer
            paginationTotalRows={dataTable?.total}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={[10,25,50,100,250]}
        />
        <div className='mt-2'>
            <h6>Total Penjualan : {Number(totalSales).toLocaleString("id")}</h6>
        </div>
    </Fragment>
  )
}

export default ReportSale