import React from 'react';
import { Form, FormGroup } from 'react-bootstrap';

function Inputs(props) {
    const {
        formik,
        name,
        placeholder,
        type,
        values,
        label,
        id,
        toucheds,
        errors,
        isCostumeValidate,
        isFormatRp,
        maxLength,
        disabled,
        isChangeCustome,
        onChange,
        isOnBlurCustome,
        onBlur,
    } = props;
    
  return (
    <FormGroup
      className={
        isCostumeValidate 
        ?
          toucheds && errors ? "form-validation error" : ""
        : 
        formik.touched[name] && formik.errors[name] ? "form-validation error" : ""
      }
      id={ id }
      data-label={ name }  
    >
         {label && (<Form.Label>{label}</Form.Label>) }
        <div className={isFormatRp ? 'input-group-merge input-group' : '' }>
          {
            isFormatRp && 
            <div className="input-group-prepend">
              <span className="input-group-text" id="min">
                Rp
              </span>
            </div>
          }
          {
            type === 'textarea' ? (
              <textarea 
                className="custome-input" 
                name={name}
                value={values}
                disabled={disabled}
                placeholder={ placeholder }
                onChange={isChangeCustome ? onChange : formik.handleChange}
                onBlur={isOnBlurCustome ? onBlur : formik.handleBlur}
              />                
            ) : (
              <input
                className='custome-input'
                type={ type }
                name={ name }
                id={ id }
                onChange={isChangeCustome ? onChange : formik.handleChange}
                onBlur={isOnBlurCustome ? onBlur : formik.handleBlur}
                value={values}
                placeholder={ placeholder }
                maxLength={maxLength}
                disabled={disabled}
              />
            )
          }
        </div>
        {
        isCostumeValidate 
        ?
          toucheds && errors ? (
            <small className="text-danger">
              {errors}
            </small>
          ) : null
        : 
        formik.touched[name] && formik.errors[name] ? (
          <small className="text-danger">
            {formik.errors[name]}
          </small>
        ) : null
        }
    </FormGroup>
  )
}

export default Inputs;