import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const Selects = (props) => {
    const {
        label,
        name,
        options,
        placeholder,
        isDisabled,
        onChange,
        isOptionDisabled,
        selected,
        formik,
        value,
        isCostumeValidate,
        toucheds,
        errors,
        isClearable,
        isLoading,
        isMulti
    } = props;
  return (
    <Fragment>
        {label && (<Form.Label>{label}</Form.Label>) }
        <Select
            name={name}
            options={options}
            placeholder={placeholder}
            components={ {
                IndicatorSeparator: () => null,
            } }
            isDisabled={isDisabled}
            isOptionDisabled={isOptionDisabled}
            onChange={onChange}
            isMulti={isMulti}
            defaultValue={selected}
            value={value}
            styles={{
                control: styles => ({
                    ...styles,
                    backgroundColor: isDisabled ? '#D0D0D0' : '#fff',
                    padding: "3px 0px"
                }),
            }}
            isClearable={isClearable}
            isLoading={isLoading}
        />
        { 
        isCostumeValidate 
            ?
            toucheds && errors ? (
                <small className="text-danger">
                {errors}
                </small>
            ) : null
            : 
            formik?.touched[name] && formik?.errors[name] ? (
                <div style={ { color: 'red' } }>{ formik.errors[name] }</div>
            ) : null 
        }
    </Fragment>
  )
}

export default Selects