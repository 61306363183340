import dayjs from "dayjs";

const ColumStock = (props) => {
  return [
    {
      name: "Nama",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Tipe Produk",
      sortable: true,
      selector: (row) => row.type,
    },
    {
      name: "Kategori",
      sortable: true,
      selector: (row) => row.product_category?.name,
    },
    {
      name: "Harga",
      sortable: true,
      selector: (row) => Number(row.price)?.toLocaleString("id"),
    },
    {
      name: "Stok",
      sortable: true,
      selector: (row) => row.stock,
    },
    {
      name: "SKU",
      sortable: true,
      selector: (row) => row.sku,
    },
    {
      name: "Berat",
      sortable: true,
      selector: (row) => row.weight,
    },
    {
      name: "Jenis Persediaan",
      sortable: true,
      selector: (row) => row.persedian_type,
    },
    {
      name: "Jenis BKP",
      sortable: true,
      selector: (row) => row.bkp_type,
    },
  ];
};

const ColumReportSale = (props) => {
  return [
    {
      name: "No",
      sortable: true,
      selector: (row) => row.code,
    },
    {
      name: "Tanggal",
      sortable: true,
      selector: (row) => dayjs(row.transaction_date).format("DD MMMM YYYY"),
    },
    {
      name: "Mitra",
      sortable: true,
      selector: (row) => row.mitra_name,
    },
    {
      name: "Produk",
      sortable: true,
      selector: (row) => row.product_name,
    },
    {
      name: "Qty",
      sortable: true,
      selector: (row) => row.qty,
    },
    {
      name: "Total",
      sortable: true,
      selector: (row) => Number(row.subtotal)?.toLocaleString("id"),
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
    },
  ];
};

export { ColumStock, ColumReportSale };
