import React, { Fragment } from "react";
import { Col, Row, Card } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePickers from "../../components/DatePickers";

export default function Chart(props) {
  const {
    dataChartTransaction,
    month,
    // years,
    setMonth,
    // setYears,
    summery,
    setParamsSummeryTransaction,
  } = props;

  const data = summery.map((item) => {
    let years = `${item.year}-${item.month}-${item.day}`;
    return {
      year: years,
      uv: item.total,
    };
  });

  return (
    <Fragment>
      <Row className="my-5">
        <Col sm={6}>
          <DatePickers
            label=""
            name="registration_date"
            dateFormat="MMMM yyyy"
            selected={month}
            placeholderText="Semua Bulan"
            // showMonthYearPicker={true}
            showMonthYearPicker={true}
            onChange={(e) => {
              console.log("e :>> ", e);
              setMonth(e);
            }}
          />
        </Col>
      </Row>
      <Card className="p-2">
        <ResponsiveContainer width="100%" height="100%" aspect={3}>
          <BarChart
            width={500}
            height={50}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" label="tes" /> */}
            <Bar dataKey="uv" fill="#82ca9d" name="Total transaksi" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </Fragment>
  );
}
