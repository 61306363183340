import React, { Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

function ComponentStatus(props) {
    const {
        formik
    } = props
  return (
    <Fragment>
        <Row>
            <Col>
                <p>Status</p>
                <Form.Group>
                    <div className='d-flex'>
                        <Form.Check
                            inline
                            custom
                            type="radio"
                            label="Aktif"
                            name="status"
                            id="active"
                            value='aktif'
                            onChange={(e) => {
                                formik.setFieldValue('status',e.target.value)
                            }}
                            checked={formik.values.status === "aktif" ? true : false }
                        />
                        <Form.Check
                            inline
                            custom
                            type="radio"
                            label="Tidak Aktif"
                            name="status"
                            value="tidak aktif"
                            id="notActive"
                            onChange={(e) => {
                                formik.setFieldValue('status',e.target.value)
                            }}
                            checked={formik.values.status === "tidak aktif" ? true : false }
                        />
                    </div>
                </Form.Group>
                {
                    formik.errors.status && formik.touched.status &&
                    (<p style={{ color: 'red', fontSize:'11px' }}>{ formik.errors.status }</p>)
                }
            </Col>
        </Row>
    </Fragment>
  )
}

export default ComponentStatus