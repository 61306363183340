import * as Yup from 'yup';

const addFAQ = Yup.object({
    question: Yup.string().required('Silahkan isi Pertanyaan'),
    answare: Yup.string().required('Silahkan isi Jawaban'),
    status: Yup.string().required('Silahkan isi status'),
});

const addPromo = Yup.object({
    title: Yup.string().required('Silahkan isi Judul'),
    desc: Yup.string().required('Silahkan isi Deskripsi'),
    status: Yup.string().required('Silahkan isi status'),
});

const banner = Yup.object({
    photo: Yup.string().required('Silahkan pilih photo'),
    title: Yup.string().required('Silahkan isi nama'),
    link: Yup.string().required('Silahkan isi link'),
    desc: Yup.string().required('Silahkan isi Deskripsi'),
    status: Yup.string().required('Silahkan isi status'),
    type: Yup.string().required('Silahkan isi tipe'),
});

const page = Yup.object({
    photo: Yup.string().required('Silahkan pilih photo'),
    title: Yup.string().required('Silahkan isi nama'),
    desc: Yup.string().required('Silahkan isi Deskripsi'),
    status: Yup.string().required('Silahkan isi status'),
});

export { addFAQ, addPromo, banner, page };