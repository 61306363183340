/* eslint-disable jsx-a11y/alt-text */
import dayjs from 'dayjs'
import React from 'react'
import { Col } from 'react-bootstrap'
import { imageError } from '../../../../helpers/brokenImg'
import './index.scss'

const Right = (props) => {
    const {
        allData
    } = props
    
  return (
    <Col sm={4}>
        <div className='d-flex justify-content-center mb-2'>
            <img 
                src={allData?.thumbnail} 
                className="img-thumbnail mx-2" 
                onError={(e) => imageError(e)}
                width={100} 
                style={{ height: "100px" }} 
                alt={allData?.name}
            />
        </div>
        <div>
            <p className='title_custome'>Kategori</p>
            <p>{allData?.product_category ? allData?.product_category?.name : "-"}</p>
        </div>
        <div>
            <p className='title_custome'>SKU</p>
            <p>{allData?.sku ? allData?.sku : "-"}</p>
        </div>
        <div>
            <p className='title_custome'>Harga</p>
            <p>{allData?.price ? allData?.price?.toLocaleString("id") : "-"}</p>
        </div>
        <div>
            <p className='title_custome'>Diskon</p>
            <p>{allData?.discount_value ? allData?.discount_value + "%" : "-"}</p>
        </div>
        <div className='d-flex'>
            <div className='mr-2'>
                <p className='title_custome'>Stok</p>
                <p>{allData?.stock ? allData?.stock : "-"}</p>
            </div>
            <div className='mx-2'>
                <p className='title_custome'>Terjual</p>
                <p>{allData?.is_sale_without_stock ? allData?.is_sale_without_stock : "-"}</p>
            </div>
        </div>
        <div className='d-flex'>
            <div className='mr-2'>
                <p className='title_custome'>Berat</p>
                <p>{allData?.weight ? allData?.weight : "-"}</p> 
            </div>
            <div className='mr-2'>
                <p className='title_custome'>Satuan</p>
                <p>{allData?.weight ? allData?.satuan?.name : "-"}</p> 
            </div>
        </div>
        {
            allData?.is_sale_without_stock && (
                <div>
                    <p className='title_custome'>Produk Dapat Dijual Tanpa Stok</p>
                </div>
            )
        }
        <div>
            <p className='title_custome'>Dimensi(cm2)</p>
            <spa>{allData?.width ? allData?.width : "-"}</spa>
            <spa> x </spa>
            <spa>{allData?.weight ? allData?.weight : "-"}</spa>
            <spa> x </spa>
            <spa>{allData?.height ? allData?.height : "-"}</spa>
        </div>
        <div>
            <p className='title_custome'>Tampilkan Produk ke</p>
            <p>{allData?.mitra_group ? allData?.mitra_group?.name : "-"}</p>
        </div>
        <div>
            <p className='title_custome'>Tanggal Tampil</p>
            <p>{allData?.publish_at ? dayjs(allData?.publish_at).format("DD MMMM YYYY")  : "-"}</p>
        </div>
        <div>
            <p className='title_custome'>Jenis Persedian Produk</p>
            <p>{allData?.persedian_type ? allData?.persedian_type : "-"}</p>
        </div>
        <div>
            <p className='title_custome'>Jenis BKP</p>
            <spa>{allData?.bkp_type ? allData?.bkp_type : "-"}</spa>
        </div>
        <div>
            <p className='title_custome'>Status</p>
            <spa>{allData?.status ? allData?.status : "-"}</spa>
        </div>
    </Col>
  )
}

export default Right