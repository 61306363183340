/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Col } from 'react-bootstrap';


import DetailInformations from './detailInformations';
import DetailTabels from './detailTabels';
import DetailUpdatePopup from './detailUpdatePopup';
import environments from '../../../environments';
import fetchAxios from '../../../helpers/fetchAxios';
import * as ROUTEPATH from '../../../routePath';

const DetailsManagementPlafond = () => {
    const [show, setShow] = useState(false);
    const [allData, setAllData] = useState();
    const { id } = useParams();
    useEffect(() => {
        fetchDetailPlafond()
    }, [ id, show ])

    const fetchDetailPlafond = async() => {
        const response = await fetchAxios(
            `${environments.api.mintra_management}/${id}`,
            "get"
        )
        if (response.status === 200) {
            setAllData(response.data?.data)
        }
    }    
  return (
    <Fragment>
        <div className='d-flex mb-5 align-items-center'>
            <div className='pl-1 pr-3'>
                <h3 style={{ color: '#5E5873' }}>Lihat Mitra</h3>
            </div>
            <div>
                <h3><span className='badge bg-secondary'>{allData?.code}</span></h3>
            </div>
            <div className='pl-1'> 
                <p className='pt-2' style={{ color: 'green' }}>{allData?.status}</p>
            </div>
        </div>
        <DetailInformations allData={allData} />
        <DetailTabels allData={allData} />
        <Col className='my-3'>
            <div className='d-flex'>
                <div>
                    <Button variant="outline-secondary" onClick={() => window.location.href = `/cms${ROUTEPATH.MANAGEMENT_PLAFOND}` } >Kembali</Button>{' '}
                </div>
                <div>
                    <Button variant="success" onClick={() => setShow(!show)}>Ubah Plafond & Tempo</Button>{' '}
                </div>
            </div>
        </Col>
        <DetailUpdatePopup
            show={show}
            handleClose={() => setShow(false)}
            allData={allData}
        />
    </Fragment>
  )
}

export default DetailsManagementPlafond