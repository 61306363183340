import Cookies from 'js-cookie';
import Axios from "axios";
import { toast } from 'react-toastify';

import * as ROUTEPATH from '../routePath';


const fetchAxios = async (url, method, body, isUpload) => {
    const base64 = Cookies.get('tokenY');
    const tokenBase64 = base64 !== undefined ? atob(base64) : '';

    if (tokenBase64 !== null) {
        Axios.defaults.headers.common["Authorization"] =`Bearer ${tokenBase64}` 
    }

    try{
        const response = await Axios({method: method, url: url, data: body })
        if (response.status <= 299) {
            return Promise.resolve(response);
        } else if(response.status === 400) {
            toast.error(response.message);
            window.location.href = '/404';
            return Promise.resolve(response);
        } else if(response.status === 500) {
            toast.error(response.message);  
        } else if (response.status === 404) {
            toast.error(response.message)
        } else if (response.status === 401) {
            toast.error(response.message);
            Cookies.remove('tokenY');
            Cookies.remove('userObj');
            window.location.href = ROUTEPATH.SIGNIN;
        } else {
            toast.error(response.message);
        }

    } catch(err) {
        const error = err.toJSON()
        if (error.status === 422) {
            toast.error(err.response?.data?.message)
            return Promise.resolve(error);
        } else if (error.status === 401) {
            toast.error(error.message)
            Cookies.remove('tokenY');
            Cookies.remove('userObj');
            window.location.href = ROUTEPATH.SIGNIN;
        } else if (error.status === 401) {
            toast.error(error.message)
            return Promise.resolve(error);
        }
        return Promise.resolve(error)
    }



}

export default fetchAxios;