import * as Yup from "yup";

const role = Yup.object({
  name: Yup.string().required("Silahkan isi nama"),
  status: Yup.string().required("Silahkan pilih status"),
  // menus: Yup.string().required('Silahkan upload image'),
});

const admin = Yup.object({
  full_name: Yup.string().required("Silahkan isi nama"),
  email: Yup.string().email().required("Silahkan isi status"),
  role_id: Yup.string().required("Silahkan pilih Role"),
  // password: Yup.string().required('Silahkan isi password'),
  status: Yup.string().required("Silahkan pilih status"),
  profile_picture: Yup.string().required("Silahkan pilih photo"),
});

export { role, admin };
