import * as Yup from "yup";

const detailFaktur = Yup.object({
  no_faktur: Yup.string().required("Silahkan isi Nomor Faktur"),
  tgl_faktur: Yup.string().required("Silahkan isi Tanggal Faktur"),
});

const sendNotif = Yup.object({
  title: Yup.string().required("Silahkan isi nama"),
  message: Yup.string().required("Silahkan isi status"),
});

const cancel = Yup.object({
  message: Yup.string().required("Silahkan isi Alasan Pembatalan"),
});

const processOrder = Yup.object({
  no_so: Yup.string().required("Silahkan isi Nomer Sales Order"),
});

const confirm = Yup.object({
  send_method: Yup.string().required("Silahkan pilih Metode Pengiriman"),
  send_date: Yup.string().required("Silahkan pilih Tanggal Pengiriman"),
  shipping_cost: Yup.string().required("Silahkan isi Ongkos kirim"),
  // note: Yup.string().required('Silahkan isi note'),
});

const sendOrder = Yup.object({
  shipping_method_id: Yup.string().required("Silahkan pilih metode pengiriman"),
  shipping_cost: Yup.string().required("Silahkan isi ongkos kirim"),
  no_surat: Yup.string().required("Silahkan isi surat jalan"),
  expedition: Yup.string().required("Silahkan isi ekpedisi"),
  driver_name: Yup.string().required("Silahkan isi nama driver"),
  driver_phone: Yup.string().required("Silahkan isi nomer telepon"),
  shipping_date: Yup.string().required("Silahkan pilih Tanggal"),
});

export { sendNotif, sendOrder, cancel, confirm, processOrder, detailFaktur };
