import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";
import { ValidateTransactions } from "../../../validate";
import DatePickers from "../../components/DatePickers";
import Inputs from "../../components/Inputs";
import SweetAlert from "../../components/SweetAlert";

function DetailFaktur(props) {
  const { show, handleClose, id } = props;
  let initialPayload = {
    no_faktur: "",
    tgl_faktur: "",
  };
  const [isConfirm, setIsConfirm] = useState(false);
  const [date, setDate] = useState(null);
  const [payload, setPayload] = useState(initialPayload);

  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateTransactions.detailFaktur,
    onSubmit: async (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });

  const onConfirm = async () => {
    const body = {
      transaction_id: id,
      no_faktur: payload.no_faktur,
      tgl_faktur: payload.tgl_faktur,
    };
    const response = await fetchAxios(
      `${environments.api.transaction}/update-faktur`,
      "put",
      body,
    );
    if (response.status === 200) {
      formik.resetForm();
      toast.success(response.data.message);
      setIsConfirm(false);
      handleClose();
    } else {
      setIsConfirm(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Detail Faktur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Inputs
              formik={formik}
              label="No Faktur"
              type="text"
              id="no_faktur"
              name="no_faktur"
              values={formik.values.no_faktur}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="mt-2">
              <DatePickers
                formik={formik}
                label="Tanggal Faktur"
                name="tgl_faktur"
                dateFormat="d MMMM yyyy"
                selected={date}
                placeholderText="Pilih Tanggal"
                onChange={(e) => {
                  setDate(e);
                  formik.setFieldValue(
                    "tgl_faktur",
                    dayjs(e).format("YYYY-MM-DD"),
                  );
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kembali
          </Button>
          <Button variant="success" type="submit">
            Kirim
          </Button>
        </Modal.Footer>
      </form>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={`Konfirmasi Update Faktur`}
        message={`Apakah kamu yakin untuk ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal22"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Modal>
  );
}

export default DetailFaktur;
