import * as ROUTEPATH from "./routePath";

export default {
  items: [
    // {
    //     id: 'navigation',
    //     title: 'Dashboard',
    //     type: 'group',
    //     icon: 'icon-navigation',
    //     children: [
    //         {
    //             id: 'dashboard',
    //             title: 'Dashboard',
    //             type: 'item',
    //             url: ROUTEPATH.DASHBOARD,
    //             icon: 'feather icon-home',
    //         }
    //     ]
    // },
    {
      id: "navigation",
      title: "Page",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: ROUTEPATH.DASHBOARD,
          icon: "feather icon-home",
        },
        {
          id: "managementTransaction",
          title: "Manajemen Transaksi",
          type: "item",
          url: ROUTEPATH.MANAGEMENT_TRANSACTION,
          icon: "feather icon-file-text",
        },
        {
          id: "managementPlafond",
          title: "Manajemen Plafond",
          type: "item",
          url: ROUTEPATH.MANAGEMENT_PLAFOND,
          icon: "feather icon-file-text",
        },
        {
          id: "managementMitra",
          title: "Manajemen Mitra",
          type: "item",
          url: ROUTEPATH.MANAGEMENT_MITRA,
          icon: "feather icon-file-text",
        },
        {
          id: "setting",
          title: "Pengaturan",
          type: "item",
          url: ROUTEPATH.SETTING,
          icon: "feather icon-settings",
        },
        {
          id: "media",
          title: "Media",
          type: "item",
          url: ROUTEPATH.MEDIA,
          icon: "feather icon-briefcase",
        },
        {
          id: "management_product",
          title: "Manajemen Produk",
          type: "collapse",
          icon: "feather icon-briefcase",
          children: [
            {
              id: "product",
              title: "Produk",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT,
            },
            {
              id: "product_paket",
              title: "Produk Paket",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_PRODUCT_PAKET,
            },
            {
              id: "product_unggulan",
              title: "Produk Unggulan",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_UNGGULAN,
            },
            {
              id: "product_management_stok",
              title: "Manajemen Stok",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_PRODUCT_PRODUCT_MANAGEMENT_STOCK,
            },
          ],
        },
        {
          id: "master_data",
          title: "Master Data",
          type: "collapse",
          icon: "feather icon-box",
          children: [
            {
              id: "group_mitra",
              title: "Jenis Pelanggan",
              type: "item",
              url: ROUTEPATH.MASTER_DATA_GROUP_MITRA,
            },
            {
              id: "category_product",
              title: "Produk Kategori",
              type: "item",
              url: ROUTEPATH.MASTER_DATA_CATEGORY_PRODUCT,
            },
            {
              id: "shipping_method",
              title: "Metode Pengiriman",
              type: "item",
              url: ROUTEPATH.SHIPPING_METHOD,
            },
            {
              id: "satuan",
              title: "Satuan",
              type: "item",
              url: ROUTEPATH.MASTER_DATA_SATUAN,
            },
          ],
        },
        {
          id: "management_admin",
          title: "Manajemen Admin",
          type: "collapse",
          icon: "feather icon-users",
          children: [
            {
              id: "role",
              title: "Role",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_ROLE,
            },
            {
              id: "admin",
              title: "Admin",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_ADMIN,
            },
          ],
        },
        {
          id: "management_content",
          title: "Konten Manajemen",
          type: "collapse",
          icon: "feather icon-message-circle",
          children: [
            {
              id: "faq",
              title: "FAQ",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_CONTENT_FAQ,
            },
            {
              id: "promo",
              title: "Promo",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_CONTENT_PROMO,
            },
            {
              id: "banner",
              title: "Banner",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_CONTENT_BANNER,
            },
            {
              id: "page",
              title: "Page",
              type: "item",
              url: ROUTEPATH.MANAGEMENT_CONTENT_PAGE,
            },
          ],
        },
        {
          id: "report",
          title: "Laporan",
          type: "collapse",
          icon: "feather icon-file-text",
          children: [
            {
              id: "report_stock",
              title: "Stok",
              type: "item",
              url: ROUTEPATH.REPORT_STOCK,
            },
            {
              id: "report_sale",
              title: "Penjualan",
              type: "item",
              url: ROUTEPATH.REPORT_SALE,
            },
          ],
        },
      ],
    },
  ],
};
