/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import environments from "../../../../environments";
import { encodeQueryData } from "../../../../helpers/endcodeQuery";
import fetchAxios from "../../../../helpers/fetchAxios";
import ColumProduct from "../../../components/Colums/ColumProduct";
import Selects from "../../../components/Selects";
import SweetAlert from "../../../components/SweetAlert";

function AddProductUnggulan(props) {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [selectedRows, setSelectedRows] = useState(false);
  const [isConfim, setIsConfim] = useState();
  const searchInput = useRef();
  const [hitParams, setHitParams] = useState({
    is_featured: false,
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 10,
    status: "",
    key: "",
    product_category_id: "",
    type: "",
  });
  const { show, handleClose, formik, optionsProductCategory } = props;

  props = {
    type: "view",
  };

  useEffect(() => {
    fetchProduct();
  }, [show, hitParams]);

  const fetchProduct = async () => {
    const response = await fetchAxios(
      environments.api.product +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    setData(response.data?.data?.data);
    setDataTable(response.data);
  };

  const searchAction = debounce((e) => {
    setHitParams({
      ...hitParams,
      key: searchInput?.current.value,
    });
  }, 500);

  const handlePerRowsChange = async (newPerPage, page) => {
    setHitParams({
      ...hitParams,
      page: page,
      per_page: newPerPage,
    });
  };

  const handlePageChange = (page) => {
    setHitParams({
      ...hitParams,
      page: page,
    });
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleSave = () => {
    if (selectedRows.length > 0) {
      setIsConfim(true);
    }
  };

  const onConfirm = async () => {
    const body = {
      product_ids: selectedRows,
    };
    const response = await fetchAxios(
      `${environments.api.product}/featured`,
      "put",
      body
    );
    if (response.status === 200) {
      toast.success(response?.data?.message);
      setIsConfim(false);
      handleClose();
    } else {
      setIsConfim(false);
      handleClose();
    }
  };

  const handleBack = () => {
    setHitParams({
      is_featured: false,
      orderby: "",
      sort: "desc",
      page: 1,
      per_page: 10,
      status: "",
      key: "",
      product_category_id: "",
      type: "",
    });
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="d-flex justify-content-end">
        <div className="mx-4 my-2 close" onClick={handleClose}>
          X
        </div>
      </div>
      <Modal.Header>
        <Modal.Title>Pilih Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={3}>
            <Selects
              formik={formik}
              label=""
              name={`product_category_id`}
              options={optionsProductCategory}
              placeholder="Semua Kategori"
              onChange={(e) => {
                setHitParams({
                  ...hitParams,
                  product_category_id: e ? e.value : "",
                });
              }}
              isClearable={true}
            />
          </Col>
          <Col sm={3}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Pencarian"
                aria-label="search"
                aria-describedby="basic-addon1"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  searchAction(e);
                }}
                ref={searchInput}
                style={{ backgroundColor: "#fff" }}
              />
            </InputGroup>
          </Col>
        </Row>
        <DataTable
          columns={ColumProduct(props)}
          data={data}
          noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
          pagination
          paginationServer
          responsive
          selectableRows
          onSelectedRowsChange={handleRowSelected}
          paginationTotalRows={dataTable?.data?.total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleBack}>
          Kembali
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Simpan
        </Button>
      </Modal.Footer>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfim}
        title="Konfirmasi Update Produk"
        message={`Apakah kamu yakin untuk update Produk Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal"
        onCancel={() => {
          setIsConfim(false);
        }}
      />
    </Modal>
  );
}

export default AddProductUnggulan;
