import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";
import DatePickers from "../../components/DatePickers";

export default function ChartTopCostumer(props) {
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState(new Date());
  const [topCostumer, setTopCostumer] = useState([]);

  useEffect(() => {
    fetchTopCostumer();
  }, []);

  const fetchTopCostumer = async () => {
    const response = await fetchAxios(
      environments.api.dashboard + `/chart/topcustomer`,
      "get"
    );
    if (response.status === 200) {
      setTopCostumer(response.data?.data);
    }
  };

  const data = topCostumer.map((item) => {
    return {
      year: item.name,
      uv: item.total_transaction,
    };
  });

  return (
    <Fragment>
      {/* <Row className="my-5">
        <Col sm={6}>
          <DatePickers
            label=""
            name="registration_date"
            dateFormat="MMMM"
            selected={month}
            placeholderText="Semua Bulan"
            showMonthYearPicker={true}
            onChange={(e) => {
              setMonth(e);
            }}
          />
        </Col>
        <Col sm={6}>
          <DatePickers
            label=""
            name="registration_date"
            dateFormat="yyyy"
            selected={years}
            placeholderText="Semua Tahun"
            showYearPicker={true}
            onChange={(e) => {
              setYears(e);
            }}
          />
        </Col>
      </Row> */}
      <div className="my-5">
        <Card className="p-2">
          <ResponsiveContainer width="100%" height="100%" aspect={3}>
            <BarChart
              width={500}
              height={50}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Bar dataKey="pv" fill="#8884d8" /> */}
              <Bar dataKey="uv" fill="#82ca9d" name="Total transaksi" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </div>
    </Fragment>
  );
}
