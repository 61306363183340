import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import Loadable from 'react-loadable';
import Cookies from "js-cookie";
import * as ROUTEPATH from "../routePath";

import "../../node_modules/font-awesome/scss/font-awesome.scss";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import route from "../route";
import routes from "../routes";
import PrivateRoute from "../private";

// const AdminLayout = Loadable({
//     loader: () => import('./layout/AdminLayout'),
//     loading: Loader
// });

const App = () => {
  return (
    <Aux>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            {route.map((route, index) => {
              return (
                <NonAuthmiddleware
                  key={route.path}
                  exact={true}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
            {routes.map((route) => {
              return (
                <PrivateRoute
                  key={route.path}
                  exact={true}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
            {/* <Route path="/" component={AdminLayout} /> */}
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
};

const NonAuthmiddleware = (props) => {
  const token = Cookies.get("tokenY");
  const { component: Component } = props;

  return (
    <Route
      render={(props) => {
        return !token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: ROUTEPATH.DASHBOARD }} />
        );
      }}
    />
  );
};

export default App;
