import React, { Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Inputs from '../../../components/Inputs'
import Selects from '../../../components/Selects'

const ConfigLimit = (props) => {
    const {
        formik,
        optionsMitraGroup,
        details
    } = props

    const addRow = () => {
        let product_limits = formik.values.product_limits;
        product_limits.push({
            mitra_group_id: "",
            min_qty: "",
            max_qty: "",
        });
        formik.setFieldValue('moladinLegendTarget', product_limits);
    };
    const removeRow = (index) => {
        let product_limits = formik.values.product_limits;
        if (product_limits.length < 1) {
            return
        }
        product_limits.splice(index, 1);
        formik.setFieldValue('moladinLegendTarget', product_limits)
    };
    
  return (
    <Fragment>
        <div className='mt-2'>
            <p>Limit Pembelian</p>
            {
                formik.values.product_limits.map((value, index) => {
                    return (
                        <Row className='align-items-center mt-2'>
                            <Col sm={5}>
                                <Selects
                                    formik={formik}
                                    label="Jenis Pelanggan"
                                    name={`product_limits.${index}.mitra_group_id`}
                                    options={optionsMitraGroup}
                                    placeholder={details ? details?.product_limits[index]?.mitra_group?.name : "Jenis Pelanggan"}
                                    onChange={(e) => { 
                                        formik.setFieldValue(`product_limits.${index}.mitra_group_id`,e.value)
                                    }}
                                    isCostumeValidate
                                    errors={formik.errors.product_limits?.[index]?.mitra_group_id}
                                    toucheds={formik.touched.product_limits?.[index]?.mitra_group_id}
                                />
                            </Col>
                            <Col sm={2}>
                                <Inputs
                                    formik={ formik }
                                    label="Min Qty"
                                    type='number'
                                    id="min_qty"
                                    name={`product_limits.${index}.min_qty`}
                                    values={formik.values.product_limits?.[index]?.min_qty}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isCostumeValidate
                                    errors={formik.errors.product_limits?.[index]?.min_qty}
                                    toucheds={formik.touched.product_limits?.[index]?.min_qty}
                                />
                            </Col>
                            <Col sm={2}>
                                <Inputs
                                    formik={ formik }
                                    label="Max Qty"
                                    type='number'
                                    id="max_qty"
                                    name={`product_limits.${index}.max_qty`}
                                    values={formik.values.product_limits?.[index]?.max_qty}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isCostumeValidate
                                    errors={formik.errors.product_limits?.[index]?.max_qty}
                                    toucheds={formik.touched.product_limits?.[index]?.max_qty}
                                />
                            </Col>
                            <Col sm={2}>
                                {
                                    formik.values.product_limits.length > 1 && (
                                        <div 
                                            className='mt-4'
                                            style={{ cursor: "pointer" }}
                                        >
                                            <i 
                                                className="feather icon-trash" 
                                                style={{ fontSize: "20px" }} 
                                                onClick={() => removeRow(index)}/>
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                    )
                })
            }
        </div>
        <Row className='mt-2'>
            <Col>
                <Button variant="primary" onClick={addRow}>+ Tambah Limit</Button>{' '}
            </Col>
        </Row>
    </Fragment>
  )
}

export default ConfigLimit