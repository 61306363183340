/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import environments from "../../../../environments";
import { encodeQueryData } from "../../../../helpers/endcodeQuery";
import fetchAxios from "../../../../helpers/fetchAxios";
import * as ROUTEPATH from "../../../../routePath";
import SweetAlert from "../../../components/SweetAlert";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { ColumManagementContentPage } from "../../../components/Colums/ColumManagementContent";

const ManagementContentPage = (props) => {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [isConfirmDeleted, setIsConfirmDeleted] = useState(false);
  const [idDeleted, setIdDeleted] = useState("");
  const [keyword, setKeyword] = useState("");
  const searchInput = useRef();
  const [hitParams, setHitParams] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 10,
    status: "",
    key: "",
    type: "page",
  });
  useEffect(() => {
    fetchManagementContent();
  }, [hitParams, isConfirmDeleted]);
  const showAlertDeleted = (id) => {
    setIdDeleted(id);
    setIsConfirmDeleted(true);
  };
  props = {
    showAlertDeleted,
  };

  const fetchManagementContent = async () => {
    const response = await fetchAxios(
      environments.api.management_content +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );
    if (response.status === 200) {
      setData(response.data?.data?.data);
      setDataTable(response.data);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setHitParams({
      ...hitParams,
      page: page,
      per_page: newPerPage,
    });
  };
  const handlePageChange = (page) => {
    setHitParams({
      ...hitParams,
      page: page,
    });
  };
  const onConfirm = async () => {
    const response = await fetchAxios(
      `${environments.api.management_content}/${idDeleted}`,
      "delete"
    );
    if (response.status === 200) {
      toast.success(response?.data?.message);
      setIsConfirmDeleted(false);
    } else {
      setIsConfirmDeleted(false);
    }
  };
  const searchAction = debounce((e) => {
    setHitParams({
      ...hitParams,
      key: searchInput?.current.value,
    });
  }, 500);

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            variant="success"
            onClick={() => {
              window.location.href = ROUTEPATH.MANAGEMENT_CONTENT_PAGE_ADD;
            }}
            hidden={true}
          >
            Tambah
          </Button>{" "}
        </div>
        <div>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Pencarian"
              aria-label="search"
              aria-describedby="basic-addon1"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                searchAction(e);
              }}
              ref={searchInput}
              style={{ backgroundColor: '#fff' }}
              
            />
          </InputGroup>
        </div>
      </div>
      <DataTable
        columns={ColumManagementContentPage(props)}
        data={data}
        noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
        pagination
        paginationServer
        paginationTotalRows={dataTable?.data?.total}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10,25,50,100,250]}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirmDeleted}
        title="Konfirmasi delete Page"
        message={`Apakah kamu yakin untuk delete Page Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal"
        onCancel={() => {
          setIsConfirmDeleted(false);
        }}
      />
    </Fragment>
  );
};

export default ManagementContentPage;
