/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";
import { ValidateSetting } from "../../../validate";
import Inputs from "../../components/Inputs";
import SweetAlert from "../../components/SweetAlert";

const Setting = () => {
  let history = useHistory();
  const [isConfirm, setIsConfirm] = useState(false);
  const [payload, setPayload] = useState();
  let initialPayload = {
    auto_complated_after_receive: "",
    reminder_notif_before_overdue: "",
    reminder_notif_after_overdue: "",
    wa_number: "",
  };
  const formik = useFormik({
    initialValues: initialPayload,
    validationSchema: ValidateSetting.setting,
    onSubmit: async (values) => {
      setIsConfirm(true);
      setPayload({ ...values });
    },
  });
  useEffect(() => {
    fetchDetailSetting();
  }, []);

  const fetchDetailSetting = async () => {
    const response = await fetchAxios(`${environments.api.setting}`, "get");
    if (response.status === 200) {
      formik.setFieldValue(
        "auto_complated_after_receive",
        response.data?.data?.content?.auto_complated_after_receive
      );
      formik.setFieldValue(
        "reminder_notif_before_overdue",
        response?.data?.data?.content?.reminder_notif_after_overdue
      );
      formik.setFieldValue(
        "reminder_notif_after_overdue",
        response?.data?.data?.content?.reminder_notif_before_overdue
      );
      formik.setFieldValue(
        "wa_number",
        response?.data?.data?.content?.wa_number
      );
    }
  };

  const onConfirm = async () => {
    const body = {
      auto_complated_after_receive: payload.auto_complated_after_receive,
      reminder_notif_before_overdue: payload.reminder_notif_before_overdue,
      reminder_notif_after_overdue: payload.reminder_notif_after_overdue,
      wa_number: payload.wa_number,
    };
    const response = await fetchAxios(
      `${environments.api.setting}`,
      "post",
      body
    );
    if (response.status === 200) {
      toast.success(response.data.message);
      setIsConfirm(false);
    } else {
      setIsConfirm(false);
    }
  };
  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Col sm={6}>
          <Inputs
            formik={formik}
            label="Auto Complete Pengiriman Diterima(hari)"
            type="number"
            id="auto_complated_after_receive"
            name="auto_complated_after_receive"
            values={formik.values.auto_complated_after_receive}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Inputs
            formik={formik}
            label="Kirim notifikasi pengingat beberapa hari sebelum jatuh tempo (hari)"
            type="number"
            id="reminder_notif_before_overdue"
            name="reminder_notif_before_overdue"
            values={formik.values.reminder_notif_before_overdue}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Inputs
            formik={formik}
            label="Kirim notifikasi pengingat beberapa hari setelah jatuh tempo setiap (hari)"
            type="number"
            id="reminder_notif_after_overdue"
            name="reminder_notif_after_overdue"
            values={formik.values.reminder_notif_after_overdue}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Inputs
            formik={formik}
            label="No WA"
            type="number"
            id="wa_number"
            name="wa_number"
            values={formik.values.wa_number}
            isChangeCustome
            onChange={(e) => {
              formik.setFieldValue("wa_number", e.target.value);
            }}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col className="mt-4">
          <div className="d-flex">
            <div>
              <Button
                className="btn btn-outline-secondary"
                onClick={() => history.goBack()}
              >
                Kembali
              </Button>{" "}
            </div>
            <div>
              <Button className="btn btn-success" type="submit">
                Simpan
              </Button>{" "}
            </div>
          </div>
        </Col>
      </form>
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirm}
        title={"Konfirmasi Tambah Pengaturan"}
        message={`Apakah kamu yakin untuk menambahkan Setting Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal"
        onCancel={() => {
          setIsConfirm(false);
        }}
      />
    </Fragment>
  );
};

export default Setting;
