import React from 'react';
import { Col, Row, Form} from 'react-bootstrap';

import Avatar1 from '../../../assets/images/user/avatar-1.jpg';

const DetailInformations = (props) => {
    const {
        allData
    } = props
    
  return (
    <Row>
        <Col sm='2'>
            <div className='d-flex justify-content-center'>
                <div>
                    <div><img className="img-radius" src={Avatar1} alt="Generic placeholder"/></div>
                </div>
            </div>
        </Col>
        <Col>
            <div className='my-1'>
                <Form.Label>Nama</Form.Label>
                <Form.Text>{allData?.name}</Form.Text>
            </div>
            <div className='my-1'>
                <Form.Label>Email</Form.Label>
                <Form.Text>{allData?.email}</Form.Text>
            </div>
            <div className='my-1'>
                <Form.Label>Telepon</Form.Label>
                <Form.Text>{allData?.phone}</Form.Text>
            </div>
            <div className='my-1'>
                <Form.Label>Alamat</Form.Label>
                <Form.Text>{allData?.owner_address}</Form.Text>
            </div>
        </Col>
        <Col>
            <div className='my-1'>
                <Form.Label>Jenis Pelanggan</Form.Label>
                <Form.Text>{allData?.mitra_group?.name}</Form.Text>
            </div>
            <div className='my-1'>
                <Form.Label>Limit Piutang</Form.Label>
                <Form.Text>{allData?.limit_plafond ? Number(allData?.limit_plafond).toLocaleString("id") : "-"}</Form.Text>
            </div>
            <div className='my-1'>
                <Form.Label>Sisa Limit</Form.Label>
                <Form.Text>{allData?.limit_plafond ? Number(allData?.limit_plafond - allData?.limit_plafond_used).toLocaleString("id") : "-"}</Form.Text>
            </div>
            <div className='my-1'>
                <Form.Label>Tempo</Form.Label>
                <Form.Text>{allData?.tempo}</Form.Text>
            </div>
        </Col>
    </Row>
  )
}

export default DetailInformations