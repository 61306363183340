/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import environments from "../../../environments";
import { encodeQueryData } from "../../../helpers/endcodeQuery";
import ColumMitraManagement from "../../components/Colums/ColumMitraManagement";
import fetchAxios from "../../../helpers/fetchAxios";
import { debounce } from "lodash";
import SweetAlert from "../../components/SweetAlert";
import { toast } from "react-toastify";

const ManagementMitra = (props) => {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState();
  const [keyword, setKeyword] = useState("");
  const searchInput = useRef();
  const [isConfirmDeleted, setIsConfirmDeleted] = useState(false);
  const [idDeleted, setIdDeleted] = useState("");
  const [hitParams, setHitParams] = useState({
    orderby: "",
    sort: "desc",
    page: 1,
    per_page: 10,
    status: "",
    key: "",
    mitra_group_id: "",
  });
  useEffect(() => {
    fetchListManagementMitra();
  }, [hitParams, isConfirmDeleted]);

  const showAlertDeleted = (id) => {
    setIdDeleted(id);
    setIsConfirmDeleted(true);
  };
  props = {
    showAlertDeleted,
  };

  const fetchListManagementMitra = async () => {
    const response = await fetchAxios(
      environments.api?.mintra_management +
        (Object.keys(hitParams).length > 0
          ? "?" + encodeQueryData(hitParams)
          : ""),
      "get"
    );

    setData(response.data?.data?.data);
    setDataTable(response.data);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setHitParams({
      ...hitParams,
      page: page,
      per_page: newPerPage,
    });
  };
  const handlePageChange = (page) => {
    setHitParams({
      ...hitParams,
      page: page,
    });
  };
  const searchAction = debounce((e) => {
    setHitParams({
      ...hitParams,
      key: searchInput?.current.value,
    });
  }, 500);

  const onConfirm = async () => {
    const response = await fetchAxios(
      `${environments.api.mintra_management}/${idDeleted}`,
      "delete"
    );
    if (response.status === 200) {
      toast.success(response?.data?.message);
      setIsConfirmDeleted(false);
    } else {
      setIsConfirmDeleted(false);
    }
  };

  const handleImport = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const response = await fetchAxios(
      `${environments.api.mintra_management}/import`,
      "post",
      formData
    );

    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
  };

  return (
    <Fragment>
      <div className="d-flex">
        <div className="flex-grow-1">
          <div className="d-flex">
            <Button
              variant="success"
              onClick={() => {
                window.location.href = `/manajemen-mitra/add`;
              }}
            >
              Tambah
            </Button>{" "}
            <label class="custom-file-upload" style={{ width: "100px" }}>
              <input
                accept=".xlsx, .xls, .csv"
                name="profile_picture"
                type="file"
                onChange={(e) => handleImport(e)}
              />
              Import
            </label>
            <Button variant="info" href={dataTable?.link_export}>
              Export
            </Button>
          </div>
        </div>
        <div>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Pencarian"
              aria-label="search"
              aria-describedby="basic-addon1"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                searchAction(e);
              }}
              ref={searchInput}
              style={{ backgroundColor: "white" }}
            />
          </InputGroup>
        </div>
      </div>
      <DataTable
        columns={ColumMitraManagement(props)}
        data={data}
        noDataComponent={<div className="py-2">Data Tidak Ditemukan</div>}
        pagination
        paginationServer
        paginationTotalRows={dataTable?.data?.total}
        // paginationRowsPerPageOptions={[10, 25, 50, 100, 1000]}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
      />
      <SweetAlert
        type="confirm"
        iconType="question"
        isActive={isConfirmDeleted}
        title="Konfirmasi delete Manajemen Mitra"
        message={`Apakah kamu yakin untuk delete Manajemen Mitra Ini?`}
        onConfirm={onConfirm}
        btnConfirm="Konfirmasi"
        btnCancel="Batal"
        onCancel={() => {
          setIsConfirmDeleted(false);
        }}
      />
    </Fragment>
  );
};
export default ManagementMitra;
