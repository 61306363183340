import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import environments from "../../../environments";
import fetchAxios from "../../../helpers/fetchAxios";
import DatePickers from "../../components/DatePickers";

export default function ChartMitraTransaction(props) {
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState(new Date());
  const [topCostumer, setTopCostumer] = useState([]);
  const [hitParams, setHitParams] = useState({
    month: "",
    year: "",
    day: "",
    status: "sudah diterima",
  });

  useEffect(() => {
    fetchTopCostumer();
  }, [hitParams]);

  const fetchTopCostumer = async () => {
    const response = await fetchAxios(
      environments.api.dashboard +
        `/sales/mitra?month=${hitParams.month}&year=${hitParams.year}&status[]=${hitParams.status}`,
      "get"
    );
    if (response.status === 200) {
      setTopCostumer(response.data?.data?.chart);
    }
  };

  const data = topCostumer.map((item) => {
    return {
      year: item.mitra_name,
      uv: item.total,
    };
  });

  return (
    <Fragment>
      <Row className="my-5">
        <Col sm={6}>
          <DatePickers
            label=""
            name="registration_date"
            dateFormat="MMMM yyyy"
            selected={month}
            placeholderText="Semua Bulan"
            showMonthYearPicker={true}
            onChange={(e) => {
              setHitParams({
                ...hitParams,
                month: dayjs(e).format("MM"),
                year: dayjs(e).format("YYYY"),
              });
              setMonth(e);
            }}
          />
        </Col>
      </Row>
      <Card className="p-3">
        <ResponsiveContainer width="100%" height="100%" aspect={3}>
          <BarChart
            width={500}
            height={50}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" /> */}
            <Bar dataKey="uv" fill="#82ca9d" name="Total transaksi" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </Fragment>
  );
}
