import React from "react";
import { Button } from "react-bootstrap";

const ColumManagementContentBanner = (props) => {
  return [
    {
      name: "Nama",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Tipe",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Aksi",
      sortable: true,
      width: "300px",
      cell: (row) => {
        return (
          <div className="d-flex">
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-outline-success"
                  size="sm"
                  onClick={() => {
                    window.location.href = `/manajemen-konten/banner/${row.id}`;
                  }}
                >
                  Ubah
                </Button>
              </span>
            </div>
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-danger"
                  size="sm"
                  onClick={() => props.showAlertDeleted(row.id)}
                >
                  Hapus
                </Button>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

const ColumManagementContentPage = (props) => {
  return [
    {
      name: "Nama",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Aksi",
      sortable: true,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-outline-success"
                  size="sm"
                  onClick={() => {
                    window.location.href = `/manajemen-konten/page/${row.id}`;
                  }}
                >
                  Ubah
                </Button>
              </span>
            </div>
            <div>
              <span>
                <Button
                  outline
                  className="btn btn-danger"
                  size="sm"
                  onClick={() => props.showAlertDeleted(row.id)}
                  hidden={true}
                >
                  Hapus
                </Button>
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export { ColumManagementContentBanner, ColumManagementContentPage };
