import * as Yup from "yup";

const addMitra = Yup.object({
  // registration_date: Yup.string().required("Silahkan isi tanggal register"),
  name: Yup.string().required("Silahkan isi nama mitra"),
  code: Yup.string().required("Silahkan isi kode pelanggan"),
  group_mitra: Yup.string().required("Silahkan isi grup mitra"),
  shipping_address: Yup.string().required("Silahkan isi alamat pengiriman"),
  province_id: Yup.string().required("Silahkan isi provinsi"),
  city_id: Yup.string().required("Silahkan isi kota"),
  district_id: Yup.string().required("Silahkan isi kecamatan"),
  village: Yup.string().required("Silahkan isi kelurahan"),
  post_code: Yup.number().required("Silahkan isi kode pos"),
  phone: Yup.number()
    .positive("Nomer telepon tidak boleh di awali dengan minus")
    .test("length min phone", "Min nomer 10", (val, data) => {
      return data?.originalValue?.toString().length > 9;
    })
    .test("length max phone", "Max nomer 15", (val, data) => {
      return data?.originalValue?.toString().length < 14;
    })
    .required("Silahkan isi telepon"),
  owner_name: Yup.string().required("Silahkan isi nama pemilik "),
  owner_address: Yup.string().required("Silahkan isi alamat pemilik "),
  sales_person: Yup.string().required("Silahkan isi sales person "),
  billing_contact: Yup.string().required("Silahkan isi kontak pembayaran"),
  company_type: Yup.string().required("Silahkan isi jenis perusahaan"),
  ktp_no: Yup.number()
    .test("length max ktp", "Max nomer KTP 16", (val) => {
      return val?.toString().length <= 16;
    })
    .required("Silahkan isi ktp"),
  status: Yup.string().required("Silahkan isi status"),
});

const updatePlafond = Yup.object({
  total_limit: Yup.number()
    .positive("Total Limit tidak boleh di awali dengan minus")
    .required("Silahkan isi inputan"),
  tempo: Yup.number()
    .test(
      "Is positive?",
      "Tempo tidak boleh di awali dengan minus",
      (value) => value >= 0,
    )
    .required("Silahkan isi inputan"),
});

const active = Yup.object({
  status: Yup.string().required("Silahkan isi status"),
});

export { addMitra, updatePlafond, active };
