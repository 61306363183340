/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import Inputs from "../../../components/Inputs";
import ConfigPrice from "./configPrice";
import ConfigLimit from "./configLimit";

export const InputAddLeft = (props) => {
  const {
    formik,
    selectedFiles,
    renderPhotos,
    showModalChooseGallery,
    optionsMitraGroup,
    details,
  } = props;

  return (
    <Col sm="7">
      <Inputs
        formik={formik}
        label="Name"
        type="text"
        id="name"
        name="name"
        values={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <div className="mb-3 mt-2">
        <p>Deskripsi</p>
        <ReactQuill
          name="desc"
          value={formik.values.desc}
          onChange={(value) => {
            formik.setFieldValue("desc", value);
          }}
          style={{ backgroundColor: "#fff" }}
        />
        {formik.errors.desc && formik.touched.desc && (
          <p style={{ color: "red", fontSize: "11px" }}>{formik.errors.desc}</p>
        )}
      </div>
      <div className="mt-2">
        <p>Galeri</p>
        <Col>
          <Row
            sm="5"
          >
            {renderPhotos(selectedFiles)}
              <Button
                variant="primary"
                onClick={showModalChooseGallery}
              >
                Tambah Galeri
              </Button>{" "}
          </Row>
        </Col>
        {formik.errors.galleries && formik.touched.galleries && (
          <p style={{ color: "red", fontSize: "11px" }}>
            {formik.errors.galleries}
          </p>
        )}
      </div>
      <ConfigPrice
        formik={formik}
        optionsMitraGroup={optionsMitraGroup}
        details={details}
      />
      <ConfigLimit
        formik={formik}
        optionsMitraGroup={optionsMitraGroup}
        details={details}
      />
    </Col>
  );
};
